import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class FollowUpService {
  constructor(private http: HttpService) {}

  async addFollowUp(Payload: any) {
    return await this.http.post(`follow-up/add`, Payload);
  }
  async editFollowUp(payload: any) {
    let options = this.http.createHttpParams(payload);
    return await this.http.put(`follow-up/${payload.callId}`, options);
  }

  async getFollowUpbyId(id: number) {
    return await this.http.get(`follow-up/${id}`);
  }
}
