import { Injectable } from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
interface ModalServiceAttributes {
  key: string;
  value: any;
}
@Injectable({
  providedIn: "root",
})
export class ModalService {
  private openedModalRef: NgbModalRef;

  constructor(private modal: NgbModal) {}

  ngOnInit(): void {}

  open(
    content: any,
    options?: NgbModalOptions,
    modalAttributes?: ModalServiceAttributes[]
  ): NgbModalRef {
    this.openedModalRef = this.modal.open(content, options);
    if (modalAttributes && modalAttributes.length) {
      modalAttributes.forEach((item: ModalServiceAttributes) => {
        (this.openedModalRef.componentInstance as any)[item.key] = item.value;
      });
    }
    return this.openedModalRef;
  }

  async confirmationModalResult(openedModalRef: NgbModalRef) {
    if (openedModalRef) {
      try {
        return await openedModalRef.result;
      } catch {
        throw new Error("modal dismiss error");
      } finally {
        openedModalRef = null;
      }
    }
  }
}
