import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class userLocationService {
  constructor(private http: HttpService) {}

  async getAllLocation() {
    return await this.http.get(`user-locations`);
  }
  editLocation(id: string, location: string) {
    let body = {
      locationName: location,
    };
    return this.http.put(`user-locations/${id}`, body);
  }
  addLocation(location: string) {
    let body = {
      locationName: location,
    };
    return this.http.post(`user-locations`, body);
  }
}
