<div class="container-fluid flex-column">
  <form [formGroup]="filterForm">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <label for="">From</label>
        <nz-date-picker
          formControlName="startDate"
          [(ngModel)]="startDate"
          class="form-control shadow-sm bg-body rounded"
        ></nz-date-picker>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <label for="">To</label>
        <nz-date-picker
          formControlName="endDate"
          [(ngModel)]="endDate"
          class="form-control shadow-sm bg-body rounded"
        ></nz-date-picker>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label for="" style="color: white">.</label>
        <input
          class="form-control shadow-sm bg-body rounded"
          type="text"
          placeholder="Search Patient"
          formControlName="name"
        />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label for="" style="color: white">.</label>

        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="title"
          [items]="exportLimit"
          placeholder="Export Limit"
          formControlName="exportCount"
          (change)="setExportLimit($event)"
        >
        </ng-select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12 col-md-6">
        <label for=""> Total Count : {{ totalCount }} </label>
      </div>
      <div class="col-sm-12 col-md-3">
        <button
          class="btn btn-danger"
          style="float: right"
          (click)="helplineModal('add')"
          [ngStyle]="{
            display: route === 'incomming-calls-closed' ? 'none' : ''
          }"
        >
          Add Query
        </button>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <img
          src="/assets/images/excel.ico"
          class="excelStyles float-right"
          height="40px"
          (click)="excelExport()"
        />
        <button
          class="btn btn-danger"
          style="float: left"
          (click)="getIncommingCalls()"
        >
          Apply filter
        </button>
      </div>
    </div>
  </form>

  <div
    id="table_div"
    class="mt-3"
    [ngClass]="resData == undefined ? 'fixed' : 'scroll'"
  >
    <table class="table table-hover table-borderless">
      <thead>
        <tr>
          <!-- [ngStyle]="{min-width: route === 'incomming-calls-closed' && item === 'Action'?  '350px' : ''}" -->
          <td
            *ngFor="let item of tableHeader"
            [ngStyle]="{
              'min-width.px':
                route === 'incomming-calls' && item === 'Action' ? 250 : null
            }"
          >
            {{ item }}
          </td>
          <td
            *ngIf="user === 'admin'"
            [ngStyle]="{
              'min-width.px':
                route === 'incomming-calls-closed' && item === 'Action'
                  ? 250
                  : null
            }"
          >
            Edit
          </td>
        </tr>
      </thead>
      <tbody class="mt-4">
        <tr
          class="mt-4 shadow-sm bg-body rounded"
          *ngFor="let item of tableData"
        >
          <td>{{ item.callerName || "-" }}</td>
          <td>{{ item.callerPhoneNumber || "-" }}</td>
          <td>{{ item.category || "-" }}</td>
          <td>{{ item.companyName || "-" }}</td>
          <td>{{ item.agent.firstName + " " + item.agent.lastName || "-" }}</td>
          <td>{{ item.remarks || "-" }}</td>
          <td>{{ item.caseCateringDepartment || "-" }}</td>
          <td>{{ item.callerRemarksORdetail || "-" }}</td>
          <td>{{ item.caseCloseTime || "-" }}</td>
          <td>{{ (item.date | date : "medium") || "-" }}</td>
          <!-- <td>{{ item.callAttentedAt || "-" }}</td> -->
          <td>{{ item.registeredVia || "-" }}</td>
          <td
            [ngStyle]="{
              display: route === 'incomming-calls-closed' ? 'none' : ''
            }"
          >
            <button
              class="btn btn-danger ml-2 btn-small mx-2"
              (click)="closeCallStatus(item.id)"
            >
              Close
            </button>
            <button
              style="
                background-color: #7cc707 !important;
                border-color: #7cc707 !important;
              "
              (click)="followUpModal(item.id, 'add')"
              class="btn btn-success btn-small mx-2"
            >
              Follow Up
            </button>
          </td>
          <td *ngIf="route === 'incomming-calls-closed'">
            <button
              *ngIf="item.caseCloseTime === 'Pending'"
              style="
                background-color: #7cc707 !important;
                border-color: #7cc707 !important;
              "
              (click)="followUpModal(item.id, 'view')"
              class="btn btn-success btn-small mx-2"
            >
              View Follow Up
            </button>
            <p *ngIf="item.caseCloseTime !== 'Pending'">-</p>
          </td>
          <td *ngIf="user === 'admin'" style="min-width: 220px">
            <button
              *ngIf="
                item.caseCloseTime === 'Pending' &&
                route === 'incomming-calls-closed'
              "
              (click)="followUpModal(item.id, 'edit')"
              style="
                background-color: #7cc707 !important;
                border-color: #7cc707 !important;
              "
              class="btn btn-success btn-small mx-2 mb-1 float-left"
            >
              Follow Up
            </button>
            <p
              *ngIf="
                item.caseCloseTime !== 'Pending' &&
                route === 'incomming-calls-closed'
              "
              style="float: left; margin: 2px 0px 0 50px"
            >
              -
            </p>
            <button
              (click)="helplineModal('edit', item.id)"
              class="btn btn-danger btn-small mx-2 mb-1"
              [ngClass]="{ 'float-right': route === 'incomming-calls-closed' }"
            >
              Helpline
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="text-center field-loader" *ngIf="resData === undefined">
    <img src="assets/images/SK-Logo-Animation.gif" alt="" />
  </div>
  <app-pagination
    (pageNumber)="requestPaginate($event)"
    [totalCount]="totalCount"
  ></app-pagination>
</div>
