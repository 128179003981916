import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class roleAndLocationService {
  constructor(private http: HttpService) {}

  getAllRoleandLocation() {
    return this.http.get(`admin/all/details`);
  }
}
