import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormControl } from "@angular/forms";

@Component({
  selector: "app-assign-agent",
  templateUrl: "./assign-agent.component.html",
  styleUrls: ["./assign-agent.component.scss"],
})
export class AssignAgentComponent implements OnInit {
  xmark = faXmark;
  agentId: FormControl;
  constructor(
    private activeModal: NgbActiveModal,
    private formBuider: FormBuilder
  ) {}
  @Input()
  agentIds: any;
  consultationsCount;
  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.agentId = new FormControl();
  }

  close() {
    this.activeModal.close(false);
  }

  async submit() {
    // console.log(this.agentId.value);
    this.activeModal.close(this.agentId.value);
  }
}
