import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { paginate } from "src/app/admin/core/enums/paginate";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  paginateNumber: number = 0;
  // currentPage: number = 0;
  @Output() pageNumber = new EventEmitter<number>();
  @Input() totalCount: number = 0;
  paginateNumberArray: any[] = [];

  requestPaginate(value: number) {
    // this.currentPage = +value - 1;
    this.pageNumber.emit((value - 1) * paginate.defaultLimit);
  }
  constructor() {}

  ngOnInit(): void {
    this.pagninateData(this.totalCount);
  }
  pagninateData(totalCount = 40) {
    this.paginateNumber = Math.ceil(totalCount / paginate.defaultLimit);
    this.paginateNumberArray = this.counter(this.paginateNumber);
  }
  counter(i: number) {
    return new Array(i);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pagninateData(changes.totalCount.currentValue);
  }
}
