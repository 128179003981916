import { HttpHeaders, HttpParams } from "@angular/common/http";

export interface IFedbackField {
  label: string;
  questionId: string;
  placeholder: string;
  type: "input" | "select";
  options?: {
    value: string;
    label: string;
  }[];
}

export interface IDropDown {
  value: string;
  label: string;
}

export interface callStatus {
  title: string;
}
export const adminAppValidations = {
  name: {
    length: 40,
  },
  email: {
    length: 255,
  },
  phone: {
    minlength: 12,
    maxlength: 16,
  },
  password: {
    minLength: 8,
    maxLength: 20,
  },
  role: {},
};

export const AppConstant = {
  config: {
    api: "api/",
    version: "v1/",
  },
  user: "sk_user",
  localStorage: {
    isSocialLogin: "isSocialLogin",
  },
  roles: {
    admin: "admin",
    doctor: "doctor",
    patient: "patient",
    company: "company",
    companyAdmin: "company-admin",
    custom: "custom:role",
  },
  identityProvider: {
    facebook: "Facebook",
    google: "Google",
  },
  messages: {
    renewalStatusChangeConfirmation:
      "Are you sure you want to change the renewal status?",
    internalNotificationChangeConfirmation:
      "This notification will be sent to Internal users only.",

    proceedWithTransaction: "Do you want to proceed with the transaction?",
    networkError: "Network Error. Try again later",
    defaultApiError: "Try again later! Something bad happened",
    allFieldsRequired: "Please fill in all of the required fields to continue.",
    InvalidFileSize: "File size must be less than 5Mb",
    fieldEditWarning:
      "Admin verification is required for using all features of application",
    linkExpire: "Link already expired",
    stepsWarning: "All steps must be filled in sequentially",
    profileUpdated: "Profile has been updated successfully",
    settingsUpdated: "Settings has been updated successfully",
    profileNotObtained: "Unable to obtain profile",
    passwordChanged: "Your password has been changed.",
    passwordResetLink:
      "Password reset link successfully sent to email address.",
    userDoesNotExist: "User does not exist",
    profileCreated: "Profile has been successfully saved",
    newConsultationRequest: "New Consultation Request Received",
    instantConsultationCreated: "Instant Consultation Created",
    consultationRequestSent: "Consultation Request Sent",
    consultationStarted: "Consultation Started",
    consultationEnded: "Consultation Ended",
    invalidImageType: "Invalid Image Type",
    prescriptionSaved: "Prescription Saved",
    prescriptionPublished: "Prescription Published",
    deleteConfirmationMessage: "Are you sure you want to delete",
    deleteConfirmationMessageQuestion:
      "Are you sure you want to delete this question?",
    deleteConfirmationMessageAnswer:
      "Are you sure you want to delete this answer?",
    cancelAppointmentConfirmationMessage:
      "Are you sure you want to cancel this appointment?",
    consultationCancelled: "Consultation Cancelled",
    emptySpacesNotAllowed: "Empty spaces are not allowed",
    specialScheduleCreated: "Special Schedule Created",
    specialScheduleDeleted: "Special Schedule Deleted",
    appointmentBookedSuccessfully: "Appointment booked successfully",
    scheduleSaved: "Schedule Saved",
    completeScheduleFirst: "Complete this schedule first",
    validScheduleRequired: "Kindly enter a valid schedule first",
    noTimeSlotAvailable: "There is no time slot available at the moment",
    timeLeftInAppointment: "Time Left in Appointment",
    sessionTime: "Session Time",
    scheduleRepeated: "Schedule Repeated",
    scheduleExists: "Schedule Already Exists",
    repeatFormValueUnchanged: "No extra Days selected",
    scheduleDeleted: "Schedule Deleted",
    validStartEndDateRequired: "Valid start and end date is required",
    scheduleDateUpdated: "Schedule date updated",
    scheduleDateChangeConfirmation: "Are you sure you want to change the date?",
    scheduleDeleteConfirmationMessage:
      "Are you sure you want to delete this schedule?",
    specialScheduleDeleteConfirmationMessage:
      "Are you sure you want to delete this special schedule?",
    scheduleDeletedMessage: "Schedule deleted successfully.",
    microphoneNotFoundMessage: "Microphone Not Found",
    cameraNotFoundMessage: "Camera Not Found",
    microphoneAndCameraNotFoundMessage: "Microphone and Camera Not Found",
    microphonePermissionsRequiredMessage: "Microphone Permission Required",
    cameraPermissionsRequiredMessage: "Camera Permission Required",
    microphoneAndCameraPermissionsRequiredMessage:
      "Microphone and Camera Permission Required",
    callPermissionsRequiredMessage: "Camera and Microphone Permission Required",
    callStartError: "Error in starting the call",
    callDisconnectDueToNetwork: "Call Disconnected Due to Network Issue",
    addPrescriptionMessage:
      "You have not added any prescription, do you want to add?",
    reviewPrescriptionMessage:
      "Make sure you review and publish the prescription before ending the consultation",
    consultationEndWarningMessage:
      "Consultation is about to end in 5 mins, \
      Make sure you review and publish the prescription",
    consultationDroppedMessage: "Consultation has been Dropped",
    consultationEndMessage: "Consultation has Ended",
    notLoggedIn: "Not logged in",
    notesSaved: "Notes Saved",
    endConsultationWarning: "Your consultation is about to end",
    doctorFeeConfirmMessage:
      "Doctor's fee is Rs. [DoctorFee]. Do you want to proceed and pay?",
    doctorFeeAdjustmentMessage:
      "Amount of Rs. [Adjustment] has been adjusted from Doctor Fee of Rs. [DoctorFee], kinldy proceed to pay for remaining amount of Rs. [RemainingAmount]",
    doctorFeeCompleteAdjustMent:
      "Doctor Fee For this consultation has been adjusted from your wallet, you can proceed with consultation",
    consultationFromSubscriptionMessage:
      "This consultation will be used from your subscription. Do you want to proceed?",
    codUnpaidPaymentSuccess:
      "Admin has granted you 1 unpaid consultation. Payment will be received later",
    codFreePaymentSuccess:
      "You have been given a free consultation. Kindly avail it.",
    pendingConsultationError: "You already have a pending consultation",
    incorrectPassword: "Incorrect Password",
    userDisabled: "Admin has disabled your user",
    revokeFirstFreeConsultation:
      "This user has not utilized his/her first free consultation. \
    Proceeding will revoke the first free consultation.",
    currentPasswordIncorrect: "Current Password is not valid",
    subscribeConfirmationMessage: "Are you sure you want to subscribe",
    consultationAllowOnlyGP:
      "Free Consultatoin Can only be availed on General Physician",
  },
  progressBarConfig: {
    color: "#d2004d",
  },
  singleDay: 86400, // In Seconds
  fifteenMB: 15000000, // In Byte
  appointment: "appointment",
  consultation: "consultation",
  error: {
    unknownError: "UnknownError",
    cognito: {
      notAuthorizedException: "NotAuthorizedException",
      currentUserRetrieveError: `Can't retrieve the CurrentUser`,
      expiredCodeException: "ExpiredCodeException",
      userNotFoundException: "UserNotFoundException",
      codeMismatchException: "CodeMismatchException",
      messages: {
        notAuthorizedException: {
          incorrectPassword: "Incorrect username or password.",
          userDoesNotExist: "User does not exist.",
          userDisabled: "User is disabled",
        },
      },
    },
  },
  currency: "PKR",
  emailPattern:
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@' +
    "((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])" +
    "|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$",
  onlineDevice: "sk_onlineDevice",
  globalChannels: "sk_globalChannels",
  // phone pattern only numbers and + sign to be removed if any
  phonePattern: "/^[0-9+]*$/",
};
export interface ModalServiceAttributes {
  key: string;
  value: any;
}
export interface AngularHttpOptions {
  body?: any;
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: any;
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}

export interface rowType {
  row: "collapse" | "inputField";
  active?: boolean;
  name?: string;
  fields: {}[];
}
[];
export const helplinePhoneValidate = RegExp(
  `^\\d{${adminAppValidations.phone.minlength},${
    adminAppValidations.phone.maxlength - 1
  }}$`
);
export const signupPhoneValidate = RegExp(
  `^\\+\\d{${adminAppValidations.phone.minlength - 1},${
    adminAppValidations.phone.maxlength - 1
  }}$`
);

export interface pieAndDonutChartInterface {
  type: "pie" | "doughnut";
  title: string;
  chart: any;
  labels: string[];
  dataTypes: {
    label: string;
    backgroundColor: string[];
    data: number[];
  };
}

export interface BarChartParams {
  title: string;
  chart: any;
  labels: string[];
  comparative: true | false;
  datasets?: {
    label: string[];
    data: number[];
    backgroundColor: string[];
  }[];
  datasetsArr?: {
    label: string[];
    data: number[];
    backgroundColor: string[];
  }[];
}

export interface iNgSelect {
  value: string;
  title: string;
}
[];

export interface getAllConsutations {
  limit?: number;
  offset?: number;
  consultationStatus?: string | string[];
  startDate?: number;
  endDate?: number;
  doctor?: string;
  partner?: string;
  type?: string;
  callStatus?: string;
  patientNumber?: string;
  agentId?: string | string[];
}
