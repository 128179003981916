import { Injectable } from "@angular/core";
// import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  constructor() {}

  exportExcel(data: any): void {
    let sheetNames = Object.keys(data);
    let sheets: any = {};
    sheetNames.map((d: any) => {
      let value = data[d];
      let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(value);
      sheets[d] = worksheet;
    });

    const workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: sheetNames,
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    let fileName: string;
    if (sheetNames[0] == "Helpline Calls") {
      fileName = "Helpline Calls";
    } else {
      fileName = "Consultations";
    }
    this.saveExcelFile(excelBuffer, fileName);
  }

  saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: "application/octet-stream" });
    const url: string = window.URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement("a");
    link.href = url;
    link.download = fileName + ".xlsx";
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }
}
