import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ModalServiceAttributes, iNgSelect } from "src/app/shared/interface";
import Swal from "sweetalert2";
import { Observable } from "rxjs";
import {
  tableHeaderConsultation,
  tableHeaderClosedCalls,
} from "../../user-management/data";
import { paginate } from "../../core/enums/paginate";
import { ConsultationService } from "../../core/services/consultation.service";
import { ModalService } from "../../core/services/modal.service";
import { Telephony } from "../../core/services/telephony.service";
import { ToasterService } from "../../core/services/toaster.service";
import { ExcelService } from "../../core/services/xlsx.service";
import { FormModalComponent } from "../../modals/form-modal/form-modal.component";
import { getAllConsutations, callStatus } from "../../../shared/interface";
import { convertDignosisToString, formatePayload } from "src/app/shared/helper";
import {
  consultationformateExportData,
  exportLimit,
} from "src/app/shared/enums";
import { AllUserService } from "../../core/services/allUsers.service";
import { AssignAgentComponent } from "../../modals/assign-agent/assign-agent.component";
@Component({
  selector: "app-pending-and-close",
  templateUrl: "./pending-and-close.component.html",
  styleUrls: ["./pending-and-close.component.scss"],
})
export class PendingAndClosedCallsComponent implements OnInit {
  startDate: any = null;
  endDate: any = null;
  displayValue: any;
  tableHeader: any;
  protected enablePagination: boolean;
  totalCount: number = 0;
  route = this.activeRoute.snapshot.routeConfig.path;
  user: any = JSON.parse(sessionStorage.getItem("user"));
  isButtonDisabled: boolean = false;
  convertDignosisToString = convertDignosisToString;
  agentName: iNgSelect[] = [];
  private readonly DISABLE_DURATION = 1 * 60 * 1000; // 3 minutes in milliseconds
  private readonly DISABLE_KEY = "buttonDisableTime";
  agents: any;

  constructor(
    private httpService: ConsultationService,
    private formbuilder: FormBuilder,
    private modalService: ModalService,
    private activeRoute: ActivatedRoute,
    private toaster: ToasterService,
    private httpTelephony: Telephony,
    private excel: ExcelService,
    private users: AllUserService
  ) {
    const disableTime = sessionStorage.getItem(this.DISABLE_KEY);
    if (disableTime && Number(disableTime) > Date.now()) {
      this.isButtonDisabled = true;
      setTimeout(() => {
        this.isButtonDisabled = false;
        sessionStorage.removeItem(this.DISABLE_KEY);
      }, Number(disableTime) - Date.now());
    }
    this.exportLimit = exportLimit;
  }
  patinetID: any;
  ngOnInit() {
    this.getUsers();
    this.startDate = new Date();
    this.endDate = new Date();
    this.formData();
    this.routecheck();
  }

  async getUsers() {
    let response = await this.users.getAllUsers();

    this.agentName = [{ value: "null", title: "Not Assigned" }];
    response.data.map((d) => {
      this.agentName.push({
        value: d.id,
        title: `${d.firstName} ${d.lastName}`,
      });
    });
    this.agents = response.data.map((d) => {
      return {
        value: d.id,
        title: `${d.firstName} ${d.lastName}`,
      };
    });
  }

  attempts: number = 0;
  allconsultations: any;
  resData: any = [];
  queryFilter: any[] = [];
  imgSrcPatient = "red";
  async databaseConnected(offset = 0) {
    this.enablePagination = false;
    let consultationType: string;
    await this.setQueryFilters();
    switch (this.route) {
      case "consultations":
        this.tableHeader = tableHeaderConsultation;
        consultationType = "pending";
        //
        break;
      case "closed-calls":
        this.tableHeader = tableHeaderClosedCalls;
        consultationType = "closed";
        break;
    }
    this.resData = undefined;

    let payload: getAllConsutations = {
      limit: paginate.defaultLimit,
      offset,
      callStatus: consultationType,
      startDate: this.filters.startDate,
      endDate: this.filters.endDate,
      doctor: this.form.value.doctor,
      partner: this.form.value.partner === "All" ? "" : this.form.value.partner,
      type:
        this.form.value.consultationType != "All"
          ? this.form.value.consultationType
          : "",
      consultationStatus: this.consultationStatus(),
      patientNumber: this.form.value.patientNumber,
    };

    if (this.route === "consultations") {
      if (this.user?.userRole?.userRole?.name === "admin") {
        payload.agentId = ["null", this.user.id];
      } else {
        payload.agentId = this.user.id;
      }
    } else {
      if (this.form.value.agentId?.length != 0) {
        payload.agentId = this.form.value.agentId;
      }
    }

    const result = await this.httpService.allConsultation(payload);
    this.resData = result.data;
    this.totalCount = result.meta.totalCount;
    this.enablePagination = this.resData.length >= paginate.defaultLimit;
    this.exportCount = this.totalCount;
  }

  consultationStatus = () => {
    let status: any = [];

    if (this.form.value.consultationStatus.length != 0) {
      this.form.value.consultationStatus.filter((d: any) => {
        if (d != "All") {
          status.push(d);
        }
      });
    } else {
      status = ["Consulted", "Cancelled", "Dropped", "Missed", "Rejected"];
    }

    return status;
  };

  async CallModal(
    status: any,
    rowId: number,
    patientnumber: string,
    attepmts: number,
    doctorPhone: string,
    consultationId: number,
    consultationDate: string,
    closed: boolean,
    called: string
  ) {
    try {
      // const agentActiveCallStatus: any =
      //   await this.checkPatientActiveCallStatus(consultationId, "active");
      // if (
      //   agentActiveCallStatus?.data ===
      //   "This patient is Already Active with other agent"
      // ) {
      //   return this.toaster.success(agentActiveCallStatus.data);
      // }
      if (!closed) {
        if (called === "patient") {
          this.telephony(patientnumber);
        } else {
          this.telephony(doctorPhone);
        }
      }

      const attributes: ModalServiceAttributes[] = [];
      attributes.push(
        {
          key: "valueData",
          value: status,
        },
        {
          key: "rowId",
          value: rowId,
        },
        { key: "attepmts", value: attepmts },
        {
          key: "route",
          value: this.route,
        },
        {
          key: "doctorPhone",
          value: doctorPhone,
        },
        {
          key: "patientPhone",
          value: patientnumber,
        },
        {
          key: "consultationsId",
          value: consultationId,
        },
        {
          key: "consultationDate",
          value: consultationDate,
        }
      );
      const result = this.modalService.open(
        FormModalComponent,
        {
          centered: true,
          backdrop: "static",
          keyboard: false,
        },
        attributes
      );

      const confirm = await this.modalService.confirmationModalResult(result);
      await this.handdleClick(rowId, confirm);
      if (confirm) {
        this.toaster.success("Feedback added successfully");
      }
    } catch (err) {
      this.toaster.error(err);
    }
  }
  imgSrcDoctor = "red";

  filters: any = {};
  setQueryFilters() {
    const sd = new Date(this.startDate);
    const ed = new Date(this.endDate);
    this.filters.startDate = isNaN(sd.getTime())
      ? new Date().getTime()
      : sd.getTime();
    this.filters.endDate = isNaN(ed.getTime())
      ? new Date().getTime()
      : ed.getTime();
    return this.filters;
  }
  async applyFilter(offset = 0) {
    await this.databaseConnected(offset);
    if (this.activeRoute.snapshot.routeConfig.path == "consultations") {
      this.allconsultations = this.resData.filter((d: any) => {
        return d.status.toLowerCase() !== "active";
      });
    }
    if (this.activeRoute.snapshot.routeConfig.path == "closed-calls") {
      this.allconsultations = await this.resData.map((data: any) => {
        return {
          ...data,
          closed: true,
        };
      });
    }
  }

  consultationType: any = [
    { value: "Appointment", title: "Appointment" },
    { value: "Instant", title: "Instant" },
  ];

  doctor: any = [];
  callType = [
    { value: "Missed", title: "Missed" },
    { value: "Consulted", title: "Consulted" },
    { value: "Cancelled", title: "Cancelled" },
    { value: "Rejected", title: "Rejected" },
    { value: "Dropped", title: "Dropped" },
  ];
  partners = [
    { value: "Retail", title: "Retail" },
    { value: "Corp", title: "Corp" },
  ];
  exportLimit: any;
  form: any;
  formData() {
    this.form = this.formbuilder.group({
      start: [this.filters.startDate],
      end: [this.filters.endDate],
      doctor: [],
      partner: [],
      consultationType: [],
      consultationStatus: [],
      patientNumber: [],
      exportCount: ["Export Current Data"],
      agentId: [],
    });
  }

  async handdleClick(rowId: number, confirm: string) {
    if (!confirm) {
      return;
    }
    const res = await this.allconsultations.map((data: any) => {
      if (+data.id === +rowId && +data.numberOfAttempts < 3) {
        return {
          ...data,
          numberOfAttempts: +data.numberOfAttempts + 1,
        };
      } else {
        return { ...data };
      }
    });
    this.allconsultations = res.filter(async (data: any) => {
      if (
        +data.id === +rowId &&
        (confirm == "close consultation" || +data.numberOfAttempts == 3)
      ) {
        await this.closeConsultation(data.id, true);
        return;
      } else {
        return { ...data };
      }
    });
  }
  placeholder: any = [];
  routecheck() {
    let route: string =
      this.activeRoute.snapshot.queryParams.consultationStatus;
    if (route != undefined) {
      this.queryFilter = [...this.queryFilter, route];
    }
    this.applyFilter();
  }

  demo = [];

  async closeConsultation(
    id: any,
    fromBtn?: boolean,
    patientId?: string,
    doctorId?: string,
    patientName?: string,
    doctorName?: string
  ) {
    try {
      if (fromBtn) {
        await this.httpService.closeConsultationStatus(id);
        await this.applyFilter();
      } else {
        let confirm: string;
        await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d00140",
          cancelButtonColor: "#7cc707",
          confirmButtonText: "Yes, Close",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await Swal.fire({
              text: "Do you want to close all consultations or this specific consultation",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d00140",
              cancelButtonColor: "#7cc707",
              confirmButtonText: "Yes, Close All",
              cancelButtonText: "Close only this",
            }).then(async (result) => {
              if (result.isConfirmed) {
                confirm = "closeAll";
                let param: {} = {
                  startDate: this.filters.startDate.toString(),
                  endDate: this.filters.endDate.toString(),
                };
                if (patientId) {
                  param = { ...param, patientId: patientId };
                }
                if (doctorId) {
                  param = { ...param, doctorId: doctorId };
                }
                await this.httpService.closeBulkConsultationStatus(param);
                await this.applyFilter();
                this.toaster.success(
                  `All Consultations of Patient ${patientName} with Dr ${doctorName}  has been closed`
                );
              } else {
                confirm = "closeSingle";
                await this.httpService.closeConsultationStatus(id);
                await this.applyFilter();
                this.toaster.success(
                  `Consultation of Patient ${patientName} with Dr ${doctorName}  has been closed`
                );
              }
            });
          }
        });
      }
    } catch (err) {
      this.toaster.error(err.error.meta.message);
    }
  }
  requestPaginate(offset: number) {
    this.allconsultations = [];
    this.applyFilter(offset);
  }

  async telephony(phone: string) {
    try {
      if (this.route == "closed-calls") {
        return;
      }
      let number = this.validatePhoneNumber(phone);
      const response = await this.httpTelephony.telephony(
        number,
        this.user.agentExtension
      );
    } catch {}
  }
  validatePhoneNumber(phone: string) {
    let extension = phone.toString().replace(/\s/g, "").slice(-10);
    return "0" + extension;
  }

  getRetailCorpDataConsultation(platform: string) {
    this.onButtonClick();
    this.httpService.getRetailCorpData(platform);
  }

  async generateExcelData(limitValue) {
    let consultationType: string;
    switch (this.route) {
      case "consultations":
        this.tableHeader = tableHeaderConsultation;
        consultationType = "pending";
        //
        break;
      case "closed-calls":
        this.tableHeader = tableHeaderClosedCalls;
        consultationType = "closed";
        break;
    }
    const payload: any = {
      limit: limitValue,
      offset: 0,
      callStatus: consultationType,
      startDate: this.filters.startDate,
      endDate: this.filters.endDate,
      doctor: this.form.value.doctor != "" ? this.form.value.doctor : "",
      partner:
        this.form.value.partner != "" && this.form.value.partner != "All"
          ? this.form.value.partner
          : "",
      type:
        this.form.value.consultationType != "" &&
        this.form.value.consultationType != "All"
          ? this.form.value.consultationType
          : "",
      consultationStatus: this.consultationStatus(),
      patientNumber:
        this.form.value.patientNumber != ""
          ? this.form.value.patientNumber
          : "",
    };

    if (this.route === "consultations") {
      if (this.user?.userRole?.userRole?.name === "admin") {
        payload.agentId = ["null", this.user.id];
      } else {
        payload.agentId = this.user.id;
      }
    } else {
      if (this.form.value.agentId?.length != 0) {
        payload.agentId = this.form.value.agentId;
      }
    }

    const reponse = await this.httpService.allConsultationExcel(payload);
    console.log(reponse.data.length);
    if (reponse.data.length == 0) {
      this.toaster.error("Please select some data to export");
      return;
    }

    const data = await consultationformateExportData(reponse);
    return data;
  }

  exportCount: number = this.totalCount;
  setExportLimit(event: any) {
    switch (event.title) {
      case "Export Current Data":
        this.exportCount = this.totalCount;
        break;
      case "Export 1000 Rows":
        this.exportCount = 1000;
        break;
    }
  }

  async excelExport() {
    const data: any = await this.generateExcelData(this.exportCount);
    this.excel.exportExcel(data);
  }
  onButtonClick() {
    // Disable the button and store the disable time in session storage
    this.isButtonDisabled = true;
    const disableTime = Date.now() + this.DISABLE_DURATION;
    sessionStorage.setItem(this.DISABLE_KEY, disableTime.toString());

    // Wait for the disable time to elapse before re-enabling the button
    setTimeout(() => {
      this.isButtonDisabled = false;
      sessionStorage.removeItem(this.DISABLE_KEY);
    }, this.DISABLE_DURATION);
  }

  formateDateandTime(valid, value) {
    if (!value) {
      return "N/A";
    }
    if (valid == "date") {
      return new Date(value).toLocaleString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    }
    if (valid == "time") {
      return new Date(value).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
    }
  }

  async sortConsultation() {
    if (this.totalCount == 0) {
      this.toaster.error(`Please select consultations`);
      return;
    }

    if (this.totalCount > 1000) {
      return this.toaster.error(
        `1000 consultations can be assign on single click`
      );
    }

    let formValue: any = {
      ...formatePayload(this.form.value),
      limit: this.totalCount,
      agentId: "null",
      callStatus: "pending",
    };
    const result = (await this.httpService.allConsultation(formValue)).data;

    const consulIds: any = result
      .filter((d) => !d.user && d.callStatus != "Closed") // Filter to include only objects where user is falsy
      .map((d) => d.consultationId);

    const modal = this.modalService.open(
      AssignAgentComponent,
      {
        centered: true,
        backdrop: "static",
        keyboard: false,
      },
      [
        {
          key: "agentIds",
          value: this.agents,
        },
        {
          key: "consultationsCount",
          value: consulIds.length,
        },
      ]
    );

    const agentIds = await this.modalService.confirmationModalResult(modal);

    let payload: any = {
      agentIds: agentIds,
      consultationsIds: consulIds,
    };

    if (agentIds) {
      const res = await this.httpService.sortConultation(payload);

      if (res) {
        await this.applyFilter();
        this.toaster.success(`Consultations have been sorted successfully`);
      }
    }
  }
}
