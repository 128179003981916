import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "../../core/services/toaster.service";
import { userTypeService } from "../../core/services/userType.service";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-AddUserType",
  templateUrl: "./AddUserType.component.html",
  styleUrls: ["./AddUserType.component.scss"],
})
export class AddUserTypeComponent implements OnInit {
  xmark = faXmark;
  typeDisplay: any;
  constructor(
    private activeModal: NgbActiveModal,
    private toaster: ToasterService,
    private http: userTypeService
  ) {}
  value: any;
  type;
  @Input() id: any;
  @Input() name: any;
  ngOnInit() {
    this.value = this.id;
    this.type = new FormControl(this.name || "");
  }

  close() {
    this.activeModal.close(false);
  }

  async submit() {
    // try {
    if (this.id) {
      await this.http.editTypes(this.id, this.type.value);
      this.toaster.success("User Type Updated Successfully");
      this.activeModal.close(true);
    }
    if (!this.id) {
      await this.http.addType(this.type.value);
      this.toaster.success("User Type Added Successfully");
      this.activeModal.close(true);
    }
    // } catch (err) {
    //   this.toaster.error(err.error.meta.message);
    // }
  }
}
