export const userData = [
  {
    id: 1,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Online",
    status: "Active",
    userType: "Admin",
    action: "icon",
  },
  {
    id: 2,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Offline",
    status: "Active",
    userType: "Admin",
    action: "icon",
  },
  {
    id: 3,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Online",
    status: "Active",
    userType: "Admin",
    action: "icon",
  },
  {
    id: 4,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Online",
    status: "Active",
    userType: "Admin",
    action: "icon",
  },
  {
    id: 5,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Offline",
    status: "Active",
    userType: "Admin",
    action: "icon",
  },
  {
    id: 6,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Online",
    status: "Active",
    userType: "Admin",
    action: "icon",
  },
  {
    id: 7,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Online",
    status: "Active",
    userType: "Admin",
    action: "icon",
  },
  {
    id: 8,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Offline",
    status: "Active",
    userType: "Admin",
    action: "icons",
  },
  {
    id: 9,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Online",
    status: "Active",
    userType: "Admin",
    action: "icon",
  },
  {
    id: 10,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Online",
    status: "Active",
    userType: "Admin",
    action: "icon",
  },
  {
    id: 11,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Offline",
    status: "Active",
    userType: "Admin",
    action: "icon",
  },
  {
    id: 12,
    image: "http://localhost:4200/assets/images/contents/user.jpg",
    fullNmae: "Arunita Shaikh",
    email: "arunitashaikh32@mil.com",
    phoneNumber: "+923121415234",
    onlineStatus: "Online",
    status: "Active",
    align: "left",
    userType: "Admin",
    action: "icon",
  },
];

export const tableHeader = [
  {
    id: 1,
    title: "S/no",
    icon: "",
    align: "left",
  },
  {
    id: 2,
    title: "Image",
    icon: "",
    align: "left",
  },
  {
    id: 3,
    title: "Full Name",
    icon: "",
    align: "left",
  },
  {
    id: 4,
    title: "Email",
    icon: "",
    align: "left",
  },
  {
    id: 5,
    title: "Phone",
    icon: "",
    align: "left",
  },
  {
    id: 6,
    title: "User Type",
    icon: "",
    align: "left",
  },
  { id: 7, title: "Agent Extension", icon: "", align: "left" },
  {
    id: 8,
    title: "Created At",
    icon: "",
    align: "left",
  },
  {
    id: 9,
    title: "Actions",
    icon: "fa-edit",
    align: "left",
  },
];

export const tableHeaderUserRights = [
  {
    id: 1,
    title: "S/no",
    icon: "",
  },
  {
    id: 2,
    title: "Features",
    icon: "",
  },
  {
    id: 3,
    title: "Perminssions",
    icon: "",
  },
];
export const tableHeaderUserTypes = [
  {
    id: 1,
    size: "40%",
    title: "S/no",
    align: "left",
    icon: "",
  },
  {
    id: 2,
    size: "40%",
    align: "left",
    title: "User Type",
    icon: "",
  },
  {
    id: 3,
    size: "40%",
    align: "right",
    title: "Action",
    icon: "",
  },
];

export const tableBodyUserType = [
  {
    id: 1,
    userType: "Admin",
    size: "40%",
    align: "right",
    action: "icon1",
  },
  {
    id: 2,
    size: "40%",
    align: "right",
    userType: "Sub-Admin",
    action: "icon1",
  },
  {
    id: 3,
    userType: "Agent",
    size: "40%",
    align: "right",
    action: "icon1",
  },
];

export const tableHeaderUserLocation = [
  {
    id: 1,
    size: "40%",
    title: "S/no",
    align: "left",
    icon: "",
  },
  {
    id: 2,
    size: "40%",
    align: "left",

    title: "Location Name",
    icon: "",
  },
  {
    id: 3,
    size: "40%",
    align: "right",

    title: "Action",
    icon: "",
  },
];

export const tableBodyUserLocation = [
  {
    id: 1,
    userLocation: "Hyderabad",
    size: "40%",
    align: "right",
    action: "icon1",
  },
  {
    id: 2,
    size: "40%",
    align: "right",
    userLocation: "Karachi",
    action: "icon1",
  },
  {
    id: 3,
    userLocation: "Latifabad",
    size: "40%",
    align: "right",
    action: "icon1",
  },
];

export const tableBodyUserRights = [
  {
    id: 1,
    features: "Dashboard",
    status: "icons1",
  },
  {
    id: 2,
    features: [
      "User Management",
      " User Rights ",
      " User Types ",
      " View All Users ",
      " User User Location ",
    ],
    status: "icons1",
  },
  {
    id: 3,
    features: "Reports",
    status: "icons1",
  },
  {
    id: 4,
    features: "Settings",
    status: "icons1",
  },
];

export const tableHeaderConsultation = [
  "Patient Name",
  "Doctor Name",
  "Company Name",
  "Consultation Status",
  "Totals attempts",
  "Agent Call Status",
  "Action",
  "Assigned Agent",
  "Duration",
  "Gender",
  "Date and Time",
  "Diagnosis",
  "Address",
  "Amount",
  "Type",
];
export const tableHeaderClosedCalls = [
  "Patient Name",
  "Doctor Name",
  "Company Name",
  "Consultation Status",
  "Totals attempts",
  "Agent Call Status",
  "Action",
  "Last Feedback Time and Date",
  "Assigned Agent",
  "Feedback taken by",
  "Duration",
  " Gender ",
  "Date and Time",
  "Diagnosis",
  "Address",
  "Amount",
  "Type",
];
export const tableHeaderActiveConsultation = [
  "Patient Name",
  "Doctor Name",
  "Consultation Status",
  "Agent Call Status",
  " Gender ",
  "Date ",
  "Time",
  "Address",
  "Amount",
  "Platform",
  "Company Name",
  "Payment Method",
  "Type",
];
export const tableBodyConsultation: any[] = [
  {
    id: 1,
    patient: "Mubashir",
    doctor: "Zubair",
    patientPhone: "03214556789 ",
    doctorPhone: "03214556789",
    consultationStatus: "Consulted",
    agentCall: "Called",
    gender: "Male",
    age: 21,
    date: "02-2-2022",
    time: "4:30pm",
    duration: "01h30m",
    address: "Tilak Inclicne , Hyderabad",
    diagnosis: "Flu",
    amount: "PKR 300",
    partner: "Sehat Kahani App",
    paymentMethod: "Jazzcash",
    type: "Instant",
  },
  {
    id: 2,
    patient: "Ali",
    doctor: "Zubair",
    patientPhone: "03214556789 ",
    doctorPhone: "03214556789",
    consultationStatus: "Consulted",
    agentCall: "Pending",
    gender: "Male",
    age: 22,
    date: "02-2-2022",
    time: "4:30pm",
    duration: "01h30m",
    address: "Tilak Inclicne , Hyderabad",
    diagnosis: "Flu",
    amount: "PKR 300",
    partner: "Sehat Kahani App",
    paymentMethod: "Jazzcash",
    type: "Instant",
  },
  {
    id: 3,
    patient: "Sania",
    doctor: "Zubair",
    patientPhone: "03214556789 ",
    doctorPhone: "03214556789",
    consultationStatus: "Consulted",
    agentCall: "Called(Ali Rehman)",
    gender: "Male",
    age: 32,
    date: "02-2-2022",
    time: "4:30pm",
    duration: "01h30m",
    address: "Tilak Inclicne , Hyderabad",
    diagnosis: "Flu",
    amount: "PKR 300",
    partner: "Sehat Kahani App",
    paymentMethod: "Jazzcash",
    type: "Instant",
  },
];

export const tableHeaderaftercall = [
  "S/no",
  "Queue Name",
  "Agent ID",
  "Agent Name",
  "CLI Number",
  "Call Reason",
  "Created Date",
  "Customer Name",
  " Booking Code",
];

export const tableBodyaftercall = [
  {
    id: 1,
    queue: "Sehat Kahani",
    agentID: "102",
    agentName: "Sana Shaikh",
    cli: "0345678901",
    reason: "customer service call",
    createdDate: "06 Dec 2021 12:55 pm",
    customerName: "Ali Shaikh",
    bookingCode: "#232255",
  },
  {
    id: 2,
    queue: "Sehat Kahani",
    agentID: "132",
    agentName: "Sana Shaikh",
    cli: "0345678901",
    reason: "customer service call",
    createdDate: "06 Dec 2021 12:55 pm",
    customerName: "Ali Shaikh",
    bookingCode: "#232255",
  },
  {
    id: 3,
    queue: "Sehat Kahani",
    agentID: "172",
    agentName: "Sana Shaikh",
    cli: "0345678901",
    reason: "customer service call",
    createdDate: "06 Dec 2021 12:55 pm",
    customerName: "Ali Shaikh",
    bookingCode: "#232255",
  },
];
