import { FormControl } from "@angular/forms";
export const EmptyStringValidator = (formControl: FormControl) => {
  if (typeof formControl.value === "string") {
    return formControl.value.length === 0
      ? null
      : formControl.value.trim().length > 0
      ? null
      : {
          emptyString: {
            valid: false,
          },
        };
  }
  return null;
};

export const validations = {
  firstName: {
    maxLength: 20,
  },
  lastName: {
    maxLength: 20,
  },
  email: {
    maxLength: 100,
  },
  password: {
    minLength: 8,
    maxLength: 20,
  },
  phoneNumber: {
    length: 15,
    minLength: 9,
  },
};
