import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Options } from "selenium-webdriver";
import { HttpService } from "./http.service";
import { ToasterService } from "./toaster.service";

@Injectable({
  providedIn: "root",
})
export class Telephony {
  constructor(private http: HttpService, private toaster: ToasterService) {}

  params = new HttpParams();

  telephony(param, exten) {
    try {
      let httpOptions: any = {
        headers: new HttpHeaders({
          validateCertificate: "false",
        }),
      };
      let headers = new HttpHeaders({});
      // this.params = this.params.append("phone", param);
      // this.params = this.params.append("exten", "108");
      let params = new HttpParams().set("phone", param).set("exten", exten);
      // const paramQ = this.http.createHttpParams(params);
      // return this.http.get(`http://192.168.100.17/amiscript.php`, params);
      return this.http.get("http://192.168.100.17/amiscript.php", params);
      // , {
      //   headers: httpOptions,
      //   params: params,
      // });
    } catch {}
  }
}
