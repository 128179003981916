import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";
@Injectable({
  providedIn: "root",
})
export class IncommingCallsService {
  constructor(private http: HttpService) {}

  async addIncommingCalls(payload: any) {
    return await this.http.post(`incomming-calls`, payload);
  }

  async getIncommingCalls(payload: any) {
    const paramQ = this.http.createHttpParams(payload);
    return await this.http.get(`incomming-calls`, paramQ);
  }
  async getIncommingCallbyId(id: any) {
    const paramQ = this.http.createHttpParams({ id });

    return await this.http.get(`incomming-calls`, paramQ);
  }
  async updateIncommingCallbyId(id: number, payload: any) {
    const paramQ = this.http.createHttpParams({ id });
    return await this.http.put(`incomming-calls`, payload, paramQ);
  }
  async getExcelIncommingCalls(payload: any) {
    const paramQ = this.http.createHttpParams(payload);
    return await this.http.get(`incomming-calls/excel-export`, paramQ);
  }

  async updateIncommingCallStatus(payload: any) {
    return await this.http.put(`incomming-calls/update-call-status`, payload);
  }
}
