<div class="container-fluid">
  <div class="row justify-content-between">
    <div class="col-12 col-sm-6 col-md-4">
      <label
        [ngStyle]="{
          display:
            value == 'view' ? 'none' : '' || value == 'edit' ? 'none' : ''
        }"
        >Select User</label
      >
      <select
        [ngStyle]="{
          display:
            value == 'view' ? 'none' : '' || value == 'edit' ? 'none' : ''
        }"
        class="form-control shadow-sm bg-body rounded"
        (change)="getPermission($event.target.value)"
      >
        <option selected hidden disabled>Select Here</option>
        <option *ngFor="let role of permissions" [value]="role.roleId">
          {{ role.role }}
        </option>
      </select>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
      <button
        class="btn btn-danger float-right"
        id="updateBtn"
        [ngStyle]="{ display: value == 'view' ? 'none' : '' }"
        (click)="updateUserSetting()"
      >
        Update
      </button>
    </div>
  </div>

  <div id="table_div" class="container-fluid scroll">
    <table class="table table-hover table-borderless mt-2">
      <thead>
        <tr>
          <td>S/no</td>
          <td>Features</td>
          <td>Permissions</td>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of permission; index as i"
          class="mt-4 shadow-sm bg-body rounded"
        >
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ item.permission }}
          </td>
          <td>
            <!-- formControlName="permissionId" -->
            <input type="hidden" [value]="item.id" />
            <!-- formControlName="view" -->
            <input
              [name]="item.permission + 'view'"
              type="checkbox"
              [checked]="item.view == '1'"
              value="1"
              (change)="inputAdd(item.permissionId, $event)"
            />
            View
            <!-- formControlName="edit" -->
            <input
              type="checkbox"
              [name]="item.permission + 'edit'"
              value="1"
              [checked]="item.edit == '1'"
              (change)="editAdd(item.permissionId, $event)"
            />
            Edit
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
