import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ngProgressBarService } from "./core/services/ngProgressBar.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent {
  isCollapsed = false;
  currentUrl = "";

  constructor(
    private router: Router,
    public progressService: ngProgressBarService
  ) {
    router.events.subscribe((e) => {
      this.onTitleHeaderChange(e);
    });
  }
  ngOnInit() {
    this.progressService.completeLoading();
    this.user();
  }

  onTitleHeaderChange(e) {
    switch (this.router.url) {
      case "/admin/dashboard":
        this.currentUrl = "Dashboard";

        return;
      case "/admin/doctors-panel":
        this.currentUrl = "Doctors Panel";

        return;
      case "/admin/users":
        this.currentUrl = "Users";

        return;
      case "/admin/user-reports":
        this.currentUrl = "Reports";

        return;
      case "/admin/role-permissions":
        this.currentUrl = "Role Permissions";

        return;
      case "/admin/roles":
        this.currentUrl = "Roles";

        return;
      case "/admin/user-location":
        this.currentUrl = "User Location";

        return;
      case "/admin/consultations":
        this.currentUrl = "Consultations";
        return;
      case "/admin/incomming-calls":
        this.currentUrl = "Helpline Calls";
        return;
      case "/admin/incomming-calls-closed":
        this.currentUrl = "Helpline Closed Calls";
        return;
      case "/admin/after-call-works":
        this.currentUrl = "After Call Work";
        return;
      case "/admin/hold-time":
        this.currentUrl = "Holdtime";
        return;
      case "/admin/active-calls":
        this.currentUrl = "Active Consultations";
        return;
      case "/admin/closed-calls":
        this.currentUrl = "Closed Calls";
        return;
      case "/admin/agent-extension":
        this.currentUrl = "Agent Extension";
        return;
      case "/admin/edit-profile":
        this.currentUrl = "Edit Profile";
        return;
      case "/admin/change-password":
        this.currentUrl = "Change Password";
        return;
      default:
        break;
    }
  }

  userName: any;
  user() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const permission = JSON.parse(sessionStorage.getItem("permission"));
    this.userName = `${user.firstName} ${user.lastName}`;
  }
}
