<div class="container-fluid">
  <div class="row">
    <div class="mb-3 col-12 col-sm-12 col-md-4 col-lg-4">
      <label for="exampleFormControlInput1" class="form-label">Search</label>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          aria-label="search"
          [formControl]="searchTab"
          aria-describedby="basic-addon1"
        />
        <div class="input-group-append" (click)="searchValue(searchTab.value)">
          <span class="input-group-text btn-danger" id="basic-addon1"
            ><fa-icon [icon]="icons.search" aria-hidden="true"></fa-icon
          ></span>
        </div>
      </div>
    </div>
    <div class="mb-3 col-12 col-sm-12 col-md-4 col-lg-4">
      <label for="exampleFormControlInput1" class="form-label">From</label>
      <nz-date-picker
        [(ngModel)]="startDate"
        class="form-control shadow-sm bg-body rounded"
      ></nz-date-picker>
    </div>
    <div class="mb-3 col-12 col-sm-12 col-md-4 col-lg-4 pl-md-0">
      <label for="exampleFormControlInput1" class="form-label">To</label>
      <nz-date-picker
        [(ngModel)]="endDate"
        class="form-control shadow-sm bg-body rounded"
      ></nz-date-picker>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8">Total Count: {{ totalCount }}</div>
    <div style="padding-left: 0 !important" class="col-sm-4">
      <button
        class="btn btn-danger float-right"
        (click)="searchValue(searchTab.value)"
      >
        Apply Filter
      </button>
      <button class="btn btn-danger float-left" (click)="editUser()">
        Add User
      </button>
    </div>
  </div>
  <!-- User Table -->

  <div id="table_div" class="container-fluid scroll">
    <div class="row mt-2">
      <table
        class="table table-hover table-borderless"
        [ngClass]="{ scroll: responseData !== undefined }"
      >
        <thead>
          <tr>
            <td *ngFor="let item of tableHeader">
              {{ item.title }}
            </td>
          </tr>
        </thead>
        <tbody class="mt-4">
          <tr
            *ngFor="let item of responseData; index as i"
            class="mt-4 shadow-sm bg-body rounded"
          >
            <td id="seriol" style="width: 10px">{{ i + 1 }}</td>

            <td>-</td>

            <td>{{ item.firstName + " " + item.lastName }}</td>
            <td>{{ item.email }}</td>

            <td>{{ item.phone }}</td>
            <td>{{ item.userRole.userRole.name }}</td>
            <td>
              {{
                item.agentExtension == null || item.agentExtension == undefined
                  ? "-"
                  : item.agentExtension
              }}
            </td>
            <td>{{ formateDate(item.updatedAt) }}</td>
            <td id="action">
              <fa-icon
                [icon]="icons.editUser"
                (click)="editUser(i)"
                class="table-icon green"
                style="margin: 0 4px"
              ></fa-icon>
              <fa-icon
                [icon]="icons.editPassword"
                (click)="editPassword(item.id)"
                class="table-icon green"
                style="margin: 0 4px"
              ></fa-icon>
              <fa-icon
                [icon]="icons.delete"
                (click)="deleteUser(item.id)"
                class="table-icon pink"
                style="margin: 0 4px"
              ></fa-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="text-center field-loader" *ngIf="responseData === undefined">
    <img src="assets/images/SK-Logo-Animation.gif" alt="" />
  </div>
  <app-pagination
    (pageNumber)="requestPaginate($event)"
    [totalCount]="totalCount"
  ></app-pagination>
</div>
