import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { paginate } from "../enums/paginate";
import { HttpService } from "./http.service";
import { formatePayload } from "src/app/shared/helper";
import { getAllConsutations } from "src/app/shared/interface";

@Injectable({
  providedIn: "root",
})
export class ConsultationService {
  constructor(private http: HttpService) {}

  async getPatientConsutation() {
    return await this.http.get(`consultations/get-consultations`);
  }

  async getPatientConsulationCallStatus() {
    return await this.http.get(`consultations/get-call-consultations`);
  }
  async allConsultation(payload: getAllConsutations) {
    //
    // limit: number = paginate.defaultLimit,
    // offset: number = paginate.defaultOffset,
    // status: string | string[],
    // startDate: number,
    // endDate: number,
    // doctor?: string,
    // partner?: string,
    // type?: string,
    // callType?: string,
    //   patientNumber ?: string

    // let paramObject: any = {
    //   callStatus: status,
    //   startDate: startDate ? startDate.toString() : "",
    //   endDate: endDate ? endDate.toString() : "",
    //   doctor: doctor != "" ? doctor : null,
    //   partner: partner != "" ? partner : null,
    //   type: type != "" ? type : null,
    //   consultationStatus: callType != "" ? callType : null,
    //   patientNumber: patientNumber != "" ? patientNumber : null,
    //   offset,
    // };
    const formatePay = formatePayload(payload);
    const paramQ = this.http.createHttpParams(formatePay);

    return await this.http.get(`consultations/all`, paramQ);
  }
  async allConsultationExcel(payload: any) {
    const formatePay = formatePayload(payload);
    const paramQ = this.http.createHttpParams(formatePay);

    return await this.http.get(`consultations/excel-export`, paramQ);
  }

  async sendConsultationFeedback(
    id: number,
    status: string,
    agentName: string,
    feedback: {}[]
  ) {
    let body = {
      consultationId: id,
      consultationStatus: status,
      // agentName: agentName,
      feedback: feedback,
    };
    return await this.http.post(`consultations/feedback`, body);
  }
  async closeConsultationStatus(id) {
    return await this.http.put(`consultations/status/${id}`);
  }
  async closeBulkConsultationStatus(param) {
    // const paramQ = this.http.createHttpParams(param);
    return await this.http.put(`consultations/bulk-update-call-status`, param);
  }
  async getconsultationFieldsValue(id) {
    return await this.http.get(`consultations/feedback-details/${id}`);
  }

  async getRetailCorpData(platform) {
    let platformURL;
    switch (platform) {
      case "retail":
        platformURL = "retail";
        break;
      case "corp":
        platform = "corp";
        break;
    }
    let param: any = { platform: platform };
    return await this.http.get(
      `consultations/retail-corp-consultations`,
      param
    );
  }

  async putcallStatusCheck(consultationId: number, status: any) {
    return await this.http.put(
      `consultations/check-update-feedback-call/${consultationId}`,
      status
    );
  }

  async sortConultation(payload: {
    consultaionsIds: number[];
    agentIds: string[];
  }) {
    return await this.http.put(`consultations/sort-consultation`, payload);
  }
}
