<div>
  <div
    class="container-top"
    [ngStyle]="{ height: isCollapsed ? '155px' : '200px' }"
  >
    <!-- <div class="profile" style="width: 50px ; height: 50px;" > -->
    <div
      class="profile"
      [ngStyle]="{
        width: isCollapsed ? '50px' : '85px',
        height: isCollapsed ? '50px' : '85px'
      }"
    >
      <img
        src="../../../../assets/images/user.png"
        style="width: 100%; height: 100%; border-radius: 50%"
        alt=""
      />
    </div>
    <div [ngStyle]="{ display: isCollapsed ? 'none' : 'block' }">
      <h3 id="userId">
        <span class="hd-span"> {{ userName | titlecase }} </span>
        <span class="hd-span"> {{ userType | titlecase }} </span>
      </h3>
    </div>
  </div>
  <div>
    <ul
      class="menuBar"
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li
        routerLinkActive="ant-menu-item-selected"
        *ngFor="let item of sidebarDetails"
        nz-menu-item
        [ngStyle]="{ display: item.nested || item.view == 0 ? 'none' : '' }"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? item.title : ''"
        [routerLink]="item.routerLink"
      >
        <i nz-icon [nzType]="item.icon"></i>
        <span [ngClass]="selected == item.id ? 'selectedTabColor' : ''">{{
          item.title
        }}</span>
      </li>

      <li
        *ngFor="let item of sidebarDetails"
        [ngStyle]="{ display: !item.nested || item.view == 0 ? 'none' : '' }"
        nz-submenu
        [nzTitle]="item.title"
        [nzIcon]="item.icon"
        class="hover"
      >
        <ul>
          <li
            routerLinkActive="ant-menu-item-selected"
            *ngFor="let item of item.nested"
            [ngStyle]="{ display: !item.view ? 'none' : '' }"
            [routerLink]="item.routerLink"
            nz-menu-item
          >
            <a>{{ item.title }}</a>
          </li>
        </ul>
      </li>
      <li
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Log Out' : ''"
        (click)="logout()"
      >
        <i nz-icon nzType="logout"></i>
        <span>Log Out</span>
      </li>
    </ul>
  </div>
</div>
