import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: "root" })
export class ToasterService {
  constructor(private toastr: ToastrService) {}

  error(text: any) {
    this.toastr.error(text);
  }

  info(text: string) {
    this.toastr.info(text);
  }

  success(text: string) {
    this.toastr.success(text);
  }

  warning(text: string) {
    this.toastr.warning(text);
  }
}
