import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { paginate } from "src/app/admin/core/enums/paginate";
import { AllUserService } from "src/app/admin/core/services/allUsers.service";
import { ModalService } from "src/app/admin/core/services/modal.service";
import { ToasterService } from "src/app/admin/core/services/toaster.service";
import { AddUserComponent } from "src/app/admin/modals/AddUser/AddUser.component";
import { EditPasswordComponent } from "src/app/admin/modals/edit-password/edit-password.component";
import { ModalServiceAttributes } from "src/app/shared/interface";
import Swal from "sweetalert2";
import { tableHeader } from "../../data";
import {
  faUserPen,
  faUnlockKeyhole,
  faTrash,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

// declare var window: any;

@Component({
  selector: "app-viwe-all-users",
  templateUrl: "./view-all-users.component.html",
  styleUrls: ["./view-all-users.component.scss"],
})
export class ViewAllUsersComponent implements OnInit {
  icons = {
    editUser: faUserPen,
    editPassword: faUnlockKeyhole,
    delete: faTrash,
    search: faMagnifyingGlass,
  };
  tableHeader: any = tableHeader;
  onChangeInputeEventFrom: any;
  onChangeInputeEventTo: any;
  startDate: any = null;
  endDate: any = null;
  formModal: any;
  responseData: any;
  totalCount: number = 0;

  constructor(
    private modalService: ModalService,
    private users: AllUserService,
    private router: Router,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.startDate = new Date();
    this.endDate = new Date();
    this.searchValue();
  }
  searchTab = new FormControl("");

  items: any;
  async getUserValue(offset: any) {
    try {
      const apiResponse = await this.users.getAllUsers({
        limit: paginate.defaultLimit,
        offset,
      });
      this.responseData = apiResponse.data;
      this.totalCount = apiResponse.meta.totalCount;
    } catch (err) {
      this.toaster.error(err.message);
    }
  }
  filters: any = {};
  setQueryFilters() {
    const sd = new Date(this.startDate);
    const ed = new Date(this.endDate);
    this.filters.startDate = isNaN(sd.getTime())
      ? new Date().getTime()
      : sd.getTime();
    this.filters.endDate = isNaN(ed.getTime())
      ? new Date().getTime()
      : ed.getTime();
    this.filters.search = this.searchTab.value;
    return this.filters;
  }

  async addNewUser() {
    // const attributes: ModalServiceAttributes[] = [];
    // attributes.push({
    //   key: "valueData",
    //   value: "",
    // });
    // const result = this.modalService.open(
    //   AddUserComponent,
    //   {
    //     centered: true,
    //     backdrop: "static",
    //   },
    //   attributes
    // );
    // const success = this.modalService.confirmationModalResult(result);
    // if (success) {
    //   await this.searchValue();
    // }
  }

  async editUser(index?: any) {
    const attributes: ModalServiceAttributes[] = [];
    index != undefined
      ? attributes.push({
          key: "valueData",
          value: this.responseData[index],
        })
      : attributes.push({
          key: "valueData",
          value: "",
        });
    const result = this.modalService.open(
      AddUserComponent,
      {
        centered: true,
        backdrop: "static",
      },
      attributes
    );
    const success = await this.modalService.confirmationModalResult(result);
    if (success) {
      this.searchValue();
    }
  }
  viewUser(id: any) {
    this.router.navigate(["userManagement", "detail", "ID", id]);
  }
  async searchValue(offset = 0) {
    try {
      this.setQueryFilters();
      const apiResponse = await this.users.getUserbyDate({
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        limit: paginate.defaultLimit,
        offset: offset,
        search: this.filters.search,
      });
      this.responseData = apiResponse.data;
      this.totalCount = apiResponse.meta.totalCount;
    } catch (err) {
      this.toaster.error(err.error.meta.message);
    }
  }
  requestPaginate(offset: number) {
    this.responseData = [];
    this.getUserValue(offset);
  }
  formateDate(date: any) {
    return new Date(date).toDateString();
  }

  async editPassword(id: any) {
    const attributes: ModalServiceAttributes[] = [];
    attributes.push({
      key: "userId",
      value: id,
    });
    const result = this.modalService.open(
      EditPasswordComponent,
      {
        centered: true,
        backdrop: "static",
      },
      attributes
    );
    const success = await this.modalService.confirmationModalResult(result);
    if (success) {
      this.toaster.success("Password updated successfully");
    }
  }

  async deleteUser(id: string) {
    await Swal.fire({
      title: "Are you sure to delete User?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d00140",
      cancelButtonColor: "#7cc707",
      confirmButtonText: "Yes, Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await this.users.deleteUserByAdmin(id);
        this.toaster.success(`User deleted successfully`);
        if (response) {
          this.searchValue();
        }
      }
    });
  }
}
