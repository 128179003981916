import { Component, Input, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  adminAppValidations,
  AppConstant,
  signupPhoneValidate,
} from "src/app/shared/interface";
import { AuthService } from "../../core/services/auth.service";
import { roleAndLocationService } from "../../core/services/role-and-location-all.service";
import { ToasterService } from "../../core/services/toaster.service";
import { EmptyStringValidator } from "../../validators/validators";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-AddUser",
  templateUrl: "./AddUser.component.html",
  styleUrls: ["./AddUser.component.scss"],
})
export class AddUserComponent implements OnInit {
  @Input() valueData: any;
  xmark = faXmark;
  validations = adminAppValidations;
  formValue: any;

  addUserForm: any;
  res: any;
  resLocation: any;
  resRoles: any;
  validate: any;
  constructor(
    private PostData: AuthService,
    private toaster: ToasterService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private http: roleAndLocationService,
    private httpUser: AuthService
  ) {}

  async ngOnInit() {
    await this.formData();
    this.getRoleandLocation();
    // this.getUser();
    // this.asignFormValue();
  }

  emptySpacesNotAllowed = AppConstant.messages.emptySpacesNotAllowed;

  async formData() {
    this.addUserForm = this.formBuilder.group({
      firstName: [
        (this.valueData && this.valueData.firstName) || "",
        [
          Validators.required,
          EmptyStringValidator,
          Validators.maxLength(this.validations.name.length),
        ],
      ],
      lastName: [
        (this.valueData && this.valueData.lastName) || "",
        [
          Validators.required,
          EmptyStringValidator,
          Validators.maxLength(this.validations.name.length),
        ],
      ],
      email: [
        (this.valueData && this.valueData.email) || "",
        [
          Validators.required,
          Validators.pattern(AppConstant.emailPattern),
          Validators.maxLength(this.validations.email.length),
        ],
      ],
      phone: [
        (this.valueData && this.valueData.phone) || "",
        [
          Validators.required,
          Validators.minLength(this.validations.phone.minlength),
          Validators.pattern(signupPhoneValidate),
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(this.validations.password.minLength),
        ],
      ],
      confirm: [
        "",
        [
          Validators.required,
          Validators.minLength(this.validations.password.minLength),
        ],
      ],
      gender: [
        (this.valueData && this.valueData.gender) || "",
        [Validators.required],
      ],
      userRole: [
        (this.valueData && [
          this.valueData.userRole.roleId,
          this.valueData.userRole.userRole.name,
        ]) ||
          "",
        [Validators.required],
      ],
      address: [
        (this.valueData && this.valueData.address) || "",
        [Validators.required],
      ],
      agentExtension: [
        (this.valueData && this.valueData.agentExtension) || "",
        [Validators.required],
      ],
      // agentExtension: [
      //   (this.valueData && this.valueData.agentExtension) || "",
      //   [Validators.required],
      // ],
    });
  }

  asignFormValue() {
    this.addUserForm.valueChanges.subscribe((value: any) => {
      this.formValue = value;
    });
  }
  async onSubmit() {
    this.formValue = this.addUserForm.value;
    try {
      if (this.formValue.password === this.formValue.confirm) {
        await this.PostData.addUsers(await this.createPayload("addUser"));
        this.toaster.success("Added Succesfully");
        this.activeModal.close(true);
      } else {
        this.toaster.error("Please Confirm Your Password");
      }
    } catch (err) {
      // this.toaster.error(err.error.meta.message);
    }
  }
  close() {
    this.activeModal.close(false);
  }

  async getRoleandLocation() {
    this.res = (await this.http.getAllRoleandLocation()).data;
    this.resLocation = await this.res.locations;
    this.resRoles = await this.res.roles;
  }

  async UpdateUser() {
    try {
      await this.httpUser.updateUser(
        this.valueData.id,
        await this.createPayload("editUser")
      );
      this.toaster.success("User is updated Successfully");
      this.activeModal.close(true);
    } catch (err) {
      this.toaster.error(err.error.meta.message);
    }
  }

  // let formValue: any = this.addUserForm.value;
  async createPayload(btn: string) {
    this.formValue = this.addUserForm.value;
    let userRole = await this.userRoleIDandName("id");
    let body: {} = {
      id: this.valueData.id,
      firstName: this.formValue.firstName,
      lastName: this.formValue.lastName,
      phone: this.formValue.phone,
      gender: this.formValue.gender,
      location: this.formValue.address,
    };
    if (btn === "addUser") {
      body = {
        ...body,
        role: userRole,
        email: this.formValue.email,
        password: this.formValue.password,
      };
      if (this.formValue.agentExtension !== "") {
        body = {
          ...body,
          agentExtension: this.formValue.agentExtension,
        };
      }
    } else {
      body = {
        ...body,
        roleId: userRole,
        agentExtension: +this.formValue.agentExtension,
      };
    }

    return body;
  }

  userRoleIDandName(value: string) {
    let userRole: number;
    if (typeof this.formValue.userRole === "string") {
      userRole = this.formValue.userRole.split(",");
    } else {
      userRole = this.formValue.userRole;
    }
    if (value === "id") {
      return userRole[0];
    } else {
      return userRole[1];
    }
  }

  OnChange() {
    // let role = this.userRoleIDandName("name");
    // if (role == "agent") {
    //   this.addUserForm.get("agentExtension").setvalidators(Validators.required);
    // } else {
    //   this.addUserForm.get("agentExtension").setvalidators(null);
    // }
  }
}
