<div class="container">
  <div class="row">
    <div class="col-12">
      <h3 id="heading">Update <span> Password </span></h3>
      <span class="close-modal-btn" (click)="close()">
        <fa-icon [icon]="xmark"></fa-icon>
      </span>

      <div>
        <hr class="borderdec" />
      </div>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit()" action="">
    <div class="row">
      <!-- <div class="col-12">
        <input
          type="password"
          class="form-control shadow-sm bg-body rounded"
          placeholder="Password"
          formControlName="password"
          [class]="
            form.get('password') &&
            form.get('password').touched &&
            form.get('password').invalid
              ? 'is-invalid'
              : ''
          "
        />
      </div> -->
      <div class="col-12">
        <input
          formControlName="password"
          type="password"
          class="form-control shadow-sm bg-body rounded bgcolorr"
          placeholder="Password"
          [class]="
            form.get('password') &&
            form.get('password').touched &&
            form.get('password').errors
              ? 'is-invalid'
              : ''
          "
          [minlength]="validations.password.minLength"
          [maxlength]="validations.password.maxLength"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            form.get('password') &&
            form.get('password').touched &&
            form.get('password').errors
          "
        >
          <span *ngIf="form.get('password').getError('required')"
            >Required Field</span
          >
          <span *ngIf="form.get('password').getError('minlength')"
            >Password must be 8 characters long</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <input
          formControlName="confirm"
          type="password"
          class="form-control shadow-sm bg-body rounded bgcolorr"
          placeholder="Confirm Password"
          [class]="
            form.get('confirm') &&
            form.get('confirm').touched &&
            form.get('confirm').errors
              ? 'is-invalid'
              : ''
          "
          [minlength]="validations.password.minLength"
          [maxlength]="validations.password.maxLength"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            form.get('confirm') &&
            form.get('confirm').touched &&
            form.get('confirm').errors
          "
        >
          <span *ngIf="form.get('confirm').getError('required')"
            >Required Field</span
          >
          <span *ngIf="form.get('confirm').getError('minlength')"
            >Password must be 8 characters long</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button
          class="btn btn-danger btn-lg align-content-center"
          type="submit"
          [disabled]="this.form.invalid"
        >
          <!-- (click)="submit()" -->
          Update Password
        </button>
      </div>
    </div>
  </form>
</div>
