import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Chart } from "chart.js";
import { dateTofirstDay, formateToTimeStamp } from "src/app/shared/helper";
import {
  BarChartParams,
  pieAndDonutChartInterface,
} from "../../shared/interface";
import { charts } from "../core/services/charts.service";
import { DoctorsPanelServices } from "../core/services/doctorsPanel.service";

@Component({
  selector: "app-doctors-panel",
  templateUrl: "./doctors-panel.component.html",
  styleUrls: ["./doctors-panel.component.scss"],
})
export class DoctorsPanelComponent implements OnInit {
  doctorBehaivourChart = null;
  doctorsRating = null;
  ratAndBehave = undefined;
  topDoctorsResponse: any = undefined;
  constructor(
    private formBuilder: FormBuilder,
    private charts: charts,
    private doctorsPanel: DoctorsPanelServices
  ) {}
  todaysDate: Date = new Date();
  startDate: Date | number = dateTofirstDay({
    type: "string",
    date: new Date(),
    day: 1,
  });
  filterForm: any;
  filterFormValue: any;
  ngOnInit(): void {
    this.initFilterForm();
    this.filterFormValue = this.filterForm.value;
    this.applyFilter();
  }
  async applyFilter() {
    this.filterFormValue.startDate = formateToTimeStamp(
      this.filterFormValue.startDate
    );
    this.filterFormValue.endDate = formateToTimeStamp(
      this.filterFormValue.endDate
    );
    this.getTopDoctors();
    this.getDoctorsRatingAndBehaviour();
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      startDate: [this.startDate],
      endDate: [this.todaysDate],
    });
  }
  async getDoctorsRatingAndBehaviour() {
    const response = await this.doctorsPanel.getDoctorsRatingAndBehaviour(
      this.filterFormValue
    );
    this.ratAndBehave = await response.data;
    this.doctorsRatingChart(this.ratAndBehave);
    this.doctorsBehaviourChart(this.ratAndBehave);
  }
  async doctorsRatingChart(payload: any) {
    const ratingsId = ["EI1", "EI2", "EI3", "EI4", "EI5"];
    const colors = ["#7cc707", "#d00140", "#F7D060", "#b57edc", "#FFB000"];
    payload = {
      data: payload,
      ids: ratingsId,
      colors,
    };
    const data: pieAndDonutChartInterface = {
      type: "pie",
      title: "doctorsRatings",
      labels: ["Excellent", "Good", "Fair", "Neutral", "Unsatisfactory"],
      chart: this.doctorsRating,
      dataTypes: {
        label: "Count",
        backgroundColor: colors,
        data: [
          this.filterData("EI1"),
          this.filterData("EI2"),
          this.filterData("EI3"),
          this.filterData("EI4"),
          this.filterData("EI5"),
        ],
      },
    };
    this.doctorsRating = await this.charts.pieOrDonutChart(data);
  }
  async doctorsBehaviourChart(payload: any) {
    const label = ["Doctor Behaviour"];
    const data: BarChartParams = {
      title: "doctorsBehaviour",

      labels: label,
      chart: this.doctorBehaivourChart,
      comparative: true,
      datasetsArr: [
        {
          label: ["Dr. behaviour was not professional"],
          backgroundColor: ["#7cc707"],
          data: [this.filterData("EM1")],
        },
        {
          label: ["Dr. was not attentive during consultaiton"],
          backgroundColor: ["#d00140"],
          data: [this.filterData("EM2")],
        },
        {
          label: ["Dr. was using false language"],
          backgroundColor: ["#F7D060"],
          data: [this.filterData("EM3")],
        },
        {
          label: ["Dr. was harrassing"],
          backgroundColor: ["#b57edc"],
          data: [this.filterData("EM4")],
        },
        {
          label: ["Dr. provided a false prescription"],
          backgroundColor: ["#952323"],
          data: [this.filterData("EM5")],
        },
        {
          label: ["Other comments by user"],
          backgroundColor: ["#FFB000"],
          data: [this.filterData("EM6")],
        },
        {
          label: ["N/A"],
          backgroundColor: ["#0C356A"],
          data: [this.filterData("EM7")],
        },
      ],
    };
    this.doctorBehaivourChart = await this.charts.barChart(data);
  }

  filterData(id: string): number {
    let value: string | number;
    this.ratAndBehave.map((ar: any) => {
      if (ar.optionId === id) {
        value = ar.countAnswers;
      }
    });
    return +value || 0;
  }

  async getTopDoctors() {
    const response = await this.doctorsPanel.getTopDoctors(
      this.filterFormValue
    );
    this.topDoctorsResponse = await response.data;
  }
}
