<nav aria-label="Page navigation " id="navbar">
  <!-- <ul class="pagination">
    <li
      class="page-item"
      (click)="
        requestPaginate(
          this.currentPage - 1 < 0 ? this.currentPage : this.currentPage - 1
        )
      "
    >
      <a class="page-link"><i class="fas fa-chevron-left"></i></a>
    </li>
    <li
      class="page-item"
      *ngFor="let item of paginateNumberArray; let i = index"
    >
      <a
        class="page-link"
        [ngClass]="{ 'active-page': currentPage == i }"
        (click)="requestPaginate(i)"
        >{{ i + 1 }}</a
      >
    </li>
    <li
      class="page-item"
      (click)="
        requestPaginate(
          this.currentPage + 1 >= paginateNumber
            ? this.currentPage
            : this.currentPage + 1
        )
      "
    >
      <a class="page-link"><i class="fas fa-chevron-right"></i></a>
    </li>
  </ul> -->
  <nz-pagination
    (nzPageIndexChange)="requestPaginate($event)"
    [nzTotal]="totalCount"
  ></nz-pagination>
</nav>
<div class="m-auto"></div>
<!-- [nzPageIndex]="" -->
