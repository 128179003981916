import { Injectable } from "@angular/core";
import { NgProgressRef } from "@ngx-progressbar/core";

@Injectable({
  providedIn: "root",
})
export class ngProgressBarService {
  public progressRef: NgProgressRef;
  constructor() {}

  ngOnInit() {}

  startLoading() {
    this.progressRef.start();
  }

  completeLoading() {
    this.progressRef.complete();
  }
  changeProgressColor() {
    this.progressRef.setConfig({ color: "#d00140" });
  }
}
