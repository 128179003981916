import { IFedbackField, rowType } from "../interface";

export const MissedConsultationFormField: IFedbackField[] = [
  {
    label: "Call Status",
    placeholder: "Select",
    questionId: "CE",
    type: "select",
    options: [
      { value: "CE1", label: "Received" },
      { value: "CE2", label: "Not Answering" },
      {
        value: "CE3",
        label: "Powered off",
      },
      { value: "CE4", label: "Invalid number" },
      { value: "CE5", label: "User cut the call" },
      { value: "CE6", label: "Not reachable" },
      { value: "CE7", label: "Landline number provided" },
      { value: "CE8", label: "International number provided" },
      {
        value: "CE9",
        label: "Busy on another call",
      },
    ],
  },
  {
    label: "Patient's Comments",
    placeholder: "Select",
    questionId: "CF",
    type: "select",
    options: [
      {
        value: "CF1",
        label: "Doctor is not responding on the app",
      },
      {
        value: "CF2",
        label: "Doctor is not available for booked appointment",
      },
      {
        value: "CF3",
        label: "Doctor had internet connectivity issues",
      },
      {
        value: "CF4",
        label: "Dr. was busy with another booked appointment",
      },
      { value: "CF5", label: "I was testing the app" },
      {
        value: "CF6",
        label: "User hung up",
      },
      {
        value: "CF7",
        label: "User has asked not to call again",
      },
      {
        value: "CF8",
        label: "Wrong number provided",
      },
      {
        value: "CF9",
        label: "Internet and connectivity issues on application",
      },
      {
        value: "CF10",
        label: "Internet and connectivity issue on patient's side",
      },
      {
        value: "CF11",
        label: "Other comment by user",
      },
      {
        value: "CF12",
        label: "Call was received but no one spoke",
      },
      {
        value: "CF13",
        label: "SK employee",
      },
      {
        value: "CF14",
        label:
          "Family member have consulted and I don't know about their feedback",
      },
      {
        value: "CF15",
        label: "Family member has consulted and their feedback is good",
      },
      {
        value: "CF16",
        label: "Can't talk busy right now",
      },
      {
        value: "CF17",
        label: "Got connected to a wrong doctor",
      },
      {
        value: "CF18",
        label: "IPAS consultation",
      },
      {
        value: "CF19",
        label: "Language Barrier",
      },
      {
        value: "CF20",
        label: "Call was received but no one spoke",
      },
      {
        value: "CF21",
        label: "Got busy somewhere will consult later",
      },
    ],
  },
  {
    label: "Action By Agent",
    placeholder: "Select",
    questionId: "CG",
    type: "select",
    options: [
      {
        value: "CG1",
        label: "Reconnected pt. with the same doctor",
      },
      {
        label: "Connected pt. with another doctor",
        value: "CG2",
      },
      {
        label: "Guided pt. to book another appointment",
        value: "CG3",
      },
      {
        label: "Guided pt. to the helpline",
        value: "CG4",
      },
      {
        value: "CG5",
        label: "N/A",
      },
      {
        value: "CG6",
        label: "Booked an appointment for relevant doctor",
      },
      {
        value: "CG7",
        label: "Called doctor for their missed consultation",
      },
      {
        value: "CG8",
        label: "Guided pt. towards helpline",
      },
      {
        value: "CG9",
        label: "Reconnected on their own",
      },
    ],
  },
  {
    label: "Remarks",
    placeholder: "Type",
    questionId: "CH",
    type: "input",
  },
];
export const rejectedMissedConsultationFormField: IFedbackField[] = [
  {
    label: "Call Status",
    placeholder: "Select",
    questionId: "DE",
    type: "select",
    options: [
      { value: "DE1", label: "Received" },
      { value: "DE2", label: "Not Answering" },
      {
        value: "DE3",
        label: "Powered off",
      },
      { value: "DE4", label: "Invalid number" },
      { value: "DE5", label: "User cut the call" },
      { value: "DE6", label: "Not reachable" },
      { value: "DE7", label: "Landline number provided" },
      { value: "DE8", label: "International number provided" },
      {
        value: "DE9",
        label: "Busy on another call",
      },
    ],
  },
  {
    label: "Patient's Comments",
    placeholder: "Select",
    questionId: "DF",
    type: "select",
    options: [
      {
        value: "DF1",
        label: "Doctor is not responding on the app",
      },
      {
        value: "DF2",
        label: "Doctor is not available for booked appointment",
      },
      {
        value: "DF3",
        label: "Doctor had internet connectivity issues",
      },
      {
        value: "DF4",
        label: "Dr. was busy with another booked appointment",
      },
      { value: "DF5", label: "I was testing the app" },
      {
        value: "DF6",
        label: "User hung up",
      },
      {
        value: "DF7",
        label: "User has asked not to call again",
      },
      {
        value: "DF8",
        label: "Wrong number provided",
      },
    ],
  },
  {
    label: "Action By Agent",
    placeholder: "Select",
    questionId: "DG",
    type: "select",
    options: [
      {
        value: "DG1",
        label: "Reconnected pt. with the same doctor",
      },
      {
        label: "Connected pt. with another doctor",
        value: "DG2",
      },
      {
        label: "Guided pt. to book another appointment",
        value: "DG3",
      },
      {
        label: "Guided pt. to the helpline",
        value: "DG4",
      },
      {
        value: "DG5",
        label: "N/A",
      },
    ],
  },
  {
    label: "Remarks",
    placeholder: "Type",
    questionId: "DH",
    type: "input",
  },
];
export const DoctorMissedConsultationFormField: IFedbackField[] = [
  {
    label: "Call Status",
    placeholder: "Select",
    questionId: "CA",
    type: "select",
    options: [
      { value: "CA1", label: "Received" },
      { value: "CA2", label: "Not Answering" },
      {
        value: "CA3",
        label: "Powered off",
      },
      { value: "CA4", label: "Invalid number" },
      { value: "CA5", label: "User cut the call" },
      { value: "CA6", label: "Not reachable" },
      { value: "CA7", label: "Landline number provided" },
      { value: "CA8", label: "International number provided" },
      {
        value: "CA9",
        label: "Busy on another call",
      },
      ,
    ],
  },
  {
    label: "Remarks of doctor",
    placeholder: "Select",
    questionId: "CB",
    type: "select",
    options: [
      { value: "CB1", label: "Notification issue" },
      { value: "CB2", label: "Application crashed" },
      { value: "CB3", label: "Not available for the consultation" },
      {
        value: "CB4",
        label: "Forgot to turn off instant",
      },
      { value: "CB5", label: "Internet and connectivity issue on app" },
    ],
  },
  {
    label: "Remarks by agent",
    placeholder: "Select",
    questionId: "CC",
    type: "select",
    options: [
      { value: "CC1", label: "Perfomed live testing for notification issue" },
      { value: "CC2", label: "Issue forwarded to tech" },
      { value: "CC3", label: "N/A" },
    ],
  },
  {
    label: "Remarks",
    placeholder: "Type",
    questionId: "CD",
    type: "input",
  },
];
export const rejectedDoctorMissedConsultationFormField: IFedbackField[] = [
  {
    label: "Call Status",
    placeholder: "Select",
    questionId: "DA",
    type: "select",
    options: [
      { value: "DA1", label: "Received" },
      { value: "DA2", label: "Not Answering" },
      {
        value: "DA3",
        label: "Powered off",
      },
      { value: "DA4", label: "Invalid number" },
      { value: "DA5", label: "User cut the Call" },
      { value: "DA6", label: "Not reachable" },
      { value: "DA7", label: "Landline number provided" },
      { value: "DA8", label: "International number provided" },
      {
        value: "DA9",
        label: "Busy on another call",
      },
    ],
  },
  {
    label: "Remarks of doctor",
    placeholder: "Select",
    questionId: "DB",
    type: "select",
    options: [
      { value: "DB1", label: "Notification issue" },
      { value: "DB2", label: "Application crashed" },
      { value: "DB3", label: "Not available for the consultation" },
      {
        value: "DB4",
        label: "Forgot to turn off instant",
      },
      { value: "DB4", label: "Internet and connectivity issue on app" },
    ],
  },
  {
    label: "Remarks by agent",
    placeholder: "Select",
    questionId: "DC",
    type: "select",
    options: [
      { value: "DC1", label: "Perfomed live testing for notification issue" },
      { value: "DC2", label: "Issue forwarded to tech" },
      { value: "DC3", label: "N/A" },
    ],
  },
  {
    label: "Remarks",
    placeholder: "Type",
    questionId: "DD",
    type: "input",
  },
];
export const CancelledConsultationFormFields: IFedbackField[] = [
  {
    label: "Call Status",
    placeholder: "Select Status",
    questionId: "R",
    type: "select",
    options: [
      { value: "R1", label: "Received" },
      { value: "R2", label: "Not Answering" },
      {
        value: "R3",
        label: "User is busy on another call",
      },
      { value: "R4", label: "User cut the call" },
      { value: "R5", label: "Invalid number" },
      { value: "R6", label: "International number" },
      { value: "R7", label: "Powered off" },
      { value: "R8", label: "Not reachable" },
      { value: "R9", label: "Extension number provided" },
      { value: "R10", label: "Other" },
    ],
  },
  {
    label: "Patient's Comments",
    placeholder: "Select Comments",
    questionId: "S",
    type: "select",
    options: [
      {
        value: "S1",
        label: "Doctor took too long to respond",
      },
      { value: "S2", label: "Testing the application" },
      {
        value: "S3",
        label: "Application crashed in between",
      },
      {
        value: "S4",
        label: "Got busy somewhere will consult later",
      },
      {
        value: "S5",
        label:
          "Family member must be consulting, I don't know about their consultation",
      },
      {
        value: "S6",
        label: "Got connected to  a wrong doctor",
      },
      {
        value: "S7",
        label: "Doctor was busy with another patient",
      },
      {
        value: "S8",
        label: "Internet connectivity issues on patient's side",
      },
      { value: "S9", label: "Other comment by user" },
    ],
  },
  {
    label: "Action By Agent",
    placeholder: "Select Action",
    type: "select",
    questionId: "T",
    options: [
      {
        value: "T1",
        label: "Reconnected patient with the doctor",
      },
      {
        label: "Connected patient with a relevant doctor",
        value: "T2",
      },
      {
        label: "Guided patient towards helpline number",
        value: "T3",
      },
      {
        label: "Guided to book an appointment",
        value: "T4",
      },
      {
        value: "T5",
        label: "Agent booked an appointment for patient",
      },
      {
        value: "T6",
        label: "Connected via helpline",
      },
      { value: "T7", label: "Other" },
    ],
  },
  {
    label: "Remarks",
    placeholder: "Select Remarks",
    type: "input",
    questionId: "U",
  },
];
export const cancelledConsultationModal = [
  {
    rowType: "uncollapse",
    fields: [
      {
        label: "Call Status",
        placeholder: "Select",
        questionId: "AA",
        type: "select",
        options: [
          { value: "AA1", label: "Received" },
          { value: "AA2", label: "Not Answering" },
          {
            value: "AA3",
            label: "Powered off",
          },
          { value: "AA4", label: "Invalid number" },
          { value: "AA5", label: "User cut the call" },
          { value: "AA6", label: "Not reachable" },
          { value: "AA7", label: "Landline number provided" },
          { value: "AA8", label: "International number provided" },
          {
            value: "AA9",
            label: "Busy on another call",
          },
        ],
      },
      {
        label: "Patient's Comments",
        placeholder: "Select",
        questionId: "AB",
        type: "select",
        options: [
          {
            value: "AB1",
            label: "Doctor is not responding on the app",
          },
          {
            value: "AB2",
            label: "Doctor is not available for booked appointment",
          },
          {
            value: "AB3",
            label: "Doctor had internet connectivity issues",
          },
          {
            value: "AB4",
            label: "Dr. was busy with another booked appointment",
          },
          {
            value: "AB5",
            label: "I was testing the app",
          },
          {
            value: "AB6",
            label: "User hung up",
          },
          {
            value: "AB7",
            label: "User has asked not to call again",
          },
          {
            value: "AB8",
            label: "Wrong number provided",
          },
          {
            value: "AB9",
            label:
              "Patient have already booked an appointment for relevant doctor",
          },
          {
            value: "AB10",
            label: `Internet and connectivity issue of patient's side`,
          },
          {
            value: "AB11",
            label: "Other comment by user",
          },
          {
            value: "AB12",
            label: "Call was received but no one spoke",
          },
          {
            value: "AB13",
            label: "SK employee",
          },
          {
            value: "AB14",
            label: `Family member have consulted and I don't know about their feedback`,
          },
          {
            value: "AB15",
            label: `Family member has consulted and their feedback is good`,
          },
          {
            value: "AB16",
            label: `Can't talk busy right now`,
          },
          {
            value: "AB17",
            label: `Got connected to a wrong doctor`,
          },
          {
            value: "AB18",
            label: `IPAS consultation`,
          },
          {
            value: "AB19",
            label: `Language Barrier`,
          },
          {
            value: "AB20",
            label: `Consultation was immediately cancelled by user`,
          },
          {
            value: "AB21",
            label: `Got busy somewhere will consult later`,
          },
        ],
      },
      {
        label: "Action By Agent",
        placeholder: "Select",
        type: "select",
        questionId: "AC",
        options: [
          {
            value: "AC1",
            label: "Reconnected pt. with the same doctor",
          },
          {
            value: "AC2",
            label: "Connected pt. with another doctor",
          },
          {
            value: "AC3",
            label: "Guided pt. to book another appointment",
          },
          {
            value: "AC4",
            label: "Guided pt. to the helpline",
          },
          {
            value: "AC5",
            label:
              "Asked user to wait a little for doctor to accept their request",
          },
          {
            value: "AC6",
            label: "Reconnected on their own",
          },
          {
            value: "AC7",
            label: "N/A",
          },
          {
            value: "AC8",
            label: "Reconnected on their own",
          },
          {
            value: "AC9",
            label:
              "Asked user to wait a little for doctor to accept their request",
          },
        ],
      },
      {
        label: "Remarks",
        placeholder: "Type",
        type: "input",
        questionId: "AD",
      },
    ],
  },
];

export const CompletedConsultation: IFedbackField[] = [
  {
    label: "Consultation made through?",
    placeholder: "Select Channel",
    questionId: "I",
    type: "select",
    options: [
      { label: "Audio call", value: "I1" },
      { label: "Video call", value: "I2" },
      { label: "Live chat", value: "I3" },
      { label: "All", value: "I4" },
    ],
  },
  {
    label: "Do you use this app for yourself or your family as well?",
    placeholder: "Select Channel",
    questionId: "J",
    type: "select",
    options: [
      { value: "J1", label: "My self" },
      { value: "J2", label: "Family" },
      { value: "J3", label: "All" },
      { value: "J4", label: "Other" },
    ],
  },
  {
    label: "Overall App Rating",
    placeholder: "Select Ratings",
    questionId: "K",
    type: "select",
    options: [
      { value: "K1", label: "Excellent" },
      { value: "K2", label: "Good" },
      { value: "K3", label: "Fair" },
      { value: "K4", label: "Neutral" },
      { value: "K5", label: "Unsatisfactory" },
    ],
  },
];
export const DroppedConsutationFormFields = [
  {
    rowType: "uncollapse",
    fields: [
      {
        label: "Call Status",
        placeholder: "Select",
        questionId: "BA",
        type: "select",
        options: [
          { value: "BA1", label: "Received" },
          { value: "BA2", label: "Not Answering" },
          {
            value: "BA3",
            label: "Powered off",
          },
          { value: "BA4", label: "Invalid number" },
          { value: "BA5", label: "User cut the call" },
          { value: "BA6", label: "Not reachable" },
          { value: "BA7", label: "Landline number provided" },
          { value: "BA8", label: "International number provided" },
          {
            value: "BA9",
            label: "Busy on another call",
          },
        ],
      },
      {
        label: "Patient's Comments",
        placeholder: "Select",
        questionId: "BB",
        type: "select",
        options: [
          {
            value: "BB1",
            label: "Consultation completed and good experience",
          },
          { value: "BB2", label: "Incomplete consultation" },
          {
            value: "BB3",
            label: "Internet and connectivity issue on application",
          },
          {
            value: "BB4",
            label: "Dr. not responded on active consultation",
          },
          {
            value: "BB5",
            label: "Not satisfied with the consultation",
          },
          {
            value: "BB6",
            label: "Wrong number provided",
          },
          {
            value: "BB7",
            label: "User has asked not to call again",
          },
          {
            value: "BB8",
            label: "Internet and connectivity issue on doctor's side",
          },
          {
            value: "BB9",
            label: "Internet and connectivity issue on patient's side",
          },
          {
            value: "BB9",
            label: "I was testing the app",
          },
          {
            value: "BB10",
            label: "Voice call issues on application",
          },
          {
            value: "BB11",
            label: "Call was received but no one spoke",
          },
          {
            value: "BB12",
            label: "Incompplete consultation would like to connect again",
          },
          {
            value: "BB13",
            label: "SK testing",
          },
          {
            value: "BB14",
            label: "SK employee",
          },
          {
            value: "BB15",
            label:
              "Family member have consulted and I don't know about their feedback",
          },
          {
            value: "BB16",
            label: "Family member has consulted and their feedback is good",
          },
          {
            value: "BB17",
            label: "IPAS consultation",
          },
          {
            value: "BB18",
            label: "User hung up",
          },
          {
            value: "BB19",
            label: "Language Barrier",
          },
          {
            value: "BB20",
            label: "Other comment by user",
          },
        ],
      },
      {
        label: "Action By Agent",
        placeholder: "Select",
        type: "select",
        questionId: "BC",
        options: [
          {
            value: "BC1",
            label: "Reconnected pt. with the same doctor",
          },
          {
            label: "Connected pt. with another doctor",
            value: "BC2",
          },
          {
            label: "Guided pt. to book another appointment",
            value: "BC3",
          },
          {
            label: "Guided pt. to the helpline",
            value: "BC4",
          },
          {
            value: "BC5",
            label: "N/A",
          },
          {
            value: "BC6",
            label: "Concern forwarded to the relevant department",
          },
          {
            value: "BC7",
            label: "Reconnected on their own",
          },
        ],
      },
      {
        label: "Remarks",
        placeholder: "Type",
        type: "input",
        questionId: "BD",
      },
    ],
  },
];
export const ApplicationsFeedbackFromPatient: IFedbackField[] = [
  {
    label: "Overall rating for app",
    placeholder: "Select",
    questionId: "EC",
    type: "select",
    options: [
      { value: "EC1", label: "Excellent" },
      { value: "EC2", label: "Good" },
      { value: "EC3", label: "Fair" },
      { value: "EC4", label: "Neutral" },
      { value: "EC5", label: "Unsatisfactory" },
      { value: "EC6", label: "N/A" },
    ],
  },
  {
    label: "User Friendliness",
    placeholder: "Select",
    questionId: "ED",
    type: "select",
    options: [
      { value: "ED1", label: "Satisfied" },
      { value: "ED2", label: "Unsatisfied" },
      { value: "ED3", label: "N/A" },
    ],
  },
  {
    label: "Audio, Video quality on app ",
    placeholder: "Select",
    questionId: "EE",
    type: "select",
    options: [
      { value: "EE1", label: "Satisfied" },
      { value: "EE2", label: "Unsatisfied" },
      { value: "EE3", label: "N/A" },
    ],
  },
  {
    label: "Internet connectivity on application",
    placeholder: "Select",
    questionId: "EF",
    type: "select",
    options: [
      { value: "EF1", label: "Satisfied" },
      { value: "EF2", label: "Unsatisfied" },
      { value: "EF3", label: "N/A" },
    ],
  },
  {
    label: "Consultation duration",
    placeholder: "Select",
    questionId: "EG",
    type: "select",
    options: [
      { value: "EG1", label: "Satisfied" },
      { value: "EG2", label: "Unsatisfied" },
      { value: "EG3", label: "N/A" },
    ],
  },
  {
    label: "Appointment scheduling",
    placeholder: "Select",
    questionId: "EH",
    type: "select",
    options: [
      { value: "EH1", label: "Satisfied" },
      { value: "EH2", label: "Unsatisfied" },
      { value: "EH3", label: "N/A" },
    ],
  },
  {
    label: "Consultation was made through?",
    placeholder: "Select",
    questionId: "EG",
    type: "select",
    options: [
      { value: "EG1", label: "Audio call" },
      { value: "EG2", label: "Video call" },
      { value: "EG3", label: "Live chat" },
      { value: "EG4", label: "All" },
    ],
  },
];
export const DoctorsFeedbackFromPatient: IFedbackField[] = [
  {
    label: "Overall rating for Doctor",
    placeholder: "Select",
    questionId: "EI",
    type: "select",
    options: [
      { value: "EI1", label: "Excellent" },
      { value: "EI2", label: "Good" },
      { value: "EI3", label: "Fair" },
      { value: "EI4", label: "Neutral" },
      { value: "EI5", label: "Unsatisfactory" },
      { value: "EI6", label: "N/A" },
    ],
  },
  {
    label: "History taking & counselling",
    placeholder: "Select",
    questionId: "EJ",
    type: "select",
    options: [
      { value: "EJ1", label: "Satisfied" },
      { value: "EJ2", label: "Unsatisfied" },
      { value: "EJ3", label: "N/A" },
    ],
  },
  {
    label: "Prescription provided by doctor",
    placeholder: "Select",
    questionId: "EK",
    type: "select",
    options: [
      { value: "EK1", label: "Satisfied" },
      { value: "EK2", label: "Unsatisfied" },
      { value: "EK3", label: "N/A" },
    ],
  },
  {
    label: "Doctors behaviour",
    placeholder: "Select",
    questionId: "EL",
    type: "select",
    options: [
      { value: "EL1", label: "Satisfied" },
      { value: "EL2", label: "Unsatisfied" },
      { value: "EL3", label: "N/A" },
    ],
  },
  {
    label: "Remarks for Doctor",
    placeholder: "Select",
    questionId: "EM",
    type: "select",
    options: [
      { value: "EM1", label: "Dr. behaviour was not professional" },
      { value: "EM2", label: "Dr. was not attentive during consultaiton" },
      { value: "EM3", label: "Dr. was using false language" },
      { value: "EM4", label: "Dr. was harrassing" },
      { value: "EM5", label: "Dr. provided a false prescription" },
      { value: "EM6", label: "Other comments by user" },
      { value: "EM7", label: "N/A" },
    ],
  },
  {
    label: "Remarks by agent",
    placeholder: "Type",
    questionId: "EN",
    type: "input",
  },
];

export const MissedFormField = [
  {
    rowType: "collapse",
    active: false,
    name: "Doctor Feedback",
    fields: DoctorMissedConsultationFormField,
  },
  {
    rowType: "collapse",
    active: false,
    name: "Patient Feedback",
    fields: MissedConsultationFormField,
  },
];

export const RejectedFormField = [
  {
    rowType: "collapse",
    active: false,
    name: "Doctor Feedback",
    fields: rejectedDoctorMissedConsultationFormField,
  },
  {
    rowType: "collapse",
    active: false,
    name: "Patient Feedback",
    fields: rejectedMissedConsultationFormField,
  },
];

export const SuccessfullConsultationFormField = [
  {
    rowType: "uncollapse",
    fields: [
      {
        label: "Patient's Call Status",
        placeholder: "Select",
        questionId: "EA",
        type: "select",
        options: [
          { value: "EA1", label: "Received" },
          { value: "EA2", label: "Not Answering" },
          {
            value: "EA3",
            label: "Powered off",
          },
          { value: "EA4", label: "Invalid number" },
          { value: "EA5", label: "User cut the call" },
          { value: "EA6", label: "Not reachable" },
          { value: "EA7", label: "Landline number provided" },
          { value: "EA8", label: "International number provided" },
          {
            value: "EA9",
            label: "Busy on another call",
          },
        ],
      },
      {
        label: "Patient's Comments",
        placeholder: "Select",
        questionId: "EB",
        type: "select",
        options: [
          {
            value: "EB1",
            label: "Feedback given",
          },
          { value: "EB2", label: "Busy right now can't talk" },
          {
            value: "EB3",
            label:
              "Family member has consulted and I don't know about their feedback",
          },
          {
            value: "EB4",
            label: "User has asked not to call again",
          },
          {
            value: "EB5",
            label: "I was testing the app",
          },
          {
            value: "EB6",
            label: "Incomplete consultation",
          },
          {
            value: "EB7",
            label: "Wrong number provided",
          },
          {
            value: "EB8",
            label: "SK testing",
          },
          {
            value: "EB9",
            label: "SK employee",
          },
          {
            value: "EB10",
            label: "User hung up the call",
          },
          {
            value: "EB11",
            label: "Other comment by user",
          },
          {
            value: "EA12",
            label: "Good experience with application and doctor",
          },
          {
            value: "EA13",
            label: "Good experience and requested not to call again",
          },
          {
            value: "EA14",
            label: "Family member has consulted and their feedback is good",
          },
          {
            value: "EA15",
            label:
              "Internet and voice call issues on application but good experience with doctor",
          },
          {
            value: "EA16",
            label: "Incomplete consultation and doctor didn't responded at all",
          },
          {
            value: "EA17",
            label: "Not satisfied with the consultant",
          },
          {
            value: "EA18",
            label: "Language Barrier",
          },
          {
            value: "EA19",
            label: "Voice call issues on application",
          },
          {
            value: "EA20",
            label: "Call was received but no one spoke",
          },
          {
            value: "EA21",
            label: "Consultation was completed and good experience",
          },
          {
            value: "EA22",
            label: "Got connected to a wrong doctor",
          },
          {
            value: "EA23",
            label: "Patient was referred to another doctor",
          },
          {
            value: "EA24",
            label: "Overall good experience but application needs to be fixed",
          },
          {
            value: "EA25",
            label:
              "Incomplete consultation and priscription provided without complete consultation",
          },
          {
            value: "EA26",
            label: "IPAS consultation",
          },
          {
            value: "EA27",
            label: "Incomplete consultation and doctor didn't responded at all",
          },
        ],
      },
    ],
  },
  {
    rowType: "collapse",
    active: false,
    name: "Application's Feedback From Patient",
    fields: ApplicationsFeedbackFromPatient,
  },
  {
    rowType: "collapse",
    active: false,
    name: "Doctor's Feedback From Patient",
    fields: DoctorsFeedbackFromPatient,
  },
];
export const collapseDroppedFields = [
  {
    active: false,
    name: "If the consultation is completed",
    fields: CompletedConsultation,
  },
];

export const IncommingCallsFormField = [
  {
    rowType: "uncollapse",
    fields: [
      {
        label: "Name",
        placeholder: "Type",
        questionId: "callerName",
        type: "input",
      },
      {
        label: "Phone Number",
        placeholder: "Example 923xxxxxxxxx",
        questionId: "callerPhoneNumber",
        type: "input",
      },
      {
        label: "Registered Via",
        placeholder: "Select",
        questionId: "registeredVia",
        type: "select",
        options: [
          { value: "Audio Call", label: "Audio Call" },
          { value: "Whatspp Call", label: "Whatsapp Call" },
          {
            value: "",
            label: "Whatsapp label",
          },
          { value: "Email", label: "Email" },
          { value: "Landline Call", label: "Landline Call" },
          { value: "International number", label: "International number" },
        ],
      },
      {
        label: "Category",
        placeholder: "Select",
        questionId: "category",
        type: "select",
        options: [
          { value: "Corp", label: "Corp" },
          { value: "Retail", label: "Retail" },
          {
            value: "Mental Health",
            label: "Mental Health",
          },
          { value: "Doctor", label: "Doctor" },
          { value: "Other", label: "Other" },
        ],
      },
      {
        label: "Company Name",
        placeholder: "Type",
        questionId: "companyName",
        type: "input",
      },
      {
        label: "Agent Name",
        placeholder: "Type",
        questionId: "agentId",
        type: "input",
      },
      {
        label: "Remarks",
        placeholder: "Type Number",
        questionId: "remarks",
        type: "select",
        options: [
          { value: "Wants to consult", label: "Wants to consult" },
          { value: "Application guidance", label: "Application guidance" },
          {
            value: "Account activatation",
            label: "Account activatation",
          },
          { value: "Password reset request", label: "Password reset request" },
          { value: "Email update request", label: "Email update request" },
          {
            value: "Payment issue reporting",
            label: "Payment issue reporting",
          },
          {
            value: "Doctor's complaint for Patient",
            label: "Doctor's complaint for Patient",
          },
          {
            value: "Incomplete consultataion reporting",
            label: "Incomplete consultataion reporting",
          },
          {
            value: "Patient's complaint regarding doctor",
            label: "Patient's complaint regarding doctor",
          },
          {
            value: "Mental health query for helpline",
            label: "Mental health query for helpline",
          },
          {
            value: "Mental health query for application",
            label: "Mental health query for application",
          },
          { value: "Support email query", label: "Support email query" },
          {
            value: "IOS pending consultation issue",
            label: "IOS pending consultation issue",
          },
          {
            value: "Pending consultation issue",
            label: "Pending consultation issue",
          },
          {
            value: "Call forwarded to relevant department",
            label: "Call forwarded to relevant department",
          },
          { value: "Irrelevant call", label: "Irrelevant call" },
          { value: "OTP issue reporting", label: "OTP issue reporting" },
          { value: "Other", label: "Other" },
          {
            value: "Information regarding E-Pharmacy",
            label: "Information regarding E-Pharmacy",
          },
          {
            value: "Duplicate account issue",
            label: "Duplicate account issue",
          },
          {
            value: "Informations already exist",
            label: "Informations already exist",
          },
        ],
      },
      {
        label: "Case catering department",
        placeholder: "Select",
        questionId: "caseCateringDepartment",
        type: "select",
        options: [
          { value: "Customer support", label: "Customer support" },
          { value: "Technical department", label: "Technical department" },
          {
            value: "Corporate department",
            label: "Corporate department",
          },
          { value: "MLE", label: "MLE" },
          { value: "Doctors network", label: "Doctors network" },
        ],
      },
      {
        label: "Case close time",
        placeholder: "Select",
        questionId: "caseCloseTime",
        type: "select",
        options: [
          { value: "Same time", label: "Same time" },
          { value: "24 hours", label: "24 hours" },
          {
            value: "48 hours",
            label: "48 hours",
          },
          { value: "72 hours", label: "72 hours" },
          { value: "5 days", label: "5 days" },
          { value: "Pending", label: "Pending" },
        ],
      },
      {
        label: "Remarks/Detail of caller ",
        placeholder: "Type",
        questionId: "callerRemarksORdetail",
        type: "input",
      },
    ],
  },
];

export const followUpIncommingCallsFormField = [
  {
    rowType: "uncollapse",
    fields: [
      {
        label: "Agent Name",
        placeholder: "Type",
        questionId: "agentId",
        type: "input",
      },
      {
        label: "Date and Time",
        placeholder: "Type",
        questionId: "date",
        type: "input",
      },
      {
        label: "Call Status",
        placeholder: "Select",
        questionId: "callStatus",
        type: "select",
        options: [
          { value: "Received", label: "Received" },
          { value: "Not Answering", label: "Not Answering" },
          { value: "Not responding", label: "Not responding" },
          { value: "Powered off", label: "Powered off" },
          { value: "Invalid number", label: "Invalid number" },
          {
            value: "Busy on another call",
            label: "Busy on another call",
          },
          { value: "Other", label: "Other" },
          { value: "User cut the call", label: "User cut the call" },
        ],
      },
      {
        label: "Case close time",
        placeholder: "Select",
        questionId: "caseCloseTime",
        type: "select",
        options: [
          { value: "24 hours", label: "24 hours" },
          {
            value: "48 hours",
            label: "48 hours",
          },
          { value: "72 hours", label: "72 hours" },
          { value: "5 days", label: "5 days" },
        ],
      },
      {
        label: "Remarks",
        placeholder: "Type",
        questionId: "remarks",
        type: "input",
      },
    ],
  },
];
