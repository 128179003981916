export enum permissions {
  DASHBOARD = "DASHBOARD",
  CONSULTATIONS = "CONSULTATIONS",
  CLOSED_CALLS = "CLOSED_CALLS",
  ACTIVE_CALLS = "ACTIVE_CALLS",
  USER_MANAGEMENT = "USER_MANAGEMENT",
  VIEW_ALL_USERS = "VIEW_ALL_USERS",
  USER_RIGHTS = "USER_RIGHTS",
  USER_TYPES = "USER_TYPES",
  USER_LOCATIONS = "USER_LOCATIONS",
  PATIENTS = "PATIENTS",
  SETTINGS = "SETTINGS",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  EDIT_PROFILE = "EDIT_PROFILE",
  HELPLINE_PENDING_CALLS = "HELPLINE_PENDING_CALLS",
  HELPLINE_CLOSE_CALLS = "HELPLINE_CLOSE_CALLS",
  HELPLINE_CALLS = "HELPLINE_CALLS",
}
