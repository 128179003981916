import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AgentAction } from "src/app/shared/Arrays/DropDowns";
import {
  cancelledConsultationModal,
  DroppedConsutationFormFields,
  followUpIncommingCallsFormField,
  IncommingCallsFormField,
  MissedFormField,
  RejectedFormField,
  SuccessfullConsultationFormField,
} from "src/app/shared/Arrays/FormFields";
import {
  adminAppValidations,
  helplinePhoneValidate,
  IDropDown,
} from "src/app/shared/interface";
import Swal from "sweetalert2";
import { ConsultationService } from "../../core/services/consultation.service";
import { FollowUpService } from "../../core/services/followUp";
import { IncommingCallsService } from "../../core/services/incommingCalls.service";
import { Telephony } from "../../core/services/telephony.service";
import { formateDateandTime } from "../../../shared/helper";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-form-modal",
  templateUrl: "./form-modal.component.html",
  styleUrls: ["./form-modal.component.scss"],
})
export class FormModalComponent implements OnInit {
  xmark = faXmark;
  validate: any;
  updateValue: string;
  validations = adminAppValidations;
  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private httpConsultationService: ConsultationService,
    private httpTelephony: Telephony,
    private httpIncommingCallsService: IncommingCallsService,
    private httpFollowUpService: FollowUpService
  ) {}
  @Input() valueData: string;
  @Input() rowId: number;
  @Input() attepmts: string;
  @Input() route: string;
  @Input() consultationsId: number;
  @Input() doctorPhone: string;
  @Input() patientPhone: string;
  @Input() consultationDate: string;
  @Input() btnType: string;

  user: any = JSON.parse(sessionStorage.getItem("user"));

  async ngOnInit() {
    this.checkformFields(this.valueData);
    this.formValues(this.rowId);
    this.form();
  }
  modalValueFormValue: any;
  action: IDropDown[] = AgentAction;
  modalTitle: string;
  FormFields: any;
  checkformFields(value: string) {
    switch (value) {
      case "Cancelled":
        this.modalTitle = "Cancelled Consultation <span> Feedback </span>";
        this.FormFields = cancelledConsultationModal;
        break;
      case "Dropped":
        this.modalTitle = "Dropped Consultation <span> Feedback </span>";
        this.FormFields = DroppedConsutationFormFields;

        break;
      case "Missed":
        this.modalTitle = `Missed Consulation<span> Feedback </span> `;
        this.FormFields = MissedFormField;

        break;
      case "Rejected":
        this.modalTitle = `Rejected Consulation<span> Feedback </span> `;
        this.FormFields = RejectedFormField;

        break;
      case "Consulted":
        this.modalTitle = `Successfull Consulation<span> Feedback </span> `;
        this.FormFields = SuccessfullConsultationFormField;
        break;
      case "Incomming Calls":
        this.modalTitle = `Incomming <span> Calls </span> `;
        this.FormFields = IncommingCallsFormField;
        break;
      case "Follow Up":
        this.modalTitle = `Incomming <span> Calls </span> `;
        this.FormFields = followUpIncommingCallsFormField;
        break;
    }
  }
  async close() {
    // if (this.valueData == "Incomming Calls" || this.valueData == "Follow Up") {
    //   this.activeModal.close(false);
    // } else {
    //   let response;
    // if (this.route == "closed-calls") {
    //   this.activeModal.close(false);
    // } else {
    // response = await this.patientAgentCallStatus();
    // if (response) {
    this.activeModal.close(false);
    // } else {
    // }
    // }
    // }
  }
  formData: FormGroup;
  formGroupFields: any = {};
  async form() {
    for (let a = 0; a < this.FormFields.length; a++) {
      for (let b = 0; b < this.FormFields[a].fields.length; b++) {
        if (this.FormFields[a].fields[b].questionId == "callerPhoneNumber") {
          this.formGroupFields[this.FormFields[a].fields[b].questionId] = [
            ,
            [
              Validators.required,
              Validators.minLength(this.validations.phone.minlength),
              Validators.pattern(helplinePhoneValidate),
            ],
          ];
        } else if (
          this.FormFields[a].fields[b].questionId == "companyName" ||
          this.FormFields[a].fields[b].questionId == "callerRemarksORdetail"
        ) {
          this.formGroupFields[this.FormFields[a].fields[b].questionId] = [];
        } else if (this.FormFields[a].fields[b].questionId == "agentId") {
          this.formGroupFields[this.FormFields[a].fields[b].questionId] = [
            `${this.user.firstName} ${this.user.lastName}`,
            Validators.required,
          ];
        } else if (this.FormFields[a].fields[b].questionId == "date") {
          this.formGroupFields[this.FormFields[a].fields[b].questionId] = [
            `${formateDateandTime("date", new Date())} ${formateDateandTime(
              "time",
              new Date()
            )}`,
            Validators.required,
          ];
        } else {
          this.formGroupFields[this.FormFields[a].fields[b].questionId] = [
            ,
            Validators.required,
          ];
        }
      }
    }

    this.formData = this.formBuilder.group(this.formGroupFields);

    switch (this.route) {
      case "consultations":
        Object.keys(this.formGroupFields).map((d) => {
          if (
            d == "EA" ||
            d == "DE" ||
            d == "DA" ||
            d == "AA" ||
            d == "BA" ||
            d == "CA" ||
            d == "CE"
          ) {
            this.formData.get(d).enable();
          } else {
            if (this.attepmts != "0") {
              this.formData.get(d).enable();
            } else {
              this.formData.get(d).disable();
            }
          }
        });
        break;
      case "incomming-calls":
        Object.keys(this.formGroupFields).map((d) => {
          switch (d) {
            case "agentId":
              this.formData.get(d).disable();
              break;
            case "date":
              this.formData.get(d).disable();
              break;

            default:
              this.formData.get(d).enable();
              break;
          }
        });

        break;
      case "closed-calls":
        Object.keys(this.formGroupFields).map((d) => {
          this.formData.get(d).disable();
        });
        break;
      case "incomming-calls-closed":
        switch (this.btnType) {
          case "edit":
            Object.keys(this.formGroupFields).map((d) => {
              switch (d) {
                case "agentId":
                  this.formData.get(d).disable();
                  break;
                case "date":
                  this.formData.get(d).disable();
                  break;

                default:
                  this.formData.get(d).enable();
                  break;
              }
            });

            break;
          case "view":
            Object.keys(this.formGroupFields).map((d) => {
              this.formData.get(d).disable();
            });

            break;
        }
        break;
    }
  }
  async formValues(id) {
    await this.form();
    switch (this.valueData) {
      case "Incomming Calls":
        if (this.btnType === "edit") {
          this.modalValueFormValue =
            await this.httpIncommingCallsService.getIncommingCallbyId(
              this.consultationsId
            );
          let result = this.modalValueFormValue.data;
          Object.keys(this.formData.value).forEach((d: any) => {
            Object.keys(this.modalValueFormValue.data).forEach((key: any) => {
              if (key === d) {
                this.formData.controls[d].setValue(
                  key === "callStatus" ? `${result[key]}` : result[key]
                );
              }
            });
          });
        }

        break;
      case "Follow Up":
        if (this.route === "incomming-calls-closed") {
          this.modalValueFormValue =
            await this.httpFollowUpService.getFollowUpbyId(
              this.consultationsId
            );
          let result = this.modalValueFormValue.data;
          Object.keys(this.formData.value).forEach((d: any) => {
            Object.keys(this.modalValueFormValue.data).forEach((key: any) => {
              if (key === d) {
                switch (key) {
                  case "date":
                    this.formData.controls[d].setValue(
                      `${formateDateandTime(
                        "date",
                        result[key]
                      )} ${formateDateandTime("time", result[key])}`
                    );
                    break;
                  case "callStatus":
                    this.formData.controls[d].setValue(`${result[key]}`);
                    break;
                  case "agentId":
                    this.formData.controls[d].setValue(
                      `${result.agent.firstName} ${result.agent.lastName}`
                    );
                    break;

                  default:
                    this.formData.controls[d].setValue(result[key]);
                    break;
                }
              }
            });
          });
        }

        break;
      default:
        if (this.attepmts != "0") {
          this.modalValueFormValue =
            await this.httpConsultationService.getconsultationFieldsValue(id);
          let option = this.modalValueFormValue.data.answers;

          for (let a = 0; a < option.length; a++) {
            this.formData.controls[option[a]["questionId"]].setValue(
              option[a].openAnswer
            );
          }
        }
        break;
    }
  }
  async onSubmit() {
    let body = [];
    let payload: any = {};
    let confirm: any = {};
    switch (this.valueData) {
      case "Follow Up":
        switch (this.btnType) {
          case "add":
            payload = {
              ...payload,
              date: new Date(),
              agentId: this.user.id || "",
              callId: this.consultationsId,
            };
            Object.keys(this.formData.value).forEach((key) => {
              let optionAns = this.formData.value[key]?.label
                ? this.formData.value[key].label
                : this.formData.value[key];
              payload = {
                ...payload,
                [key]: optionAns,
              };
            });

            confirm = await this.httpFollowUpService.addFollowUp(payload);

            break;
          case "edit":
            payload = {
              callId: this.consultationsId,
            };

            Object.keys(this.formData.value).forEach((key) => {
              let optionAns = this.formData.value[key]?.label
                ? this.formData.value[key].label
                : this.formData.value[key];
              payload = {
                ...payload,
                [key]: optionAns,
              };
            });
            await Swal.fire({
              title: "Are you sure to edit Follow Up?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d00140",
              cancelButtonColor: "#7cc707",
              confirmButtonText: "Yes, Close",
            }).then(async (result) => {
              if (result.isConfirmed) {
                confirm = await this.httpFollowUpService.editFollowUp(payload);
                this.activeModal.close("follow-up-edited");
              } else {
                this.activeModal.close(false);
              }
            });

            break;
        }
        break;
      case "Incomming Calls":
        switch (this.btnType) {
          case "add":
            payload = {
              ...payload,
              date: new Date(),
              agentId: this.user.id,
            };

            Object.keys(this.formData.value).forEach((key) => {
              let optionAns = this.formData.value[key]?.label
                ? this.formData.value[key].label
                : this.formData.value[key];
              payload = {
                ...payload,
                [key]: optionAns,
              };
            });

            if (payload.caseCloseTime === "Pending") {
              payload.callStatus = "pending";
            } else {
              payload.callStatus = "closed";
            }
            confirm = await this.httpIncommingCallsService.addIncommingCalls(
              payload
            );
            break;
          case "edit":
            let updateIncomming: any = {};

            Object.keys(this.formData.value).forEach((key) => {
              let optionAns = this.formData.value[key]?.label
                ? this.formData.value[key].label
                : this.formData.value[key];
              updateIncomming = {
                ...updateIncomming,
                [key]: optionAns,
              };
            });

            await Swal.fire({
              title: "Are you sure to edit Helpline Call?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d00140",
              cancelButtonColor: "#7cc707",
              confirmButtonText: "Yes",
            }).then(async (result) => {
              if (result.isConfirmed) {
                confirm =
                  await this.httpIncommingCallsService.updateIncommingCallbyId(
                    this.consultationsId,
                    updateIncomming
                  );
                this.activeModal.close("incomming-call-edited");
              } else {
                this.activeModal.close(false);
              }
            });
            break;
        }
        break;

      default:
        Object.keys(this.formData.value).forEach((key) => {
          let optionAns = this.formData.value[key].label
            ? this.formData.value[key].label
            : this.formData.value[key];
          let optionId = this.formData.value[key].value;
          body.push({
            questionId: key,
            optionId: optionId,
            openAnswer: optionAns,
            consultationDate: this.consultationDate,
          });
        });
        let agentName = `${this.user.firstName} ${this.user.lastName}`;
        this.httpConsultationService.sendConsultationFeedback(
          this.rowId,
          this.valueData,
          agentName,
          body
        );
        break;
    }

    switch (this.valueData) {
      case "Missed":
        if (
          (this.formData.value.CA.label == "Received" &&
            this.formData.value.CE == "Received") ||
          (this.formData.value.CA == "Received" &&
            this.formData.value.CE.label == "Received") ||
          (this.formData.value.CA.label == "Received" &&
            this.formData.value.CE.label == "Received")
        ) {
          // const response: any = await this.patientAgentCallStatus();
          // if (response) {
          this.activeModal.close("close consultation");
          // } else {
          // }
        } else {
          // const response: any = await this.patientAgentCallStatus();
          // if (response) {
          this.activeModal.close(true);
          // } else {
          // }
        }
        break;
      case "Rejected":
        if (
          (this.formData.value.DA.label == "Received" &&
            this.formData.value.DE == "Received") ||
          (this.formData.value.DA == "Received" &&
            this.formData.value.DE.label == "Received") ||
          (this.formData.value.DA.label == "Received" &&
            this.formData.value.DE.label == "Received")
        ) {
          // const response: any = await this.patientAgentCallStatus();
          // if (response) {
          this.activeModal.close("close consultation");
          // } else {
          // }
        } else {
          // const response: any = await this.patientAgentCallStatus();
          // if (response) {
          this.activeModal.close(true);
          // } else {
          // }
        }
        break;
      case "Cancelled":
        if (this.formData.value.AA.label == "Received") {
          //   const response: any = await this.patientAgentCallStatus();
          //   if (response) {
          this.activeModal.close("close consultation");
          // } else {
          // }
        } else {
          // const response: any = await this.patientAgentCallStatus();
          // if (response) {
          this.activeModal.close(true);
          // } else {
          // }
        }
        break;
      case "Consulted":
        if (this.formData.value.EA.label == "Received") {
          // const response: any = await this.patientAgentCallStatus();
          // if (response) {
          this.activeModal.close("close consultation");
          // } else {
          // }
        } else {
          // const response: any = await this.patientAgentCallStatus();
          // if (response) {
          this.activeModal.close(true);
          // } else {
          // }
        }
        break;
      case "Dropped":
        if (this.formData.value.BA.label == "Received") {
          // const response: any = await this.patientAgentCallStatus();
          // if (response) {
          this.activeModal.close("close consultation");
          // } else {
          // }
        } else {
          // const response: any = await this.patientAgentCallStatus();
          // if (response) {
          this.activeModal.close(true);
          // } else {
          // }
        }
        break;
      case "Incomming Calls":
        switch (this.btnType) {
          case "add":
            this.activeModal.close("incomming-call-added");

            break;
          case "edit":
            break;
        }
        break;
      case "Follow Up":
        switch (this.btnType) {
          case "add":
            this.activeModal.close("follow-up-added");

            break;
          case "edit":
            break;
        }
        break;
    }
  }

  calld: string;
  callp: string;
  disabled: any = true;
  getValue() {
    let fields = this.formData.value;

    switch (this.valueData) {
      case "Rejected":
        if (
          this.formData.value.DA?.label &&
          this.formData.value.DA.label == "Received"
        ) {
          this.formData.get("DB").enable();
          this.formData.get("DC").enable();
          this.formData.get("DD").enable();
        }
        if (
          this.formData.value.DA?.label &&
          this.formData.value.DA.label != "Received"
        ) {
          this.formData.get("DB").disable();
          this.formData.get("DC").disable();
          this.formData.get("DD").disable();
          this.formData.get("DB").setValue("");
          this.formData.get("DC").setValue("");
          this.formData.get("DD").setValue("");
        }
        if (
          this.formData.value.DE?.label &&
          this.formData.value.DE.label == "Received"
        ) {
          this.formData.get("DF").enable();
          this.formData.get("DG").enable();
          this.formData.get("DH").enable();
        }
        if (
          this.formData.value.DE?.label &&
          this.formData.value.DE.label != "Received"
        ) {
          this.formData.get("DF").disable();
          this.formData.get("DG").disable();
          this.formData.get("DH").disable();
          this.formData.get("DF").setValue("");
          this.formData.get("DG").setValue("");
          this.formData.get("DH").setValue("");
        }

        if (
          this.formData.value.DA &&
          this.formData.value.DA.label != "Received" &&
          this.formData.value.DE &&
          this.formData.value.DE.label != "Received"
        ) {
          this.disabled = null;
        } else if (!this.formData.invalid) {
          this.disabled = null;
        } else {
          this.disabled = true;
        }

        break;
      case "Missed":
        if (
          this.formData.value.CA?.label &&
          this.formData.value.CA.label == "Received"
        ) {
          this.formData.get("CB").enable();
          this.formData.get("CC").enable();
          this.formData.get("CD").enable();
        }
        if (
          this.formData.value.CA?.label &&
          this.formData.value.CA.label != "Received"
        ) {
          this.formData.get("CB").disable();
          this.formData.get("CC").disable();
          this.formData.get("CD").disable();
          this.formData.get("CB").setValue("");
          this.formData.get("CC").setValue("");
          this.formData.get("CD").setValue("");
        }
        if (
          this.formData.value.CE?.label &&
          this.formData.value.CE.label == "Received"
        ) {
          this.formData.get("CF").enable();
          this.formData.get("CG").enable();
          this.formData.get("CH").enable();
        }
        if (
          this.formData.value.CE?.label &&
          this.formData.value.CE.label != "Received"
        ) {
          this.formData.get("CF").disable();
          this.formData.get("CG").disable();
          this.formData.get("CH").disable();
          this.formData.get("CF").setValue("");
          this.formData.get("CG").setValue("");
          this.formData.get("CH").setValue("");
        }

        if (
          this.formData.value.CA &&
          this.formData.value.CA.label != "Received" &&
          this.formData.value.CE &&
          this.formData.value.CE.label != "Received"
        ) {
          this.disabled = null;
        } else if (!this.formData.invalid) {
          this.disabled = null;
        } else {
          this.disabled = true;
        }

        break;
      case "Consulted":
        if (this.formData.value.EA.label == "Received") {
          Object.keys(this.formGroupFields).map((d) => {
            this.formData.get(d).enable();
          });
        }
        if (this.formData.value.EA.label != "Received") {
          Object.keys(this.formGroupFields).map((d) => {
            if (d.toString() != "EA") {
              this.formData.get(d).disable();
              this.formData.get(d).setValue("");
            }
          });
        }
        this.disabled =
          this.formData.value.EA.label != "Received" ||
          (this.formData.value.EA.label == "Received" && !this.formData.invalid)
            ? null
            : true;

        break;
      case "Dropped":
        if (this.formData.value.BA.label == "Received") {
          Object.keys(this.formGroupFields).map((d) => {
            this.formData.get(d).enable();
          });
        }
        if (this.formData.value.BA.label != "Received") {
          Object.keys(this.formGroupFields).map((d) => {
            if (d != "BA") {
              this.formData.get(d).disable();
              this.formData.get(d).setValue("");
            }
          });
        }
        this.disabled =
          (this.formData.value.BA.label == "Received" &&
            !this.formData.invalid) ||
          this.formData.value.BA.label != "Received"
            ? null
            : true;
        break;
      case "Cancelled":
        if (fields.AA.label == "Received") {
          Object.keys(this.formGroupFields).map((d) => {
            this.formData.get(d).enable();
          });
        } else {
          Object.keys(this.formGroupFields).map((d) => {
            if (d.toString() != "AA") {
              this.formData.get(d).disable();
              this.formData.get(d).setValue("");
            }
          });
        }
        this.disabled =
          this.formData.value.AA.label != "Received" ||
          (this.formData.value.AA.label == "Received" && !this.formData.invalid)
            ? null
            : true;

        break;
      case "Incomming Calls":
        if (this.formData.valid) {
          this.disabled = null;
        }
        break;
      case "Follow Up":
        if (this.formData.valid) {
          this.disabled = null;
        }
        break;
    }
  }

  calltodoctor() {
    this.telephony(this.doctorPhone);
  }
  calltoPatient() {
    this.telephony(this.patientPhone);
  }
  async telephony(phone: string) {
    try {
      if (this.route == "closed-calls") {
        return;
      }
      let number = this.validatePhoneNumber(phone);
      const response = await this.httpTelephony.telephony(
        number,
        this.user.agentExtension
      );
    } catch (err) {}
  }
  validatePhoneNumber(phone: string) {
    let extension = phone.toString().replace(/\s/g, "").slice(-10);
    return "0" + extension;
  }

  // async patientAgentCallStatus(close?: any) {
  //   if (close) {
  //     return;
  //   }
  //   const response: any = await this.httpConsultationService.putcallStatusCheck(
  //     this.consultationsId,
  //     {
  //       status: "Inactive",
  //     }
  //   );
  //   return response.meta.message;
  // }

  call(name: string) {
    if (name == "Patient Feedback") {
      this.calltoPatient();
    } else {
      this.calltodoctor();
    }
  }
}
