<div class="container-fluid">
  <div class="row justify-content-end">
    <div class="col-12 col-sm-6 col-md-3">
      <button class="btn btn-danger float-right" (click)="open()">
        <i class="fas fa-plus-circle"></i> Add New
      </button>
    </div>
  </div>
  <div id="table_div" class="container-fluid scroll">
    <table class="table table-hover table-borderless mt-2">
      <thead>
        <tr style="background-color: #7cc707 !important">
          <td
            *ngFor="let item of userTableHeader"
            style="color: #fff; font-weight: 600; cursor: pointer"
          >
            {{ item.title }}
          </td>
        </tr>
      </thead>
      <tbody class="mt-4">
        <tr
          *ngFor="let item of res; index as i"
          class="mt-4 shadow-sm bg-body rounded"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ item.name }}</td>

          <td id="action">
            <fa-icon
              [icon]="editRole"
              (click)="open(item.id, item.name)"
              class="table-icon green"
            ></fa-icon>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center field-loader" *ngIf="res === undefined">
      <img src="assets/images/SK-Logo-Animation.gif" alt="" />
    </div>
  </div>
</div>
<!-- </div> -->
