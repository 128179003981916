import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Router } from "@angular/router";
import { CallStatus } from "src/app/shared/constant";
import { DashboardService } from "../core/services/dashboard.service";
import { ApiResponse } from "../core/interfaces/API-Config";
import { dateTofirstDay } from "../../shared/helper";
Chart.register(...registerables);
Chart.register({ ChartDataLabels });
Chart.defaults.set("plugins.datalabels", {
  color: "#FFF",
  fontSize: "22",
});
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  startDate: any = null;
  endDate: any = null;
  response: ApiResponse;
  form: any;
  company: any = [];
  category: any = [
    { value: "All", title: "All" },
    { value: "Retail", title: "Retail" },
    { value: "Corp", title: "Corp" },
  ];
  categoryDD: string;
  feedback: any;
  appRating: any;
  consultationDetail: any;
  consultationTable: any;
  genderBifurcationVal: any;
  tableData: any;
  totalConsultation: any;
  tableHeader = ["Category", "Count", "Successful Calls", "Pending Calls"];
  feedbackCanvas = null;
  genderCanvas = null;
  appRatingCanvas = null;
  display: any;
  consultationTotalCount: number = 0;
  consultationChart: any = {};
  consultationChartData: any;
  feedbackTotalCount = 0;
  feedbackChartData: any = {};
  isCountProcess: boolean;
  consultationCountTable: {
    title: string;
    totalCount: any;
    successfullCalls: any;
    pendingCalls: any;
  }[];
  constructor(
    private formBuider: FormBuilder,
    private router: Router,
    private http: DashboardService
  ) {}
  ngOnInit() {
    this.startDate = dateTofirstDay({
      type: "string",
      date: new Date(),
      day: 1,
    });
    this.endDate = new Date();
    this.initForm();

    this.display = JSON.parse(sessionStorage.getItem("permission")).filter(
      (d: any) => {
        return Object.keys(d)[0] === "DASHBOARD";
      }
    )[0].DASHBOARD.view;
  }
  filters: any = {};

  setQueryFilters() {
    const sd = this.startDate;
    const ed = new Date(this.endDate);
    this.filters.startDate = isNaN(sd.getTime())
      ? new Date().getTime()
      : sd.getTime();
    this.filters.endDate = isNaN(ed.getTime())
      ? new Date().getTime()
      : ed.getTime();
    return this.filters;
  }

  async applyFilter(fromBtn?: boolean) {
    this.isCountProcess = true;
    this.consultationTotalCount = 0;
    this.consultationChart = {};
    this.setQueryFilters();
    let category =
      this.form.value.category != "" && this.form.value.category != "All"
        ? this.form.value.category
        : null;
    let startDate = this.filters.startDate;
    let endDate = this.filters.endDate;
    await this.consultationDetailFun(startDate, endDate, category);
    this.feedbackChartDetail(category);
    this.appRatingfunc(startDate, endDate, category);
    this.isCountProcess = false;
  }

  async consultationDetailFun(startDate, endDate, category) {
    this.consultationChartData = (
      await this.http.consultationDetail(startDate, endDate, category)
    ).data;

    this.consultationDetail = this.consultationChartData.consultationCounts;

    const groupedData = this.consultationDetail.map((obj) => {
      this.consultationTotalCount += obj.consultationCount;
      const key = obj.consultationStatusName;
      // consultationChart[key].consultationCount = obj.consultationCount;
      if (!this.consultationChart[key]) {
        if (obj.callStatus === CallStatus.pendingCall) {
          this.consultationChart[key] = {
            pendingCalls: obj.callCount,
            closedCalls: 0,
            consultationCount: obj.consultationCount || 0,
          };
          return;
        } else {
          this.consultationChart[key] = {
            pendingCalls: 0,
            closedCalls: obj.callCount || 0,
            consultationCount: obj.consultationCount || 0,
          };
          return;
        }
      }
      if (obj.callStatus === CallStatus.pendingCall) {
        this.consultationChart[key].pendingCalls += obj.callCount;
        this.consultationChart[key].consultationCount += obj.consultationCount;

        return;
      } else {
        this.consultationChart[key].closedCalls += obj.callCount;
        this.consultationChart[key].consultationCount += obj.consultationCount;

        return;
      }
    });

    this.genderBifurcation();
  }
  async feedbackChartDetail(category) {
    this.feedback = (await this.http.feedbackCounts(category)).data;
    this.filterfeedbackChart(this.feedback);
    this.feedbackChart();
  }
  async appRatingfunc(startDate, endDate, category) {
    this.appRating = (
      await this.http.applicationReview(startDate, endDate, category)
    ).data;
    this.appRatingChart();
  }
  filterRatings(rate: string) {
    let val = this.appRating.filter((d) => {
      return d.optionName == rate;
    });
    return val != "" ? val[0].countAnswers : 0;
  }

  filterGender(gender: string) {
    let val = this.consultationChartData.patientGender.filter((d) => {
      return d.patientGender == gender;
    });
    return val == "" ? 0 : val[0].count;
  }

  initForm() {
    this.form = this.formBuider.group({
      startDate: [this.filters.startDate],
      endDate: [this.filters.endDate],
      category: [],
    });
    this.applyFilter();
  }

  disabledField() {
    this.categoryDD = this.form.value.category[0].display;
  }

  switchtoconsultations(status: string) {
    this.router.navigate(["admin", "consultations"], {
      queryParams: { consultationStatus: status },
    });
  }

  feedbackChart() {
    if (this.feedbackCanvas != null) {
      this.feedbackCanvas.destroy();
    }
    let Month = new Date().getMonth();
    this.feedbackCanvas = new Chart("feedbackChart", {
      type: "bar",
      data: {
        labels: [
          this.setMonth(Month),
          this.setMonth(Month - 1),
          this.setMonth(Month - 2),
        ],
        datasets: [
          {
            data: [
              this.filterFeedbackReview(0, "Closed"),
              this.filterFeedbackReview(1, "Closed"),
              this.filterFeedbackReview(2, "Closed"),
            ],
            label: "Successful feedback",
            backgroundColor: "#7cc707",
            hoverBackgroundColor: "#7cc707",
          },
          {
            data: [
              this.filterFeedbackReview(0, "Pending"),
              this.filterFeedbackReview(1, "Pending"),
              this.filterFeedbackReview(2, "Pending"),
            ],
            label: "Unsuccesful feedback",
            backgroundColor: "#d00140",
            hoverBackgroundColor: "#d00140",
          },
          {
            data: [
              this.filterFeedbackReview(0, "Pending") +
                this.filterFeedbackReview(0, "Closed"),
              this.filterFeedbackReview(1, "Pending") +
                this.filterFeedbackReview(1, "Closed"),
              this.filterFeedbackReview(2, "Pending") +
                this.filterFeedbackReview(2, "Closed"),
            ],
            label: "Total Consultations",
            backgroundColor: "#F7D060",
            hoverBackgroundColor: "#F7D060",
          },
        ],
      },
      options: {
        scales: {
          y: {
            min: 0,
            beginAtZero: false,
            ticks: {
              stepSize: 1,
            },
          },
        },
      },
    });
  }

  genderBifurcation() {
    if (this.genderCanvas != null) {
      this.genderCanvas.destroy();
    }
    this.genderCanvas = new Chart("genderChart", {
      type: "doughnut",
      data: {
        labels: ["Male", "Female", "Other"],
        datasets: [
          {
            label: "My First Dataset",
            data: [
              this.filterGender("Male"),
              this.filterGender("Female"),
              this.filterGender("Other"),
            ],
            backgroundColor: ["#7cc707", "#d00140", "#F7D060"],
            hoverOffset: 4,
          },
        ],
      },
    });
  }
  appRatingChart() {
    if (this.appRatingCanvas != null) {
      this.appRatingCanvas.destroy();
    }
    this.appRatingCanvas = new Chart("appRatingChart", {
      type: "bar",
      data: {
        labels: ["Ratings"],
        datasets: [
          {
            label: "Excellent",
            data: [this.filterRatings("Excellent")],
            backgroundColor: ["#7cc707"],
          },
          {
            label: "Good",
            data: [this.filterRatings("Good")],
            backgroundColor: ["#d00140"],
          },
          {
            label: "Fair",
            data: [this.filterRatings("Fair")],
            backgroundColor: ["#F7D060"],
          },
          {
            label: "Neutral",
            data: [this.filterRatings("Neutral")],
            backgroundColor: ["#b57edc"],
          },
          {
            label: "Unsatisfactory",
            data: [this.filterRatings("Unsatisfactory")],
            backgroundColor: ["#952323"],
          },
        ],
      },
      options: {
        scales: {
          y: {
            min: 0,
            beginAtZero: false,
            ticks: {
              stepSize: 1,
            },
          },
        },
      },
    });
  }

  switchToActiveModal() {
    this.router.navigate(["admin", "active-calls"]);
  }

  filterfeedbackChart(data: any = []) {
    this.consultationCountTable = [
      {
        title: "Active",
        totalCount: this.consultationChart.Active?.consultationCount || 0,
        successfullCalls: this.consultationChart.Active?.closedCalls || 0,
        pendingCalls: this.consultationChart.Active?.pendingCalls || 0,
      },
      {
        title: "Missed",
        totalCount: this.consultationChart.Missed?.consultationCount || 0,
        successfullCalls: this.consultationChart.Missed?.closedCalls || 0,
        pendingCalls: this.consultationChart.Missed?.pendingCalls || 0,
      },
      {
        title: "Rejected",
        totalCount: this.consultationChart.Rejected?.consultationCount || 0,
        successfullCalls: this.consultationChart.Rejected?.closedCalls || 0,
        pendingCalls: this.consultationChart.Rejected?.pendingCalls || 0,
      },
      {
        title: "Cancelled",
        totalCount: this.consultationChart.Cancelled?.consultationCount || 0,
        successfullCalls: this.consultationChart.Cancelled?.closedCalls || 0,
        pendingCalls: this.consultationChart.Cancelled?.pendingCalls || 0,
      },
      {
        title: "Consulted",
        totalCount: this.consultationChart.Consulted?.consultationCount || 0,
        successfullCalls: this.consultationChart.Consulted?.closedCalls || 0,
        pendingCalls: this.consultationChart.Consulted?.pendingCalls || 0,
      },
      {
        title: "Dropped",
        totalCount: this.consultationChart.Dropped?.consultationCount || 0,
        successfullCalls: this.consultationChart.Dropped?.closedCalls || 0,
        pendingCalls: this.consultationChart.Dropped?.pendingCalls || 0,
      },
    ];
  }

  filterFeedbackReview(month: number, type: any) {
    let dateArr = [
      new Date().getMonth() + 1,
      new Date().getMonth(),
      new Date().getMonth() - 1,
    ];

    let feedback = this.feedback.find((d) => {
      return d.month == dateArr[month] && d.callStatusName == type ? d : 0;
    });
    return feedback?.feedbackCount || 0;
  }

  setMonth(month: number) {
    switch (month) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
        break;
      case 5:
        return "Jun";
        break;
      case 6:
        return "July";
        break;
      case 7:
        return "August";
        break;
      case 8:
        return "September";
        break;
      case 9:
        return "October";
        break;
      case 10:
        return "November";
        break;
      case 11:
        return "December";
        break;
    }
  }
}
