import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionsServices } from "src/app/admin/core/services/permission.service";
import { ToasterService } from "src/app/admin/core/services/toaster.service";
import { tableHeaderUserRights } from "../../data";

@Component({
  selector: "app-user-rights",
  templateUrl: "./user-rights.component.html",
  styleUrls: ["./user-rights.component.scss"],
})
export class UserRightsComponent implements OnInit {
  tableHeaderUserRights = tableHeaderUserRights;
  permission: any;
  valView: any;
  valEdit: any;
  valRole: any;
  inputPermission: any;
  editPermission: any;
  constructor(
    private http: PermissionsServices,
    private router: Router,
    private toaster: ToasterService,
    private formBuilder: FormBuilder
  ) {}
  value: any;
  data: any;
  list: any;
  formFields: {}[] = [];
  async ngOnInit(): Promise<void> {
    await this.get();
  }
  permissions: any;

  async get() {
    try {
      const apiResponse = await this.http.getAllPermissions();
      this.permissions = apiResponse.data;
      // .map((d) => {
      // return d.permission;
      // });
    } catch (err) {
      this.toaster.error(err.error);
    }
  }
  roleId: number;
  async getPermission(index: any) {
    this.list = [];
    this.data = undefined;
    this.roleId = index;
    this.permission = (
      await this.permissions.filter((d) => {
        return d.roleId == index;
      })
    )[0].permissions;
    this.valRole = index;
  }

  async updateUserSetting() {
    try {
      await this.http.editPermissions(this.data);
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";
      this.router.navigate([this.router.url]);
      this.toaster.success("Permissions are updated");
    } catch (err) {
      this.toaster.error(err.error.meta.message);
    }
  }

  inputAdd(permissionId, val) {
    let permit: number;
    if (val.target.checked) {
      permit = 1;
    } else {
      permit = 0;
    }

    if (this.data == undefined) {
      this.data = {
        roleId: this.roleId,
        permissions: this.list,
      };
      this.list.push({
        permissionId: permissionId,
        view: permit,
      });
    } else {
      if (
        this.list.some((d) => {
          return +d.permissionId == +permissionId;
        })
      ) {
        this.list = this.list.map((d) => {
          if (+d.permissionId === +permissionId) {
            return {
              ...d,
              view: permit,
            };
          } else {
            return { ...d };
          }
        });
        this.data.permissions = this.list;
      } else {
        this.list.push({
          permissionId: permissionId,
          view: permit,
        });
      }
    }
  }

  editAdd(permissionId, val) {
    let permit: number;
    if (val.target.checked) {
      permit = 1;
    } else {
      permit = 0;
    }
    if (this.data == undefined) {
      this.data = {
        roleId: this.roleId,
        permissions: this.list,
      };
      this.list.push({
        permissionId: permissionId,
        edit: permit,
      });
    } else {
      if (
        this.list.some((d) => {
          return +d.permissionId == +permissionId;
        })
      ) {
        this.list = this.list.map((d) => {
          if (+d.permissionId == +permissionId) {
            return {
              ...d,
              edit: permit,
            };
          } else {
            return { ...d };
          }
        });
        this.data.permissions = this.list;
      } else {
        this.list.push({
          permissionId: permissionId,
          edit: permit,
        });
      }
    }
  }
}
