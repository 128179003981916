import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { tableHeaderActiveConsultation } from "src/app/admin/user-management/data";
import { ConsultationService } from "../../core/services/consultation.service";
import { ToasterService } from "../../core/services/toaster.service";
import { getAllConsutations, callStatus } from "../../../shared/interface";

@Component({
  selector: "app-active-calls",
  templateUrl: "./active-calls.component.html",
  styleUrls: ["./active-calls.component.scss"],
})
export class ActiveCallsComponent implements OnInit {
  startDate: null;
  closeDate: null;
  tableHeader = tableHeaderActiveConsultation;
  partners: any = [
    { value: "All", title: "All" },
    { value: "Retail", title: "Retail" },
    { value: "Corp", title: "Corp" },
  ];
  allconsultations: any;
  type: any = [
    { value: "All", title: "All" },
    { value: "Appointment", title: "Appointment" },
    { value: "Instant", title: "Instant" },
  ];
  filter: any;
  totalCount: number = 0;

  constructor(
    private router: Router,
    private toaster: ToasterService,
    private formBuilder: FormBuilder,
    private http: ConsultationService
  ) {}
  patinetID: any;
  ngOnInit() {
    this.initForm();
    this.getValue();
  }

  initForm() {
    this.filter = this.formBuilder.group({
      doctor: [],
      partner: [],
      consultationType: [],
    });
  }

  async getValue(offset = 0) {
    const payload: getAllConsutations = {
      offset,
      callStatus: "pending",
      doctor: this.filter.value.doctor,
      partner:
        this.filter.value.partner != "All" ? this.filter.value.partner : "",
      type:
        this.filter.value.consultationType != "All"
          ? this.filter.value.consultationType
          : "",
      consultationStatus: "active",
    };
    let resData = (this.allconsultations = await this.http.allConsultation(
      payload
    ));
    this.allconsultations = resData.data;
    this.totalCount = resData.meta.totalCount;
  }

  switchToPatientDetails(Id: any) {
    this.router.navigate(["patient", "detail", "ID", Id]);
  }

  onChangevalueFromTextInput(event: any) {}
  onChangevalueToTextInput(event: any) {}

  call() {
    sessionStorage.setItem("Route", "Active Calls");
  }
  requestPaginate(offset: number = 0) {
    this.allconsultations = [];
    this.getValue(offset);
  }
  convertDignosisToString(diagnosis) {
    if (!diagnosis) return;
    diagnosis = JSON.parse(diagnosis);
    return diagnosis.join(" | ");
  }
  dateFormate(date, param) {
    if (param === "date") {
      return new Date(date);
    }
    if (param === "time") {
      let d = new Date(date).toLocaleTimeString();

      return d;
    }
  }
}
