<form
  [formGroup]="addUserForm"
  id="testmodal"
  class="container-fluid"
  (ngSubmit)="valueData == '' ? onSubmit() : UpdateUser()"
>
  <!-- (ngSubmit)="{ valueData == "" ? onSubmit() : ''}" -->
  <div class="row">
    <div class="col-12">
      <h3 id="heading">
        {{ valueData == "" ? "ADD USER" : "UPDATE USER DETAILS" }}
      </h3>

      <span class="close-modal-btn" (click)="close()">
        <fa-icon [icon]="xmark"></fa-icon>
      </span>
      <div>
        <hr class="borderdec" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6">
      <input
        formControlName="firstName"
        type="text"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="First Name"
        [class]="
          addUserForm.get('firstName') &&
          addUserForm.get('firstName').touched &&
          addUserForm.get('firstName').invalid
            ? 'is-invalid'
            : ''
        "
      />
      <div
        class="invalid-feedback"
        *ngIf="
          addUserForm.get('firstName') &&
          addUserForm.get('firstName').touched &&
          addUserForm.get('firstName').errors
        "
      >
        <span *ngIf="addUserForm.get('firstName').getError('required')"
          >Required Field</span
        >
        <span *ngIf="addUserForm.get('firstName').getError('emptyString')">{{
          emptySpacesNotAllowed
        }}</span>
        <span *ngIf="addUserForm.get('firstName').getError('maxlength')"
          >Maximum {{ validations.name.length }} characters allowed</span
        >
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6">
      <input
        formControlName="lastName"
        name="lastName"
        type="text"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Last Name"
        [class]="
          addUserForm.get('lastName') &&
          addUserForm.get('lastName').touched &&
          addUserForm.get('lastName').invalid
            ? 'is-invalid'
            : ''
        "
      />
      <div
        class="invalid-feedback"
        *ngIf="
          addUserForm.get('lastName') &&
          addUserForm.get('lastName').touched &&
          addUserForm.get('lastName').errors
        "
      >
        <span *ngIf="addUserForm.get('lastName').getError('required')"
          >Required Field</span
        >
        <span *ngIf="addUserForm.get('lastName').getError('emptyString')">{{
          emptySpacesNotAllowed
        }}</span>
        <span *ngIf="addUserForm.get('lastName').getError('maxlength')"
          >Maximum {{ validations.name.length }} characters allowed</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input
        formControlName="email"
        [ngStyle]="{
          display: valueData == '' ? '' : 'none'
        }"
        type="email"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        [class]="
          addUserForm.get('email') &&
          addUserForm.get('email').touched &&
          addUserForm.get('email').invalid
            ? 'is-invalid'
            : ''
        "
        placeholder="E mail"
        [maxlength]="validations.email.length"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          addUserForm.get('email') &&
          addUserForm.get('email').touched &&
          addUserForm.get('email').errors
        "
      >
        <span *ngIf="addUserForm.get('email').getError('required')"
          >Required Field</span
        >
        <span *ngIf="addUserForm.get('email').getError('pattern')"
          >Invalid email</span
        >
        <span *ngIf="addUserForm.get('email').getError('maxlength')"
          >Maximum {{ validations.email.length }} characters allowed</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input
        formControlName="phone"
        type="text"
        maxlength="16"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Example +923xxxxxxxxx"
        [class]="
          addUserForm.get('phone') &&
          addUserForm.get('phone').touched &&
          addUserForm.get('phone').invalid
            ? 'is-invalid'
            : ''
        "
      />
      <div
        class="invalid-feedback"
        *ngIf="
          addUserForm.get('phone') &&
          addUserForm.get('phone').touched &&
          addUserForm.get('phone').invalid
        "
      >
        <span *ngIf="addUserForm.get('phone').getError('required')"
          >Required Field</span
        >
        <span
          *ngIf="
            addUserForm.get('phone').getError('minlength') &&
            addUserForm.get('phone').getError('pattern')
          "
          >Minimum {{ validations.phone.minlength }} characters allowed</span
        >
        <span
          *ngIf="
            addUserForm.get('phone').getError('pattern') &&
            !addUserForm.get('phone').getError('minlength') &&
            !addUserForm.get('phone').getError('required')
          "
          >Invalid phone number</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6">
      <input
        formControlName="password"
        type="password"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Password"
        [class]="
          addUserForm.get('password') &&
          addUserForm.get('password').touched &&
          addUserForm.get('password').invalid
            ? 'is-invalid'
            : ''
        "
        [ngStyle]="{
          display: valueData == '' ? '' : 'none'
        }"
        [minlength]="validations.password.minLength"
        [maxlength]="validations.password.maxLength"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          addUserForm.get('password') &&
          addUserForm.get('password').touched &&
          addUserForm.get('password').errors
        "
      >
        <span *ngIf="addUserForm.get('password').getError('required')"
          >Required Field</span
        >
        <span *ngIf="addUserForm.get('password').getError('minlength')"
          >Password must be 8 characters long</span
        >
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6">
      <input
        formControlName="confirm"
        type="password"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Confirm"
        [class]="
          addUserForm.get('confirm') &&
          addUserForm.get('confirm').touched &&
          addUserForm.get('confirm').invalid
            ? 'is-invalid'
            : ''
        "
        [ngStyle]="{
          display: valueData == '' ? '' : 'none'
        }"
        [minlength]="validations.password.minLength"
        [maxlength]="validations.password.maxLength"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          addUserForm.get('confirm') &&
          addUserForm.get('confirm').touched &&
          addUserForm.get('confirm').errors
        "
      >
        <span *ngIf="addUserForm.get('confirm').getError('required')"
          >Required Field</span
        >
        <span *ngIf="addUserForm.get('confirm').getError('minlength')"
          >Password must be 8 characters long</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input
        formControlName="agentExtension"
        type="number"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Extension Number"
        [class]="
          addUserForm.get('agentExtension') &&
          addUserForm.get('agentExtension').touched &&
          addUserForm.get('agentExtension').invalid
            ? 'is-invalid'
            : ''
        "
      />
      <div
        class="invalid-feedback"
        *ngIf="
          addUserForm.get('agentExtension') &&
          addUserForm.get('agentExtension').touched &&
          addUserForm.get('agentExtension').invalid
        "
      >
        <span *ngIf="addUserForm.get('agentExtension').getError('required')"
          >Required Field</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label for="">Gender</label>

      <select
        formControlName="gender"
        class="form-control shadow-sm bg-body rounded"
        name="gender"
        [class]="
          addUserForm.get('gender') &&
          addUserForm.get('gender').touched &&
          addUserForm.get('gender').invalid
            ? 'is-invalid'
            : ''
        "
      >
        <option value="" disabled hidden>Select</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>
      <div
        class="invalid-feedback"
        *ngIf="
          addUserForm.get('gender') &&
          addUserForm.get('gender').touched &&
          addUserForm.get('gender').errors
        "
      >
        <span *ngIf="addUserForm.get('gender').getError('required')"
          >Required Field</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label for="">User Role</label>

      <select
        formControlName="userRole"
        class="form-control shadow-sm bg-body rounded"
        name="userRole"
        [class]="
          addUserForm.get('userRole') &&
          addUserForm.get('userRole').touched &&
          addUserForm.get('userRole').invalid
            ? 'is-invalid'
            : ''
        "
        (change)="OnChange()"
      >
        <option value="" disabled hidden>Select</option>
        <option *ngFor="let role of resRoles" [value]="[role.id, role.name]">
          {{ role.name }}
        </option>
      </select>
      <div
        class="invalid-feedback"
        *ngIf="
          addUserForm.get('userRole') &&
          addUserForm.get('userRole').touched &&
          addUserForm.get('userRole').errors
        "
      >
        <span *ngIf="addUserForm.get('userRole').getError('required')"
          >Required Field</span
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label for=""> User Location </label>
      <select
        formControlName="address"
        class="form-control shadow-sm bg-body rounded"
        name="address"
        [class]="
          addUserForm.get('address') &&
          addUserForm.get('address').touched &&
          addUserForm.get('address').invalid
            ? ' is-invalid'
            : ''
        "
      >
        <option value="" disabled hidden>Select</option>

        <option *ngFor="let location of resLocation" [value]="location.id">
          {{ location.location }}
        </option>
      </select>
      <div
        class="invalid-feedback"
        *ngIf="
          addUserForm.get('address') &&
          addUserForm.get('address').touched &&
          addUserForm.get('address').errors
        "
      >
        <span *ngIf="addUserForm.get('address').getError('required')"
          >Required Field</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button
        class="btn btn-danger btn-lg align-content-center"
        [disabled]="
          valueData == ''
            ? addUserForm.invalid
            : addUserForm.touched
            ? false
            : true
        "
      >
        <i
          class="fa fa-plus-circle"
          aria-hidden="true"
          [ngStyle]="{
            display: valueData == '' ? '' : 'none'
          }"
        ></i>
        {{ valueData == "" ? "ADD USER" : "UPDATE USER DETAILS" }}
      </button>
    </div>
  </div>
</form>
