<div class="container-fluid">
  <form [formGroup]="filterForm" action="">
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <label for="exampleFormControlInput1" class="form-label">From</label>
        <nz-date-picker
          formControlName="startDate"
          [(ngModel)]="filterFormValue.startDate"
          class="form-control shadow-sm bg-body rounded"
        ></nz-date-picker>
      </div>
      <div class="col-sm-12 col-md-4">
        <label for="exampleFormControlInput1" class="form-label">To</label>
        <nz-date-picker
          formControlName="endDate"
          [(ngModel)]="filterFormValue.endDate"
          class="form-control shadow-sm bg-body rounded"
        ></nz-date-picker>
      </div>
      <div class="col-sm-12 col-md-4">
        <label for="" style="color: white; display: block">.</label>
        <button class="btn btn-danger float-right" (click)="applyFilter()">
          Apply Filter
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-3">
    <div class="col-sm-12 col-md-4">
      <div class="heading">Doctors Rating</div>
      <div class="text-center field-loader" *ngIf="ratAndBehave === undefined">
        <img src="assets/images/SK-Logo-Animation.gif" alt="" />
      </div>
      <canvas id="doctorsRatings"></canvas>
    </div>
    <div class="col-sm-12 col-md-8">
      <div class="heading">Doctors Behaviour</div>
      <div class="text-center field-loader" *ngIf="ratAndBehave === undefined">
        <img src="assets/images/SK-Logo-Animation.gif" alt="" />
      </div>
      <canvas id="doctorsBehaviour"></canvas>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="heading">Best Performing Doctors</div>
      <table class="table table-hover table-borderless">
        <thead>
          <tr>
            <td>Doctor Name</td>
            <td>Excellent</td>
            <td>Good</td>
            <td>Fair</td>
            <td>Neutral</td>
            <td>Unsatisfactory</td>
          </tr>
        </thead>
        <tbody class="mt-4">
          <tr
            class="mt-4 shadow-sm bg-body rounded"
            *ngFor="let item of topDoctorsResponse"
          >
            <td>{{ item.doctorName || 0 }}</td>
            <td>{{ item.excellent || 0 }}</td>
            <td>{{ item.good || 0 }}</td>
            <td>{{ item.fair || 0 }}</td>
            <td>{{ item.neutral || 0 }}</td>
            <td>{{ item.unsatisfactory || 0 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div
    class="text-center field-loader"
    *ngIf="topDoctorsResponse === undefined"
  >
    <img src="assets/images/SK-Logo-Animation.gif" alt="" />
  </div>
</div>
