<div class="container-fluid">
  <form [formGroup]="form" (ngSubmit)="applyFilter()">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-2" [ngClass]="">
        <label for="">From</label>
        <nz-date-picker
          formControlName="startDate"
          class="form-control shadow-sm bg-body rounded"
        ></nz-date-picker>
      </div>
      <div class="col-12 col-sm-6 col-md-2" [ngClass]="">
        <label for="">TO</label>
        <nz-date-picker
          formControlName="endDate"
          class="form-control shadow-sm bg-body rounded"
        ></nz-date-picker>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-2">
        <label style="color: white" for="">Patient Phone Number</label>
        <input
          class="form-control shadow-sm bg-body rounded"
          type="number"
          placeholder="Patient Phone Number"
          formControlName="patientNumber"
        />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-2">
        <label style="color: white" for="">Consultation Type</label>

        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="value"
          placeholder="Consultation Type"
          [items]="consultationType"
          formControlName="type"
        >
        </ng-select>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-2">
        <label style="color: white" for="">Feedback Type</label>

        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="value"
          placeholder="Feedback Type"
          [items]="feedbackType"
          formControlName="callStatus"
        ></ng-select>
      </div>
      <div class="col-sm-2">
        <label style="color: white" for="">Feedback Type</label>

        <button type="submit" class="btn btn-danger w-100">Apply Filter</button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12 col-md-6 col-lg-2">
        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="value"
          placeholder="Platform"
          [items]="platform"
          formControlName="partner"
        >
        </ng-select>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-2">
        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="value"
          placeholder="Consult: Status"
          [items]="consultationStatus"
          [multiple]="true"
          [closeOnSelect]="false"
          formControlName="consultationStatus"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngIf="items.length > 0">
              <span class="ng-value-label">selected {{ items.length }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-2">
        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="value"
          placeholder="Agent Name"
          [items]="agentName"
          [multiple]="true"
          [closeOnSelect]="false"
          formControlName="agentId"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngIf="items.length > 0">
              <span class="ng-value-label">selected {{ items.length }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-2">
        <input
          class="form-control shadow-sm bg-body rounded"
          type="text"
          placeholder="Search by doctor"
          formControlName="doctor"
        />
      </div>

      <div class="col-md-2">
        <div>
          <ng-select
            class="form-control shadow-sm bg-body rounded"
            bindLabel="title"
            bindValue="title"
            placeholder="Export Limit"
            [items]="exportLimit"
            formControlName="exportLimit"
            (change)="setExportLimit($event)"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-sm-2">
        <button
          type="button"
          class="btn btn-danger w-100"
          (click)="sortConsultation()"
        >
          Assign Consultation
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-9">
        <label for=""> Total Count : {{ totalCount || 0 }} </label>
      </div>

      <div class="col-sm-3">
        <img
          (click)="excelExport()"
          src="/assets/images/excel.ico"
          class="excelStyles float-right"
          height="35px"
        />
      </div>
    </div>
  </form>
  <div id="table_div" [ngClass]="response == undefined ? 'fixed' : 'scroll'">
    <table class="table table-hover table-borderless">
      <thead>
        <tr style="background-color: #7cc707 !important">
          <td>Patient Name</td>
          <td>Patient Number</td>
          <td>Doctor Name</td>
          <td>Assigned Agent</td>
          <td>Duraion</td>
          <td>Diagnosis</td>
          <td>Date and time</td>
        </tr>
      </thead>
      <tbody class="mt-4">
        <tr
          *ngFor="let item of response"
          class="mt-4 shadow-sm bg-body rounded"
        >
          <td>{{ item.patientName || "-" }}</td>
          <td>{{ item.patientPhone || "-" }}</td>
          <td>{{ item.doctorName || "-" }}</td>
          <td>
            {{
              item.user
                ? item?.user?.firstName + " " + item?.user?.lastName
                : "-"
            }}
          </td>
          <td>{{ item.duration || "-" }}</td>
          <td>{{ convertDignosisToString(item.diagnosis) }}</td>
          <td>
            <p id="title">
              {{ item.consultationDate || "-" }}
            </p>
            <p>{{ item.consultationTime || "-" }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-center field-loader" *ngIf="response === undefined">
    <img src="assets/images/SK-Logo-Animation.gif" alt="" />
  </div>
  <app-pagination
    (pageNumber)="applyFilter({ offset: $event })"
    [totalCount]="totalCount"
  ></app-pagination>
</div>
