import { Injectable } from "@angular/core";
import { Chart } from "chart.js";
import { BehaviorSubject, Subject } from "rxjs";
import {
  BarChartParams,
  pieAndDonutChartInterface,
} from "src/app/shared/interface";

@Injectable({
  providedIn: "root",
})
export class charts {
  pieOrDonutChart = (param: pieAndDonutChartInterface) => {
    if (param.chart != null) {
      param.chart.destroy();
    }
    param.chart = new Chart(param.title, {
      type: param.type,
      data: {
        labels: param.labels,
        datasets: [
          {
            label: param.dataTypes.label,
            data: param.dataTypes.data,
            backgroundColor: param.dataTypes.backgroundColor,
            hoverOffset: 4,
          },
        ],
      },
    });
    return param.chart;
  };

  barChart = (param: BarChartParams) => {
    if (param.chart != null) {
      param.chart.destroy();
    }
    let datasetspayload: any;

    if (param.comparative) {
      datasetspayload = param.datasetsArr.map((d) => {
        return {
          label: d.label,
          data: d.data,
          backgroundColor: d.backgroundColor,
          borderWidth: 0,
        };
      });
    }
    if (!param.comparative) {
      datasetspayload = [
        {
          label: param.datasets[0].label,
          data: param.datasets[0].data,
          backgroundColor: param.datasets[0].backgroundColor,
          borderWidth: 0,
        },
      ];
    }
    param.chart = new Chart(param.title, {
      type: "bar",
      data: {
        labels: param.labels,
        datasets: datasetspayload,
      },
    });

    return param.chart;
  };
}
