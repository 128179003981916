<form [formGroup]="formData" id="testmodal" class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h3 id="heading" [innerHTML]="modalTitle"></h3>
      <span class="close-modal-btn" (click)="close()">
        <fa-icon [icon]="xmark"></fa-icon>
        <!-- <i class="fa fa-times" aria-hidden="true"></i> -->
      </span>
      <div>
        <hr class="borderdec" />
      </div>
    </div>
  </div>
  <!--  -->
  <ng-container *ngFor="let item of FormFields">
    <div class="row mt-2" *ngIf="item.rowType == 'uncollapse'">
      <!--  -->
      <div class="col-6" *ngFor="let field of item.fields">
        <div *ngIf="field.type == 'input'">
          <label for="exampleFormControlInput1" class="form-label">{{
            field.label
          }}</label>

          <input
            type="text"
            [formControlName]="field.questionId"
            class="form-control shadow-sm bg-body rounded"
            [placeholder]="field.placeholder"
            [class]="
              formData.get(field.questionId) &&
              formData.get(field.questionId).touched &&
              formData.get(field.questionId).invalid
                ? 'is-invalid'
                : ''
            "
            (keyup)="getValue()"
          />
          <div
            class="invalid-feedback"
            *ngIf="
              formData.get(field.questionId) &&
              formData.get(field.questionId).touched &&
              formData.get(field.questionId).errors
            "
          >
            <span *ngIf="formData.get(field.questionId).getError('required')"
              >Required Field</span
            >
            <span
              *ngIf="
                formData.get(field.questionId).getError('minlength') &&
                formData.get(field.questionId).getError('pattern')
              "
              >Minimum {{ validations.phone.minlength }} characters
              allowed</span
            >
            <span
              *ngIf="
                formData.get(field.questionId).getError('pattern') &&
                !formData.get(field.questionId).getError('minlength') &&
                !formData.get(field.questionId).getError('required')
              "
              >Invalid phone number</span
            >
          </div>
        </div>
        <div *ngIf="field.type == 'select'">
          <label for="exampleFormControlInput1" class="form-label">{{
            field.label
          }}</label>
          <ng-select
            [items]="field.options"
            placeholder="Select"
            class="form-control shadow-sm bg-body rounded"
            [formControlName]="field.questionId"
            [class]="
              formData.get(field.questionId) &&
              formData.get(field.questionId).touched &&
              formData.get(field.questionId).invalid
                ? 'is-invalid'
                : ''
            "
            (change)="getValue()"
            [addTag]="
              field.questionId == 'CC' || field.questionId == 'DC'
                ? true
                : false
            "
          >
          </ng-select>

          <div
            class="invalid-feedback"
            *ngIf="
              formData.get(field.questionId) &&
              formData.get(field.questionId).touched &&
              formData.get(field.questionId).errors
            "
          >
            <span *ngIf="formData.get(field.questionId).getError('required')"
              >Required Field</span
            >
            <span
              *ngIf="
                formData.get(field.questionId).getError('minlength') &&
                formData.get(field.questionId).getError('pattern')
              "
              >Minimum {{ validations.phone.minlength }} characters
              allowed</span
            >
            <span
              *ngIf="
                formData.get(field.questionId).getError('pattern') &&
                !formData.get(field.questionId).getError('minlength') &&
                !formData.get(field.questionId).getError('required')
              "
              >Invalid phone number</span
            >
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <nz-collapse class="mt-4" *ngIf="item.rowType == 'collapse'">
      <nz-collapse-panel [nzHeader]="item.name" [nzActive]="item.active">
        <div class="row">
          <div class="col-6" *ngFor="let field of item.fields">
            <div *ngIf="field.type == 'input'">
              <label for="exampleFormControlInput1" class="form-label">{{
                field.label
              }}</label>

              <input
                type="text"
                [formControlName]="field.questionId"
                class="form-control shadow-sm bg-body rounded"
                (keyup)="getValue(item.name)"
                [placeholder]="field.placeholder"
                [class]="
                  formData.get(field.questionId) &&
                  formData.get(field.questionId).touched &&
                  formData.get(field.questionId).invalid
                    ? 'is-invalid'
                    : ''
                "
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  formData.get(field.questionId) &&
                  formData.get(field.questionId).touched &&
                  formData.get(field.questionId).errors
                "
              >
                <span
                  *ngIf="formData.get(field.questionId).getError('required')"
                  >Required Field</span
                >
              </div>
            </div>
            <div *ngIf="field.type == 'select'">
              <label for="exampleFormControlInput1" class="form-label">{{
                field.label
              }}</label>
              <ng-select
                [items]="field.options"
                class="form-control shadow-sm bg-body rounded"
                (change)="getValue()"
                [formControlName]="field.questionId"
                placeholder="Select"
                [addTag]="
                  field.questionId == 'CC' || field.questionId == 'DC'
                    ? true
                    : false
                "
                [class]="
                  formData.get(field.questionId) &&
                  formData.get(field.questionId).touched &&
                  formData.get(field.questionId).invalid
                    ? 'is-invalid'
                    : ''
                "
              >
              </ng-select>
              <div
                class="invalid-feedback"
                *ngIf="
                  formData.get(field.questionId) &&
                  formData.get(field.questionId).touched &&
                  formData.get(field.questionId).errors
                "
              >
                <span
                  *ngIf="formData.get(field.questionId).getError('required')"
                  >Required Field</span
                >
              </div>
            </div>
          </div>
          <div
            class="col-12"
            *ngIf="valueData == 'Missed' || valueData == 'Rejected'"
          >
            <button
              (click)="call(item.name)"
              class="btn call-btn w-25 float-right"
            >
              {{
                item.name == "Patient Feedback"
                  ? "Call To Patient"
                  : "Call To Doctor"
              }}
            </button>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
  </ng-container>

  <!-- testing code -->

  <div class="row mt-3">
    <div
      class="col-12"
      [ngStyle]="{
        display: route == 'closed-calls' || btnType == 'view' ? 'none' : ''
      }"
    >
      <button
        id="submitBtn"
        class="btn btn-danger btn-lg align-content-center"
        (click)="onSubmit()"
        [attr.disabled]="disabled"
      >
        Submit
      </button>
    </div>
  </div>
</form>
