<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="220px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
    nzTheme="light"
  >
    <div
      class="sidebar-logo"
      [ngStyle]="{
        'padding-left': isCollapsed ? '15px' : '50px'
      }"
    >
      <img
        *ngIf="!isCollapsed"
        width="70%"
        src="../assets/images/Sehat-Kahani-Logo-File.png"
        alt="logo"
      />
      <img *ngIf="isCollapsed" src="../assets/images/logo.png" alt="logo" />
    </div>
    <app-sidebar [isCollapsed]="isCollapsed"></app-sidebar>
  </nz-sider>
  <nz-layout>
    <nz-header class="container-fluid header_box">
      <div class="row">
        <div class="col-6 route-div">
          <span style="cursor: pointer" (click)="isCollapsed = !isCollapsed">
            <i nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
          </span>
          <span class="route-title">
            <p class="new">{{ currentUrl }}</p>
          </span>
        </div>

        <div class="col-6" id="user-box">
          <div id="user-title">
            <p>Hi {{ userName | titlecase }}</p>
          </div>
          <div id="user-image-box">
            <img src="assets/images/user.png" alt="user" />
          </div>
        </div>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
