import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NZ_I18N } from "ng-zorro-antd/i18n";
import { en_US } from "ng-zorro-antd/i18n";
import { CommonModule, registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { ToastrModule } from "ngx-toastr";
import { IconsProviderModule } from "./icons-provider.module";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzConfig } from "ng-zorro-antd/core/config";
import { TokenInterceptor } from "./Interceptor/token.interceptor";
import { NgProgress, NgProgressModule } from "@ngx-progressbar/core";
import { NgProgressHttpModule } from "@ngx-progressbar/http";
import { silentApiList } from "./shared/constant";
import { AdminModule } from "./admin/admin.module";
import { CoreModule } from "./admin/core/core.module";
import { ModalModule } from "./admin/modals/modal.module";
registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  message: { nzTop: 120 },
  notification: { nzTop: 240 },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    ModalModule,
    NgbModule,
    CoreModule,
    NzLayoutModule,
    NzMenuModule,
    HttpClientModule,
    BrowserModule,
    IconsProviderModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      autoDismiss: true,
      newestOnTop: true,
      preventDuplicates: true,
    }),
    NgProgressModule,
    NgProgressHttpModule.withConfig({
      silentApis: silentApiList,
    }),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AppRoutingModule,
    NzToolTipModule,
    NzIconModule,
    AdminModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    NgProgress,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
