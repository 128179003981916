import { Component, OnInit } from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalService } from "src/app/admin/core/services/modal.service";
import { ToasterService } from "src/app/admin/core/services/toaster.service";
import { userTypeService } from "src/app/admin/core/services/userType.service";
import { AddUserTypeComponent } from "src/app/admin/modals/AddUserType/AddUserType.component";
import { tableHeaderUserTypes, tableBodyUserType } from "../../data/index";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
interface ModalServiceAttributes {
  key: string;
  value: any;
}
@Component({
  selector: "app-user-types",
  templateUrl: "./user-types.component.html",
  styleUrls: ["./user-types.component.scss"],
})
export class UserTypesComponent implements OnInit {
  editRole = faUserPen;
  userTableHeader = tableHeaderUserTypes;
  userTableData = tableBodyUserType;
  res: any;

  constructor(
    private modalService: ModalService,
    private toaster: ToasterService,
    private http: userTypeService
  ) {}

  ngOnInit(): void {
    this.getUserTypes();
  }
  async open(id?: number, name?: string) {
    const attributes: ModalServiceAttributes[] = [];
    try {
      attributes.push(
        {
          key: "id",
          value: id,
        },
        {
          key: "name",
          value: name,
        }
      );
      const result = this.modalService.open(
        AddUserTypeComponent,
        {
          centered: true,
          backdrop: "static",
        },
        attributes
      );
      const success: any = await this.modalService.confirmationModalResult(
        result
      );

      if (success) {
        this.getUserTypes();
      }
    } catch (err) {
      this.toaster.error(err.error.meta.message);
    }
  }
  // open() {
  //   this.ModalService.open(AddUserTypeComponent, {
  //     centered: true,
  //     backdrop: "static",
  //   });
  // }

  // async getUserTypes() {
  //   this.res = (await this.userType.getAllPermissions()).data;
  // }

  // async navigatetoView(index: any) {
  //   const attributes: ModalServiceAttributes[] = [];
  //   attributes.push({
  //     key: "ViewData",
  //     value: this.res[index],
  //   });
  //   const result = this.modalService.open(
  //     AddUserTypeComponent,
  //     {
  //       centered: true,
  //       backdrop: "static",
  //     },
  //     attributes
  //   );
  //   const success: any = await this.modalService.confirmationModalResult(
  //     result
  //   );
  //   if (success) {
  //     this.getUserTypes();
  //   }
  // }
  // async navigatetoEdit(index: any) {
  //   const attributes: ModalServiceAttributes[] = [];
  //   attributes.push({
  //     key: "EditData",
  //     value: this.res[index],
  //   });
  //   const result = this.modalService.open(
  //     AddUserTypeComponent,
  //     {
  //       centered: true,
  //       backdrop: "static",
  //     },
  //     attributes
  //   );
  //   const success: any = await this.modalService.confirmationModalResult(
  //     result
  //   );
  //   if (success) {
  //     this.getUserTypes();
  //   }
  // }
  async getUserTypes() {
    try {
      this.res = (await this.http.getAllTypes()).data;
    } catch (err) {
      this.toaster.error(err.error.meta.message);
    }
  }
}
