<div
  class="container-fluid"
  [ngStyle]="{ display: display == 1 ? '' : 'none' }"
>
  <form [formGroup]="form" action="">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label for="exampleFormControlInput1" class="form-label">From</label>
        <nz-date-picker
          formControlName="startDate"
          [(ngModel)]="startDate"
          class="form-control shadow-sm bg-body rounded"
        ></nz-date-picker>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label for="exampleFormControlInput1" class="form-label">To</label>
        <nz-date-picker
          formControlName="endDate"
          [(ngModel)]="endDate"
          class="form-control shadow-sm bg-body rounded"
        ></nz-date-picker>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Platform</label
        >
        <!-- <tag-input
          class="form-control"
          [ngModel]="[]"
          [onlyFromAutocomplete]="true"
          [maxItems]="1"
          formControlName="category"
          secondaryPlaceholder="Enter Text"
          (onAdd)="disabledField()"
        >
          <tag-input-dropdown
            [showDropdownIfEmpty]="true"
            [autocompleteItems]="category"
          >
            <ng-template let-item="item">
              {{ item.display }}
            </ng-template>
          </tag-input-dropdown>
        </tag-input> -->
        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="title"
          placeholder="Select"
          [items]="category"
          formControlName="category"
        >
        </ng-select>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <button
          class="btn btn-danger filter-btn float-right"
          (click)="applyFilter()"
          [disabled]="isCountProcess"
        >
          Apply Filter
        </button>
      </div>
    </div>
  </form>

  <div class="row" id="chartsDiv">
    <!-- Table -->
    <div class="col-sm-12 col-xl-6">
      <div id="total-consultation-box">
        <h1>
          <span id="text-head" class="w-75"> Total number of consulations</span>
          <span id="number-head" class="w-25">
            {{ consultationTotalCount }}</span
          >
        </h1>
      </div>
      <div id="states-table">
        <table class="table table-hover">
          <thead>
            <tr style="background-color: #7cc707 !important">
              <td style="color: #fff" *ngFor="let item of tableHeader">
                {{ item }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let count of consultationCountTable">
              <td>{{ count.title }}</td>
              <td>{{ count.totalCount }}</td>
              <td>{{ count.successfullCalls }}</td>
              <td>
                <span class="table-text-span">
                  {{ count.pendingCalls }}
                </span>
                <span
                  class="table-img-span"
                  *ngIf="count.title === 'Active' && count.pendingCalls != 0"
                >
                  <!-- 'Active'
                   ? switchtoconsultations(Active) : -->
                  <!-- item.category != 'Active'
                   ? 'assets/images/contents/Calling-2.png'
                   :  -->
                  <img
                    (click)="switchToActiveModal()"
                    [src]="'assets/images/view.png'"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </span>
                <span
                  class="table-img-span"
                  *ngIf="count.title !== 'Active' && count.pendingCalls != 0"
                >
                  <img
                    (click)="switchtoconsultations(count.title)"
                    [src]="'assets/images/Calling-2.png'"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="text-center field-loader"
          *ngIf="consultationChartData === undefined"
        >
          <img src="assets/images/SK-Logo-Animation.gif" alt="" />
        </div>
      </div>
    </div>
    <!-- Application Rating charts -->
    <div class="col-sm-12 col-xl-6">
      <h1 class="chart"><span>Application Ratings</span></h1>
      <div class="text-center field-loader" *ngIf="appRating === undefined">
        <img src="assets/images/SK-Logo-Animation.gif" alt="" />
      </div>
      <canvas id="appRatingChart"></canvas>
    </div>
    <!-- Gender Chart  -->
    <div class="col-sm-12 col-xl-4">
      <h1 class="chart"><span>Gender Bifurcation</span></h1>
      <div
        class="text-center field-loader"
        *ngIf="consultationChartData === undefined"
      >
        <img src="assets/images/SK-Logo-Animation.gif" alt="" />
      </div>
      <canvas id="genderChart"></canvas>
    </div>
    <!-- Feedback Chart -->
    <div class="col-sm-12 col-xl-8">
      <h1 class="chart"><span>Feedback Review</span></h1>
      <div class="text-center field-loader" *ngIf="feedback === undefined">
        <img src="assets/images/SK-Logo-Animation.gif" alt="" />
      </div>
      <canvas id="feedbackChart"> </canvas>
    </div>
  </div>
</div>
<div
  class="container-fluid"
  [ngStyle]="{ display: display == 0 ? '' : 'none' }"
>
  <div id="none">
    <p>You Donot have permission for this Tab</p>
  </div>
</div>
