import { AppEnvironment } from "src/app/admin/core/interfaces/app-environment.interface";

export const environment: AppEnvironment = {
  production: false,
  baseApiUrl: "https://example.com/api/v1",
  security: {
    allowedOrigins: "https://example.com",
  },
  apiUrl: "https://sk-crm-api.azurewebsites.net/api/v1",
};
