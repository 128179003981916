import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { adminAppValidations } from "src/app/shared/interface";
import { AllUserService } from "../../core/services/allUsers.service";
import { ToasterService } from "../../core/services/toaster.service";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-edit-password",
  templateUrl: "./edit-password.component.html",
  styleUrls: ["./edit-password.component.scss"],
})
export class EditPasswordComponent implements OnInit {
  @Input() userId: string;
  xmark = faXmark;
  validations = adminAppValidations;
  form: any;
  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private toaster: ToasterService,
    private userService: AllUserService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(this.validations.password.minLength),
        ],
      ],
      confirm: [
        "",
        [
          Validators.required,
          Validators.minLength(this.validations.password.minLength),
        ],
      ],
    });
  }
  async submit() {
    let formData = this.form.value;
    if (formData.password !== formData.confirm) {
      this.toaster.error("Your password doesn't Match");
    } else {
      formData = {
        ...formData,
        userId: this.userId,
      };

      await this.userService.updateUserPasswordByAdmin(formData);
      this.activeModal.close(true);
    }
  }
  close() {
    this.activeModal.close(false);
  }
}
