import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

interface IEdit {
  roleId: string;
  permissionId: string;
  view?: boolean;
  edit?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class PermissionsServices {
  constructor(private http: HttpService) {}

  async getAllPermissions() {
    return await this.http.get(`permissions/get-permissions`);
  }

  async editPermissions(data: any) {
    return await this.http.put(`permissions/edit-permissions`, data);
  }
}
