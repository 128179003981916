<div id="testmodal" class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h3 id="heading">Add <span> User Types </span></h3>
      <span class="close-modal-btn" (click)="close()">
        <fa-icon [icon]="xmark"></fa-icon>
      </span>

      <div>
        <hr class="borderdec" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input
        type="text"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Add Type "
        [formControl]="type"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button
        class="btn btn-danger btn-lg align-content-center"
        (click)="submit()"
      >
        {{ id ? "Update Type" : "Add Type" }}
      </button>
    </div>
  </div>
</div>
