<div id="testmodal" class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h3 id="heading">Select <span> Agents </span></h3>
      <span class="close-modal-btn" (click)="close()">
        <fa-icon [icon]="xmark"></fa-icon>
      </span>

      <div>
        <hr class="borderdec" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-10">
      <label for=""> Selected Consultations: </label>
    </div>
    <div class="col-2" style="text-align: right">
      <label for="">
        {{ consultationsCount || 0 }}
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-select
        class="form-control shadow-sm bg-body rounded"
        bindLabel="title"
        bindValue="value"
        placeholder="Agent Name"
        [items]="agentIds"
        [multiple]="true"
        [closeOnSelect]="false"
        [formControl]="agentId"
      >
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngIf="items.length > 0">
            <span class="ng-value-label">selected {{ items.length }}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button
        class="btn btn-danger btn-lg align-content-center"
        (click)="submit()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
