<form [formGroup]="form" class="container">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6">
      <label for="exampleFormControlInput1" class="form-label"
        >First Name</label
      >
      <input
        formControlName="firstName"
        type="text"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Enter First Name"
        [class]="
          form.get('firstName') &&
          form.get('firstName').touched &&
          form.get('firstName').invalid
            ? 'is-invalid'
            : ''
        "
      />
      <div
        class="invalid-feedback"
        *ngIf="
          form.get('firstName') &&
          form.get('firstName').touched &&
          form.get('firstName').errors
        "
      >
        <span *ngIf="form.get('firstName').getError('required')"
          >Required Field</span
        >
        <span *ngIf="form.get('firstName').getError('emptyString')">{{
          emptySpacesNotAllowed
        }}</span>
        <span *ngIf="form.get('firstName').getError('maxlength')"
          >Maximum {{ validations.name.length }} characters allowed</span
        >
      </div>
      <!-- <input
        formControlName="firstName"
        type="text"
        placeholder="Enter First Name"
        class="form-control shadow-sm bg-body rounded"
      /> -->
    </div>
    <div class="col-12 col-sm-6 col-md-6">
      <label for="exampleFormControlInput1" class="form-label">Last Name</label>
      <input
        formControlName="lastName"
        name="lastName"
        type="text"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Enter Last Name"
        [class]="
          form.get('lastName') &&
          form.get('lastName').touched &&
          form.get('lastName').invalid
            ? 'is-invalid'
            : ''
        "
      />
      <div
        class="invalid-feedback"
        *ngIf="
          form.get('lastName') &&
          form.get('lastName').touched &&
          form.get('lastName').errors
        "
      >
        <span *ngIf="form.get('lastName').getError('required')"
          >Required Field</span
        >
        <span *ngIf="form.get('lastName').getError('emptyString')">{{
          emptySpacesNotAllowed
        }}</span>
        <span *ngIf="form.get('lastName').getError('maxlength')"
          >Maximum {{ validations.name.length }} characters allowed</span
        >
      </div>
      <!-- <input
      formControlName="lastName"
      type="text"
      placeholder="Enter Last Name"
      class="form-control shadow-sm bg-body rounded"
      /> -->
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6">
      <label for="exampleFormControlInput1" class="form-label"
        >Phone Number</label
      >
      <input
        formControlName="phone"
        type="text"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Enter Phone Number"
        [class]="
          form.get('phone') &&
          form.get('phone').touched &&
          form.get('phone').invalid
            ? 'is-invalid'
            : ''
        "
      />
      <div
        class="invalid-feedback"
        *ngIf="
          form.get('phone') &&
          form.get('phone').touched &&
          form.get('phone').invalid
        "
      >
        <span *ngIf="form.get('phone').getError('required')"
          >Required Field</span
        >
      </div>
      <!-- <input
        type="text"
        formControlName="phone"
        placeholder="Enter Phone"
        class="form-control shadow-sm bg-body rounded"
      /> -->
    </div>
    <div class="col-12 col-sm-6 col-md-6">
      <label for="exampleFormControlInput1" class="form-label">Gender</label>
      <select
        formControlName="gender"
        class="form-control shadow-sm bg-body rounded"
        name="gender"
        [class]="
          form.get('gender') &&
          form.get('gender').touched &&
          form.get('gender').invalid
            ? 'is-invalid'
            : ''
        "
      >
        <option value="" selected disabled hidden>Select</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>
      <div
        class="invalid-feedback"
        *ngIf="
          form.get('gender') &&
          form.get('gender').touched &&
          form.get('gender').errors
        "
      >
        <span *ngIf="form.get('gender').getError('required')"
          >Required Field</span
        >
      </div>
    </div>
    <!-- <div class="col-12 col-sm-6 col-md-6">
      <label for="exampleFormControlInput1" class="form-label">Role</label>
      <select
        formControlName="userRole"
        class="form-control shadow-sm bg-body rounded"
        name="role"
        [class]="
          form.get('userRole') &&
          form.get('userRole').touched &&
          form.get('userRole').invalid
            ? 'is-invalid'
            : ''
        "
      >
        <option value="" selected disabled hidden>Select</option>
        <option *ngFor="let role of resRoles" [value]="role.id">
          {{ role.name }}
        </option>
      </select>
      <div
        class="invalid-feedback"
        *ngIf="
          form.get('userRole') &&
          form.get('userRole').touched &&
          form.get('userRole').errors
        "
      >
        <span *ngIf="form.get('userRole').getError('required')"
          >Required Field</span
        >
      </div>
    </div> -->
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6">
      <label for="exampleFormControlInput1" class="form-label">Location</label>
      <select
        formControlName="location"
        class="form-control shadow-sm bg-body rounded"
        name="address"
        [class]="
          form.get('location') &&
          form.get('location').touched &&
          form.get('location').invalid
            ? ' is-invalid'
            : ''
        "
      >
        <option value="" selected disabled hidden>Select</option>

        <option *ngFor="let location of resLocation" [value]="location.id">
          {{ location.location }}
        </option>
      </select>
      <div
        class="invalid-feedback"
        *ngIf="
          form.get('location') &&
          form.get('location').touched &&
          form.get('location').errors
        "
      >
        <span *ngIf="form.get('location').getError('required')"
          >Required Field</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button
        [disabled]="form.invalid"
        class="btn btn-danger w-25 float-right"
        (click)="submit()"
      >
        Submit
      </button>
    </div>
  </div>
</form>
