import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AdminRoutingModule } from "./admin-routing.module";
import { SharedModule } from "../shared/SharedModule";
import { AdminComponent } from "./admin.component";
import { UserTypesComponent } from "./user-management/components/user-types/user-types.component";
import { IncommingCallsComponent } from "./incomming-calls/incomming-calls.component";
import { ChangepasswordComponent } from "./setting/changepassword/changepassword.component";
import { EditprofileComponent } from "./setting/editprofile/editprofile.component";
import { UserLocationComponent } from "./user-management/components/user-location/user-location.component";
import { UserRightsComponent } from "./user-management/components/user-rights/user-rights.component";
import { PendingAndClosedCallsComponent } from "./consultations/pening-and-close-calls/pending-and-close.component";
import { ActiveCallsComponent } from "./consultations/active-calls/active-calls.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ViewAllUsersComponent } from "./user-management/components/view-all-users/view-all-users.component";
import { DoctorsPanelComponent } from "./doctors-panel/doctors-panel.component";
import { ReportsComponent } from "./user-management/components/reports/reports.component";

@NgModule({
  declarations: [
    DashboardComponent,
    AdminComponent,
    ViewAllUsersComponent,
    ActiveCallsComponent,
    UserTypesComponent,
    UserRightsComponent,
    UserLocationComponent,
    ChangepasswordComponent,
    EditprofileComponent,
    IncommingCallsComponent,
    PendingAndClosedCallsComponent,
    DoctorsPanelComponent,
    ReportsComponent,
  ],
  imports: [SharedModule, AdminRoutingModule],
})
export class AdminModule {}
