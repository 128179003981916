import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzTimePickerModule } from "ng-zorro-antd/time-picker";
import { TagInputModule } from "ngx-chips";
import { PaginationComponent } from "./pagination/pagination.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NgSelectModule } from "@ng-select/ng-select";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { IconsProviderModule } from "../icons-provider.module";
import { CoreModule } from "../admin/core/core.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
@NgModule({
  declarations: [PaginationComponent],
  imports: [ReactiveFormsModule, FormsModule, CommonModule, NzPaginationModule],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    NzDatePickerModule,
    NzTimePickerModule,
    TagInputModule,
    PaginationComponent,
    ClipboardModule,
    NgSelectModule,
    NzLayoutModule,
    NzMenuModule,
    CommonModule,
    FontAwesomeModule,
    CoreModule,
    IconsProviderModule,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
