import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminComponent } from "./admin.component";
import { ActiveCallsComponent } from "./consultations/active-calls/active-calls.component";
import { PendingAndClosedCallsComponent } from "./consultations/pening-and-close-calls/pending-and-close.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DoctorsPanelComponent } from "./doctors-panel/doctors-panel.component";
import { IncommingCallsComponent } from "./incomming-calls/incomming-calls.component";
import { ChangepasswordComponent } from "./setting/changepassword/changepassword.component";
import { EditprofileComponent } from "./setting/editprofile/editprofile.component";
import { UserLocationComponent } from "./user-management/components/user-location/user-location.component";
import { UserRightsComponent } from "./user-management/components/user-rights/user-rights.component";
import { UserTypesComponent } from "./user-management/components/user-types/user-types.component";
import { ViewAllUsersComponent } from "./user-management/components/view-all-users/view-all-users.component";
import { ReportsComponent } from "./user-management/components/reports/reports.component";

const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      { path: "dashboard", component: DashboardComponent },
      { path: "doctors-panel", component: DoctorsPanelComponent },
      { path: "consultations", component: PendingAndClosedCallsComponent },
      { path: "closed-calls", component: PendingAndClosedCallsComponent },
      { path: "active-calls", component: ActiveCallsComponent },
      { path: "incomming-calls", component: IncommingCallsComponent },
      { path: "incomming-calls-closed", component: IncommingCallsComponent },
      { path: "users", component: ViewAllUsersComponent },
      { path: "role-permissions", component: UserRightsComponent },
      { path: "role-permissions/view/:id", component: UserRightsComponent },
      { path: "role-permissions/edit/:id", component: UserRightsComponent },
      { path: "roles", component: UserTypesComponent },
      { path: "user-location", component: UserLocationComponent },
      { path: "user-reports", component: ReportsComponent },
      { path: "edit-profile", component: EditprofileComponent },
      { path: "change-password", component: ChangepasswordComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
