import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpService) {}

  feedbackCounts(category) {
    let paramObject: any = {
      category: category != "" ? category : null,
    };
    const paramQ = this.http.createHttpParams(paramObject);
    return this.http.get(`dashboard/feedbacks-reviews`, paramQ);
  }

  consultationDetail(startDate, endDate, category) {
    let paramObject: any = {
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
      category: category != "" ? category : null,
    };
    const paramQ = this.http.createHttpParams(paramObject);
    return this.http.get(`dashboard/consultations-details`, paramQ);
  }

  applicationReview(startDate, endDate, category) {
    let paramObject: any = {
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
      category: category != "" ? category : null,
    };
    const paramQ = this.http.createHttpParams(paramObject);

    return this.http.get(`dashboard/application-ratings`, paramQ);
  }
}
