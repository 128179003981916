<div class="container">
  <div class="row">
    <div class="col-12">
      <h3 id="heading">Add <span> User Location </span></h3>
      <span class="close-modal-btn" (click)="close()">
        <fa-icon [icon]="xmark" aria-hidden="true"></fa-icon>
      </span>

      <div>
        <hr class="borderdec" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input
        type="text"
        class="form-control shadow-sm bg-body rounded"
        placeholder="Type Location"
        [formControl]="location"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button
        class="btn btn-danger btn-lg align-content-center"
        (click)="submit()"
      >
        {{ id ? "Update Location" : "Add Location" }}
      </button>
    </div>
  </div>
</div>
