import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AddUserLocationComponent } from "./AddUserLocation/AddUserLocation.component";
import { AddUserTypeComponent } from "./AddUserType/AddUserType.component";
import { AgentExtensionComponent } from "./AgentExtension/AgentExtension.component";
import { AddUserComponent } from "./AddUser/AddUser.component";
import { FormModalComponent } from "./form-modal/form-modal.component";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { ConfirmationModalComponent } from "./confirmation-modal/confirmation-modal.component";
import { SharedModule } from "src/app/shared/SharedModule";
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { AssignAgentComponent } from './assign-agent/assign-agent.component';

@NgModule({
  declarations: [
    AddUserComponent,
    AddUserLocationComponent,
    AddUserTypeComponent,
    AgentExtensionComponent,
    FormModalComponent,
    ConfirmationModalComponent,
    EditPasswordComponent,
    AssignAgentComponent,
  ],
  imports: [NzCollapseModule, SharedModule],
})
export class ModalModule {}
