export const formateDateandTime = (valid: string, value: Date) => {
  if (!value) {
    return "-";
  }
  if (valid == "date") {
    return new Date(value).toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }
  if (valid == "time") {
    return new Date(value).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  }
};

export const formateToTimeStamp = (date: Date): number => {
  let timeStampDate = new Date(date).getTime();
  return timeStampDate;
};

export const dateTofirstDay = (param: {
  type: "timeStamp" | "string";
  date: Date;
  day: number;
}): number | Date => {
  if (param.type === "timeStamp") {
    const firstDayOfMonth = new Date(new Date(param.date).setDate(param.day));
    return firstDayOfMonth.getTime();
  }
  if (param.type === "string") {
    const firstDayOfMonth = new Date(new Date(param.date).setDate(param.day));
    return firstDayOfMonth;
  }
};

export const formatePayload = (filters: {}) => {
  let payload = {};
  Object.keys(filters).map((d: any) => {
    if (Array.isArray(filters[d]) && filters[d].length != 0) {
      payload[d] = filters[d];
      return;
    }
    if (filters[d]) {
      payload[d] = filters[d];
      return;
    }
  });

  return payload;
};

export const convertDignosisToString = (diagnosis) => {
  if (!diagnosis) {
    return "-";
  }
  diagnosis = diagnosis.map((d) => d).join(" | ");
  return diagnosis || "-";
};

export const filterFeedbackAnswer = (answers: any, id: string) => {
  let rtrn: string;
  answers.map((d) => {
    if (d.questionId == id) {
      rtrn = d.openAnswer;
    }
  });
  return rtrn || "-";
};
