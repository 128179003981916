import {
  convertDignosisToString,
  filterFeedbackAnswer,
  formateDateandTime,
} from "./helper";
import { iNgSelect } from "./interface";

export const consultationType: iNgSelect[] = [
  { value: "Appointment", title: "Appointment" },
  { value: "Instant", title: "Instant" },
];

export const consultationStatus: iNgSelect[] = [
  { value: "Missed", title: "Missed" },
  { value: "Consulted", title: "Consulted" },
  { value: "Cancelled", title: "Cancelled" },
  { value: "Rejected", title: "Rejected" },
  { value: "Dropped", title: "Dropped" },
];

export const platform: iNgSelect[] = [
  { value: "Retail", title: "Retail" },
  { value: "Corp", title: "Corp" },
];

export const feedbackType: iNgSelect[] = [
  { value: "pending", title: "Pending Feedback" },
  { value: "closed", title: "Successfull Feedback" },
];

export const exportLimit: iNgSelect[] = [
  { title: "Export Current Data", value: "Export Current Data" },
  { title: "Export 1000 Rows", value: "Export 1000 Rows" },
];

export const consultationformateExportData = (reponse) => {
  let status: any = {};
  reponse.data.map(async (consultation: any) => {
    let consultationDetail: {} = {
      "Patient Name": consultation.patientName || "-",
      "Patient Phone": consultation.patientPhone || "-",
      "Doctor Name": consultation.doctorName || "-",
      "Doctor Phone": consultation.doctorPhone || "-",
      "Consultation Status": consultation.status || "-",
      "Feedback Status": consultation.callStatus || "-",
      Duration: consultation.duration || "-",
      Gender: consultation.gender || "-",
      "Consultation Date": consultation.consultationDate || "-",
      "Consultation Time": consultation.consultationTime || "-",
      Address: consultation.address || "-",
      Diagnosis: convertDignosisToString(consultation.diagnosis) || "-",
      Amount: consultation.amount || "-",
      "Partner App": consultation.partnerApp || "-",
      companyName: consultation.companyName || "-",
      "Payment Method": consultation.paymentMethod || "-",
      "Consultation Type": consultation.type || "-",
    };

    if (consultation.callHistories.length == 0) {
      let extentConsultationDetail = { Attempt: 0, ...consultationDetail };
      switch (consultation.status) {
        case "Missed":
          if (!status.Missed) {
            status.Missed = [];
          }
          status.Missed = [];
          status.Missed.push(extentConsultationDetail);
          break;
        case "Rejected":
          if (!status.Rejected) {
            status.Rejected = [];
          }
          status.Rejected.push(extentConsultationDetail);
          break;
        case "Consulted":
          if (!status.Consulted) {
            status.Consulted = [];
          }
          status.Consulted.push(extentConsultationDetail);
          break;
        case "Cancelled":
          if (!status.Cancelled) {
            status.Cancelled = [];
          }
          status.Cancelled.push(extentConsultationDetail);
          break;
        case "Dropped":
          if (!status.Dropped) {
            status.Dropped = [];
          }
          status.Dropped.push(extentConsultationDetail);
          break;
      }
    }

    if (consultation.callHistories.length != 0) {
      consultation.callHistories.map(async (d: any, i: number) => {
        switch (i) {
          case 0:
            consultationDetail = {
              Attempt: consultation.numberOfAttempts,
              ...consultationDetail,
            };
            break;
          case 1:
            consultationDetail = {
              Attempt: consultation.numberOfAttempts - 1,
            };
            break;
          case 2:
            consultationDetail = {
              Attempt: consultation.numberOfAttempts - 2,
            };
            break;
        }
        switch (consultation.status) {
          case "Missed":
            if (!status.Missed) {
              status.Missed = [];
            }
            let missed: any;
            missed = {
              ...consultationDetail,
              "Agent Name": d.agentName,
              "Feedback Date": formateDateandTime("date", d.createdAt) || "-",
              "Feedback Time": formateDateandTime("time", d.createdAt) || "-",
              "Doctor's Call Status": filterFeedbackAnswer(d.answers, "CA"),
              "Remarks of doctor": filterFeedbackAnswer(d.answers, "CB"),
              "Remarks by agent": filterFeedbackAnswer(d.answers, "CC"),
              Remarks: filterFeedbackAnswer(d.answers, "CD"),
              "Patient's call status": filterFeedbackAnswer(d.answers, "CE"),
              "Patient's comments": filterFeedbackAnswer(d.answers, "CF"),
              "Action by agent": filterFeedbackAnswer(d.answers, "CG"),
              "Remarks by Patient": filterFeedbackAnswer(d.answers, "CH"),
            };
            status.Missed.push(missed);
            break;
          case "Rejected":
            if (!status.Rejected) {
              status.Rejected = [];
            }
            let rejected: any;
            rejected = {
              ...consultationDetail,
              "Agent Name": d.agentName,
              "Feedback Date": formateDateandTime("date", d.createdAt) || "-",
              "Feedback Time": formateDateandTime("time", d.createdAt) || "-",
              "Doctor's Call Status": filterFeedbackAnswer(d.answers, "DA"),
              "Remarks of doctor": filterFeedbackAnswer(d.answers, "DB"),
              "Remarks by agent": filterFeedbackAnswer(d.answers, "DC"),
              Remarks: filterFeedbackAnswer(d.answers, "DD"),
              "Patient's call status": filterFeedbackAnswer(d.answers, "DE"),
              "Patient's comments": filterFeedbackAnswer(d.answers, "DF"),
              "Action by agent": filterFeedbackAnswer(d.answers, "DG"),
              "Remarks by Patient": filterFeedbackAnswer(d.answers, "DH"),
            };
            status.Rejected.push(rejected);

            break;
          case "Dropped":
            if (!status.Dropped) {
              status.Dropped = [];
            }
            let dropped: any;
            dropped = {
              ...consultationDetail,
              "Agent Name": d.agentName,
              "Feedback Date": formateDateandTime("date", d.createdAt) || "-",
              "Feedback Time": formateDateandTime("time", d.createdAt) || "-",
              "Call Status": filterFeedbackAnswer(d.answers, "BA"),
              "Patient`s Comments": filterFeedbackAnswer(d.answers, "BB"),
              "Action By Agent": filterFeedbackAnswer(d.answers, "BC"),
              Remarks: filterFeedbackAnswer(d.answers, "BD"),
            };
            status.Dropped.push(dropped);

            break;
          case "Cancelled":
            if (!status.Cancelled) {
              status.Cancelled = [];
            }
            let cancelled: any;
            cancelled = {
              ...consultationDetail,
              "Agent Name": d.agentName,
              "Feedback Date": formateDateandTime("date", d.createdAt),
              "Feedback Time": formateDateandTime("time", d.createdAt),
              "Call Status": filterFeedbackAnswer(d.answers, "AA"),
              "Patient`s Comments": filterFeedbackAnswer(d.answers, "AB"),
              "Action By Agent": filterFeedbackAnswer(d.answers, "AC"),
              Remarks: filterFeedbackAnswer(d.answers, "AD"),
            };
            status.Cancelled.push(cancelled);
            break;
          case "Consulted":
            if (!status.Consulted) {
              status.Consulted = [];
            }
            let consulted: any;
            consulted = {
              ...consultationDetail,
              "Agent Name": d.agentName,
              "Feedback Date": formateDateandTime("date", d.createdAt) || "-",
              "Feedback Time": formateDateandTime("time", d.createdAt) || "-",
              "Call Status": filterFeedbackAnswer(d.answers, "EA"),
              "Patient`s Comments": filterFeedbackAnswer(d.answers, "EB"),
              "Overall rating for app": filterFeedbackAnswer(d.answers, "EC"),
              "User friendliness": filterFeedbackAnswer(d.answers, "ED"),
              "Audio, Video quality on app": filterFeedbackAnswer(
                d.answers,
                "EE"
              ),
              "Internet connectivity on application": filterFeedbackAnswer(
                d.answers,
                "EF"
              ),
              "Consultation duration": filterFeedbackAnswer(d.answers, "EG"),
              "Appointment scheduling": filterFeedbackAnswer(d.answers, "EH"),
              "Overall rating for Doctor": filterFeedbackAnswer(
                d.answers,
                "EI"
              ),
              "History taking & counselling": filterFeedbackAnswer(
                d.answers,
                "EJ"
              ),
              "Prescription provided by doctor": filterFeedbackAnswer(
                d.answers,
                "EK"
              ),
              "Doctors behaviour": filterFeedbackAnswer(d.answers, "EL"),
              "Remarks for Doctor": filterFeedbackAnswer(d.answers, "EM"),
              "Remarks by agent": filterFeedbackAnswer(d.answers, "EN"),
            };
            status.Consulted.push(consulted);
            break;
        }
      });
    }
  });

  return status;
};
