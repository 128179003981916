<div class="container-fluid">
  <form [formGroup]="form" (ngSubmit)="applyFilter()">
    <div class="row">
      <div class="col" [ngClass]="">
        <label for="">From</label>
        <nz-date-picker
          formControlName="start"
          [(ngModel)]="startDate"
          class="form-control shadow-sm bg-body rounded"
        ></nz-date-picker>
      </div>
      <div class="col" [ngClass]="">
        <label for="">TO</label>
        <nz-date-picker
          formControlName="end"
          [(ngModel)]="endDate"
          class="form-control shadow-sm bg-body rounded"
          open="true"
        ></nz-date-picker>
      </div>
      <div class="col">
        <label style="color: white" for="">Patient Phone Number</label>
        <input
          class="form-control shadow-sm bg-body rounded"
          type="number"
          placeholder="Patient Phone Number"
          formControlName="patientNumber"
        />
      </div>
      <div class="col">
        <label style="color: white" for="">Consultation Type</label>

        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="title"
          placeholder="Consultation Type"
          [items]="consultationType"
          formControlName="consultationType"
        >
        </ng-select>
      </div>

      <div class="col">
        <label style="color: white" for="">Consultation Type</label>
        <button type="submit" class="btn btn-danger w-100">Apply Filter</button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="title"
          placeholder="Platform"
          [items]="partners"
          formControlName="partner"
        >
        </ng-select>
      </div>
      <div class="col">
        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="title"
          placeholder="Consultation Status"
          [items]="callType"
          [multiple]="true"
          [ngModel]="queryFilter"
          formControlName="consultationStatus"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
              <span class="ng-value-label">{{ item.title }}</span>
              <span
                class="ng-value-icon right"
                (click)="clear(item)"
                aria-hidden="true"
                >×</span
              >
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
              <span class="ng-value-label">{{ items.length - 1 }} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col">
        <!-- <label style="color: white" for="">Doctor</label> -->
        <input
          class="form-control shadow-sm bg-body rounded"
          type="text"
          placeholder="Search by doctor"
          formControlName="doctor"
        />
      </div>

      <div class="col">
        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="value"
          [items]="exportLimit"
          placeholder="Export Limit"
          formControlName="exportCount"
          (change)="setExportLimit($event)"
        >
        </ng-select>
      </div>

      <div class="col" *ngIf="route === 'closed-calls'">
        <ng-select
          class="form-control shadow-sm bg-body rounded"
          bindLabel="title"
          bindValue="value"
          placeholder="Agent Name"
          [items]="agentName"
          [multiple]="true"
          [closeOnSelect]="false"
          formControlName="agentId"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngIf="items.length > 0">
              <span class="ng-value-label">selected {{ items.length }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div
        class="col"
        *ngIf="
          route === 'consultations' &&
          this.user?.userRole?.userRole?.name === 'admin'
        "
      >
        <button
          type="button"
          class="btn btn-danger w-100"
          (click)="sortConsultation()"
        >
          Assign Consultation
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-9">
        <label for=""> Total Count : {{ totalCount }} </label>
      </div>
      <div class="col-3">
        <div class="col">
          <img
            src="/assets/images/excel.ico"
            class="excelStyles float-right"
            height="35px"
            (click)="excelExport()"
          />
        </div>
      </div>
    </div>
  </form>

  <div id="table_div" [ngClass]="resData == undefined ? 'fixed' : 'scroll'">
    <table class="table table-hover table-borderless">
      <thead>
        <tr style="background-color: #7cc707 !important">
          <td
            *ngFor="let item of tableHeader"
            [ngStyle]="{
              'min-width':
                route != 'closed-calls' && item == 'Action' ? '230px' : ''
            }"
          >
            {{ item }}
          </td>
        </tr>
      </thead>
      <tbody class="mt-4">
        <tr
          *ngFor="let item of allconsultations"
          class="mt-4 shadow-sm bg-body rounded"
        >
          <td>
            <div id="name">
              <div
                class="name-field"
                [ngClass]="{ 'name-box': route !== 'closed-calls' }"
              >
                <p id="title">
                  {{
                    item.patientName != undefined || item.patientName != null
                      ? item.patientName
                      : "-"
                  }}
                </p>
                <p>
                  {{
                    item.patientPhone != undefined || item.patientPhone != null
                      ? item.patientPhone
                      : "-"
                  }}
                </p>
              </div>
              <div id="phone-Box" class="name-field" *ngIf="!item.closed">
                <img
                  (click)="
                    CallModal(
                      item.status,
                      item.id,
                      item.patientPhone || item.patients.phone,
                      item.numberOfAttempts,
                      item.doctorPhone,
                      item.consultationId,
                      item.consultationDate,
                      item.closed,
                      'patient'
                    )
                  "
                  id="phone-icon"
                  [src]="
                    imgSrcDoctor == 'green'
                      ? 'assets/images/Calling-1.png'
                      : 'assets/images/Calling-2.png'
                  "
                  style="width: 30px; height: 30px"
                  alt=""
                  class="float-right mr-4 callbtn"
                />
              </div>
            </div>
          </td>
          <td>
            <div id="name">
              <div
                class="name-field"
                [ngClass]="{ 'name-box': route !== 'closed-calls' }"
              >
                <p id="title">
                  {{
                    item.doctorName != undefined || item.doctorName != null
                      ? item.doctorName
                      : "-"
                  }}
                </p>
                <p>
                  {{
                    item.doctorPhone != undefined || item.doctorPhone != null
                      ? item.doctorPhone
                      : "-"
                  }}
                </p>
              </div>
              <div id="phone-Box" class="name-field" *ngIf="!item.closed">
                <img
                  id="phone-icon"
                  (click)="
                    CallModal(
                      item.status,
                      item.id,
                      item.patientPhone || item.patients.phone,
                      item.numberOfAttempts,
                      item.doctorPhone,
                      item.consultationId,
                      item.consultationDate,
                      item.closed,
                      'doctor'
                    )
                  "
                  [src]="
                    imgSrcDoctor == 'green'
                      ? 'assets/images/Calling-1.png'
                      : 'assets/images/Calling-2.png'
                  "
                  style="width: 30px; height: 30px"
                  alt=""
                  class="float-right mr-4 callbtn"
                />
              </div>
            </div>
          </td>
          <td>{{ item.companyName || "-" }}</td>
          <td>
            {{ item.status || "-" }}
          </td>
          <td>{{ item.numberOfAttempts }}</td>
          <td>
            <div>
              <p>
                {{ item.callStatus || "-" }}
              </p>
            </div>
          </td>
          <td>
            <button
              *ngIf="
                item.callStatus === 'Pending' || item.numberOfAttempts != 0
              "
              (click)="
                CallModal(
                  item.status,
                  item.id,
                  item.patientPhone || item.patients.phone,
                  item.numberOfAttempts,
                  item.doctorPhone,
                  item.consultationId,
                  item.consultationDate,
                  item.closed,
                  'patient'
                )
              "
              id="feedbackBtn"
              class="btn btn-small align-content-center"
              [disabled]="!item.closed && item.numberOfAttempts == 3"
            >
              {{ !item.closed ? "Take Feedback" : "View Feedback" }}
            </button>
            <button
              (click)="
                closeConsultation(
                  item.id,
                  false,
                  item.patientId,
                  item.doctorId,
                  item.patientName,
                  item.doctorName
                )
              "
              id="feedbackBtn"
              class="btn btn-small btn-danger ml-2"
              [ngStyle]="{ display: item.closed ? 'none' : '' }"
            >
              close
            </button>
            <p
              *ngIf="item.callStatus === 'Closed' && item.numberOfAttempts == 0"
            >
              -
            </p>
          </td>
          <td *ngIf="route === 'closed-calls'">
            {{ (item.callHistories[0]?.createdAt | date : "medium") || "-" }}
          </td>
          <td>
            {{
              item.user
                ? item?.user?.firstName + " " + item?.user?.lastName
                : "-"
            }}
          </td>
          <td *ngIf="route === 'closed-calls'">
            {{ item?.callHistories[0]?.agentName || "-" }}
          </td>
          <td>
            {{ item.duration || "-" }}
          </td>
          <td>
            {{ item.gender || "-" }}
          </td>
          <td>
            <p id="title">
              {{ item.consultationDate || "-" }}
            </p>
            <p>{{ item.consultationTime || "-" }}</p>
          </td>
          <td>
            {{ convertDignosisToString(item.diagnosis) }}
          </td>
          <td>
            {{ item.address || "-" }}
          </td>
          <td>
            {{ item.amount || "-" }}
          </td>
          <td>
            {{ item.type || "-" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-center field-loader" *ngIf="resData === undefined">
    <img src="assets/images/SK-Logo-Animation.gif" alt="" />
  </div>
  <app-pagination
    (pageNumber)="requestPaginate($event)"
    [totalCount]="totalCount"
  ></app-pagination>
</div>
<!-- </main> -->
