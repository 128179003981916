import { IDropDown, IFedbackField } from "../interface";

export const CallStatus: IDropDown[] = [
  { value: "R1", label: "Received" },
  { value: "R2", label: "Not Answering" },
  {
    value: "R3",
    label: "User is busy on another call",
  },
  { value: "R4", label: "User cut the call" },
  { value: "R5", label: "Invalid number" },
  { value: "R6", label: "International number" },
  { value: "R7", label: "Powered off" },
  { value: "R8", label: "Not reachable" },
  { value: "R9", label: "Extension number provided" },
  { value: "R10", label: "Other" },
];
export const missedCallStatus: IDropDown[] = [
  { value: "R1", label: "Received" },
  { value: "R2", label: "Not Answering" },
  {
    value: "R3",
    label: "User is busy on another call",
  },
  { value: "R4", label: "User cut the call" },
  { value: "R5", label: "Invalid number" },
  { value: "R6", label: "International number" },
  { value: "R7", label: "Powered off" },
  { value: "R8", label: "Not reachable" },
  { value: "R9", label: "Extension number provided" },
  { value: "R10", label: "Other" },
];
export const rejectedCallStatus: IDropDown[] = [
  { value: "O1", label: "Received" },
  { value: "O2", label: "Not Answering" },
  {
    value: "O3",
    label: "User is busy on another call",
  },
  { value: "O4", label: "User cut the call" },
  { value: "O5", label: "Invalid number" },
  { value: "O6", label: "International number" },
  { value: "O7", label: "Powered off" },
  { value: "O8", label: "Not reachable" },
  { value: "O9", label: "Extension number provided" },
  { value: "O10", label: "Other" },
];
export const MissedCallStatus: IDropDown[] = [
  { value: "I1", label: "Received" },
  { value: "I2", label: "Not Answering" },
  {
    value: "I3",
    label: "User is busy on another call",
  },
  { value: "I4", label: "User cut the call" },
  { value: "I5", label: "Invalid number" },
  { value: "I6", label: "International number" },
  { value: "I7", label: "Powered off" },
  { value: "I8", label: "Not reachable" },
  { value: "I9", label: "Extension number provided" },
  { value: "I10", label: "Other" },
];
export const AgentAction: IDropDown[] = [
  {
    value: "N1",
    label: "Reconnected patient with the doctor",
  },
  {
    label: "Connected patient with a relevant doctor",
    value: "N2",
  },
  {
    label: "Guided patient towards helpline number",
    value: "N3",
  },
  {
    label: "Guided to book an appointment",
    value: "N4",
  },
  {
    value: "N5",
    label: "Agent booked an appointment for patient",
  },
  {
    value: "N6",
    label: "Connected via helpline",
  },
  { value: "N7", label: "Other" },
];
export const rejectedAgentAction: IDropDown[] = [
  {
    value: "N1",
    label: "Reconnected patient with the doctor",
  },
  {
    label: "Connected patient with a relevant doctor",
    value: "N2",
  },
  {
    label: "Guided patient towards helpline number",
    value: "N3",
  },
  {
    label: "Guided to book an appointment",
    value: "N4",
  },
  {
    value: "N5",
    label: "Agent booked an appointment for patient",
  },
  {
    value: "N6",
    label: "Connected via helpline",
  },
  { value: "N7", label: "Other" },
];
export const ReasonForMissedConsultation: IDropDown[] = [
  { value: "Notification issue", label: "Notification issue" },
  { value: "Application crashed", label: "Application crashed" },
  { value: "Internet issues", label: "Internet issues" },
  {
    value: "Not attentive at the moment",
    label: "Not attentive at the moment",
  },
  { value: "Busy with another patient", label: "Busy with another patient" },
  { value: "Other comment", label: "Other comment" },
  {
    value: "Not online yet received a request",
    label: "Not online yet received a request",
  },
];
export const rejectedReasonForMissedConsultation: IDropDown[] = [
  { value: "P1", label: "Notification issue" },
  { value: "P2", label: "Application crashed" },
  { value: "P3", label: "Internet issues" },
  {
    value: "P4",
    label: "Not attentive at the moment",
  },
  { value: "P4", label: "Busy with another patient" },
  { value: "P5", label: "Other comment" },
  {
    value: "P6",
    label: "Not online yet received a request",
  },
];
export const missedReasonForMissedConsultation: IDropDown[] = [
  { value: "J1", label: "Notification issue" },
  { value: "J2", label: "Application crashed" },
  { value: "J3", label: "Internet issues" },
  {
    value: "J4",
    label: "Not attentive at the moment",
  },
  { value: "J5", label: "Busy with another patient" },
  { value: "Other comment", label: "Other comment" },
  {
    value: "J6",
    label: "Not online yet received a request",
  },
];
export const DoctorAdditionalComments: IDropDown[] = [
  {
    value: "M1",
    label: "Doctor is not responding on the app",
  },
  {
    value: "M2",
    label: "Doctor is not available at my appointment time",
  },
  {
    value: "M3",
    label: "Doctor took too long to respond",
  },
  {
    value: "M4",
    label: "Doctor was busy with another consultation",
  },
  { value: "M5", label: "Other comment by user" },
  {
    value: "M6",
    label: "Internet connectivity issues on patient`s side",
  },
  {
    value: "M7",
    label:
      "Family member must be consulting, I don`t know about their consultation",
  },
];
export const YesNo: IDropDown[] = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Other", label: "Other" },
];
export const CancelledConsultationAdditionalComments: IDropDown[] = [
  {
    value: "F1",
    label: "Doctor took too long to respond",
  },
  { value: "F2", label: "Testing the application" },
  {
    value: "F3",
    label: "Application crashed in between",
  },
  {
    value: "F4",
    label: "Got busy somewhere will consult later",
  },
  {
    value: "F5",
    label:
      "Family member must be consulting, I don`t know about their consultation",
  },
  {
    value: "F6",
    label: "Got connected to  a wrong doctor",
  },
  {
    value: "F7",
    label: "Doctor was busy with another patient",
  },
  {
    value: "F8",
    label: "Internet connectivity issues on patient`s side",
  },
  { value: "F9", label: "Other comment by user" },
];

export const DroppedAdditionalComments: IDropDown[] = [
  {
    value: "Consultation was completed and good experience",
    label: "Consultation was completed and good experience",
  },
  {
    value: "Incomplete consultation would like to connect again",

    label: "Incomplete consultation would like to connect again",
  },
  {
    value: "Incomplete consultation, got busy now will consult later",

    label: "Incomplete consultation, got busy now will consult later",
  },
  {
    value: "Consultation was completed priscription is missing",

    label: "Consultation was completed priscription is missing",
  },
  {
    value: "Internet connectivity issue on patinet`s side, will consult later",

    label: "Internet connectivity issue on patinet`s side, will consult later",
  },
  {
    value: "Internet connectivity issue on doctor`s side, will consult later",

    label: "Internet connectivity issue on doctor`s side, will consult later",
  },
  {
    value: "Other comment by the user",

    label: "Other comment by the user",
  },
];
export const ConsultationChannel: IDropDown[] = [
  { label: "Audio call", value: "Audio call" },
  { label: "Video call", value: "Video call" },
  { label: "Live chat", value: "Live chat" },
  { label: "All", value: "All" },
];

export const usageBy: IDropDown[] = [
  { value: "My self", label: "My self" },
  { value: "Family", label: "Family" },
  { value: "All", label: "All" },
  { value: "Other", label: "Other" },
];

export const OverallAppRating: IFedbackField = {
  label: "Overall App Rating",
  placeholder: "Select Rating",
  questionId: "AB",
  type: "select",
  options: [
    { value: "App Rating", label: "App Rating" },
    { value: "Excellent", label: "Excellent" },
    { value: "Good", label: "Good" },
    { value: "Fair", label: "Fair" },
    { value: "Neutral", label: "Neutral" },
    { value: "Unsatisfactory", label: "Unsatisfactory" },
  ],
};
export const AdditionalComments: IDropDown[] = [
  {
    value: "Good experience with application and doctor",
    label: "Good experience with application and doctor",
  },
  {
    value: "Good experience with doctor, application needs to be fixed",
    label: "Good experience with doctor, application needs to be fixed",
  },
  {
    value: "Good experience with application but doctor was not responsive",
    label: "Good experience with application but doctor was not responsive",
  },
  {
    value: "Voice and connectivity issues on application",
    label: "Voice and connectivity issues on application",
  },
  {
    value: "Voice and connectivity issues on application but good experience",
    label: "Voice and connectivity issues on application but good experience",
  },
  {
    value: "Family member have consulted and their experience was good",
    label: "Family member have consulted and their experience was good",
  },
  {
    value: "Family member have consulted and I don`t know about their feedback",
    label: "Family member have consulted and I don`t know about their feedback",
  },
  {
    value: "Doctor was not responding on application",
    label: "Doctor was not responding on application",
  },
  {
    value: "Application crashed in middle of consultation",
    label: "Application crashed in middle of consultation",
  },
  {
    value: "Patient was referred to another doctor",
    label: "Patient was referred to another doctor",
  },
  {
    value: "Other suggestion by the user",
    label: "Other suggestion by the user",
  },
];
export const ratings: IDropDown[] = [
  { value: "Excellent", label: "Excellent" },
  { value: "Good", label: "Good" },
  { value: "Fair", label: "Fair" },
  { value: "Neutral", label: "Neutral" },
  { value: "Unsatisfactory", label: "Unsatisfactory" },
];
