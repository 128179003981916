import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { paginate } from "../enums/paginate";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class AllUserService {
  constructor(private http: HttpService) {}

  async getAllUsers(parms?: any) {
    const param = {
      limit: parms?.limit || paginate.defaultLimit,
      offset: parms?.offset || paginate.defaultOffset,
    };
    const option = this.http.createHttpParams(param);
    const apiResponse = await this.http.get(`admin/get-all`, option);
    return apiResponse;
  }

  async getUserbyDate(params?: any) {
    const param = {
      limit: params.limit || paginate.defaultLimit,
      offset: params.offset || paginate.defaultOffset,
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.search,
    };
    const option = this.http.createHttpParams(param);
    const apiResponse = await this.http.get(`admin/get-all`, option);
    return apiResponse;
  }

  async updateUserPasswordByAdmin(params: any) {
    const param = {
      userId: params.userId,
      newPassword: params.password,
      confirmPassword: params.confirm,
    };

    let options = this.http.createHttpParams(param);
    const data = await this.http.put(`admin/update-password`, options);
    return data;
  }
  async deleteUserByAdmin(params: any) {
    const param: any = {
      userId: params,
    };

    let options = this.http.createHttpParams(param);
    const data = await this.http.delete(`admin/delete-user`, param);
    return data;
  }
}
