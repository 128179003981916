import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class userTypeService {
  constructor(private http: HttpService) {}

  async getAllTypes() {
    return await this.http.get(`roles`);
  }
  editTypes(id: string, agent: string) {
    let body = {
      name: agent,
    };
    return this.http.put(`roles/${id}`, body);
  }
  addType(agent: string) {
    let body = {
      name: agent,
    };
    return this.http.post(`roles`, body);
  }
}
