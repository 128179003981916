<div class="container-fluid">
  <form [formGroup]="filter" class="row">
    <div class="col-sm-12 col-md-6 col-lg-3">
      <label for="">Doctor</label>
      <input
        class="form-control shadow-sm bg-body rounded"
        type="text"
        placeholder="Search doctor"
        formControlName="doctor"
      />
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3">
      <label for="">Platform</label>
      <!-- <tag-input
        class="form-control shadow-sm bg-body rounded"
        secondaryPlaceholder="Exter Text"
        [ngModel]="[]"
        [onlyFromAutocomplete]="true"
        [maxItems]="1"
        formControlName="partner"
      >
        <tag-input-dropdown
          [showDropdownIfEmpty]="true"
          [autocompleteItems]="partners"
        >
          <ng-template let-item="item">
            {{ item.display }}
          </ng-template>
        </tag-input-dropdown>
      </tag-input> -->
      <ng-select
        class="form-control shadow-sm bg-body rounded"
        bindLabel="title"
        bindValue="title"
        placeholder="Select"
        [items]="partners"
        formControlName="partner"
      >
      </ng-select>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3">
      <label for="">Consultation Type</label>
      <!-- <tag-input
        class="form-control shadow-sm bg-body rounded"
        secondaryPlaceholder="Exter Text"
        [ngModel]="[]"
        [onlyFromAutocomplete]="true"
        [maxItems]="1"
        formControlName="consultationType"
      >
        <tag-input-dropdown
          [showDropdownIfEmpty]="true"
          [autocompleteItems]="type"
        >
          <ng-template let-item="item">
            {{ item.display }}
          </ng-template>
        </tag-input-dropdown>
      </tag-input> -->
      <ng-select
        class="form-control shadow-sm bg-body rounded"
        bindLabel="title"
        bindValue="title"
        placeholder="Select"
        [items]="type"
        formControlName="consultationType"
      >
      </ng-select>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3">
      <button class="btn btn-danger filter-btn" (click)="getValue()">
        Apply Filter
      </button>
    </div>
  </form>
  <div class="row mt-3">
    <div class="col-sm-12">
      <label for=""> Total Count : {{ totalCount }} </label>
    </div>
  </div>
  <div id="table_div" class="container-fluid mt-3 scroll">
    <table class="table table-hover table-borderless">
      <thead>
        <tr>
          <td *ngFor="let item of tableHeader">
            {{ item }}
          </td>
        </tr>
      </thead>
      <tbody class="mt-4">
        <tr
          *ngFor="let item of allconsultations"
          class="mt-4 shadow-sm bg-body rounded"
        >
          <!-- <td style="width: 10px">{{ item.id }}</td> -->

          <td>
            <div id="name">
              <div id="name-box" class="name-field">
                <p id="title">
                  {{ item.patientName || "-" }}
                </p>
                <p>
                  {{ item.patientPhone || "-" }}
                </p>
              </div>
            </div>
          </td>

          <td>
            <div id="name">
              <div id="name-box" class="name-field">
                <p id="title">{{ item.doctorName || "-" }}</p>
                <p>{{ item.doctorPhone || "-" }}</p>
              </div>
            </div>
          </td>
          <td>{{ item.status || "-" }}</td>
          <td>
            {{ item.callStatus || "-" }}
          </td>

          <td>{{ item.gender || "-" }}</td>
          <!-- <td></td> -->
          <td>{{ (dateFormate(item.createdAt, "date") | date) || "-" }}</td>
          <td>{{ dateFormate(item.createdAt, "time") || "-" }}</td>
          <td>
            {{ item.address || "-" }}
          </td>
          <td>{{ item.amount || "-" }}</td>
          <td>{{ item.partnerApp || "-" }}</td>
          <td>{{ item.companyName || "-" }}</td>
          <td>
            {{ item.paymentMethod || "-" }}
          </td>
          <td>{{ item.type || "-" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-pagination
  (pageNumber)="requestPaginate($event)"
  [totalCount]="totalCount"
></app-pagination>
