import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { IncommingCallsService } from "../core/services/incommingCalls.service";
import { ModalService } from "../core/services/modal.service";
import { ToasterService } from "../core/services/toaster.service";
import { ExcelService } from "../core/services/xlsx.service";
import { FormModalComponent } from "../modals/form-modal/form-modal.component";
import { formateDateandTime } from "src/app/shared/helper";

@Component({
  selector: "app-incomming-calls",
  templateUrl: "./incomming-calls.component.html",
  styleUrls: ["./incomming-calls.component.scss"],
})
export class IncommingCallsComponent implements OnInit {
  filterForm: any;
  tableHeader = [
    "Name",
    "Phone number",
    "Category",
    "Company name",
    "Agent name",
    "Remarks",
    "Case Catering Department",
    "Remarks / Details of caller",
    "Case close time",
    "Date",
    "Registered via",
    "Action",
  ];

  startDate: string | number | Date;
  endDate: string | number | Date;
  filters: any = {};
  tableData: any = [];
  totalCount: any = 0;
  route = this.activeRoute.snapshot.routeConfig.path;
  resData: any = [];
  exportCount: any = this.totalCount;
  exportLimit: { title: string }[];
  user: string = JSON.parse(sessionStorage.getItem("user"))?.userRole.userRole
    .name;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private http: IncommingCallsService,
    private activeRoute: ActivatedRoute,
    private excel: ExcelService,
    private toaster: ToasterService
  ) {
    this.exportLimit = [
      { title: "Export Current Data" },
      { title: "Export 1000 Rows" },
    ];
  }

  ngOnInit(): void {
    this.startDate = new Date();
    this.endDate = new Date();
    this.initform();
    this.getIncommingCalls();
  }
  async initform() {
    this.filterForm = this.formBuilder.group({
      startDate: [this.startDate],
      endDate: [this.endDate],
      name: [""],
      exportCount: ["Export Current Data"],
    });
  }
  formateDate() {
    const form = this.filterForm.value;
    const sd = new Date(form.startDate);
    const ed = new Date(form.endDate);
    this.filters.startDate = isNaN(sd.getTime())
      ? new Date().getTime()
      : sd.getTime();
    this.filters.endDate = isNaN(ed.getTime())
      ? new Date().getTime()
      : ed.getTime();
  }
  async helplineModal(btnType: string, id?: string) {
    let result: any;
    const attributes: any = [
      {
        key: "valueData",
        value: "Incomming Calls",
      },
      {
        key: "route",
        value: this.route,
      },
      {
        key: "consultationsId",
        value: id,
      },

      {
        key: "btnType",
        value: btnType,
      },
    ];
    switch (btnType) {
      case "add":
        result = this.modalService.open(
          FormModalComponent,
          {
            centered: true,
            backdrop: "static",
            keyboard: false,
          },
          attributes
        );
        break;
      case "edit":
        result = this.modalService.open(
          FormModalComponent,
          {
            centered: true,
            backdrop: "static",
            keyboard: false,
          },
          attributes
        );
        break;
    }
    const confirm = await this.modalService.confirmationModalResult(result);
    switch (confirm) {
      case "incomming-call-added":
        this.toaster.success("Helpline Case Added Successfully");
        this.getIncommingCalls();
        break;
      case "incomming-call-edited":
        this.toaster.success("Helpline Case Edited Successfully");
        this.getIncommingCalls();
        break;
    }
  }

  async getIncommingCalls(offset?: any) {
    this.resData = undefined;
    let load = { offset };
    const payload = await this.setfilters(load);

    this.resData = await this.http.getIncommingCalls(payload);
    this.tableData = this.resData.data;
    this.totalCount = this.resData.meta.totalCount;
    this.exportCount = this.totalCount;
  }

  async updateCallStatus(id: any) {
    let payload = {
      consultationId: id,
      callStatus: "closed",
    };
    return await this.http.updateIncommingCallStatus(payload);
  }

  async closeCallStatus(id: any) {
    await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d00140",
      cancelButtonColor: "#7cc707",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.updateCallStatus(id);
        await this.getIncommingCalls();
        this.toaster.success("This call has closed");
      }
    });
  }

  async followUpModal(id: any, btntype: string) {
    let result: any;
    const attributes: any = [
      {
        key: "valueData",
        value: "Follow Up",
      },
      {
        key: "consultationsId",
        value: id,
      },
      {
        key: "route",
        value: this.route,
      },
      {
        key: "btnType",
        value: btntype,
      },
    ];

    result = this.modalService.open(
      FormModalComponent,
      {
        centered: true,
        backdrop: "static",
        keyboard: false,
      },
      attributes
    );

    const confirm = await this.modalService.confirmationModalResult(result);
    switch (confirm) {
      case "follow-up-added":
        await this.updateCallStatus(id);
        this.toaster.success("Follow Up has Added Successfully");
        await this.getIncommingCalls();
        break;
      case "follow-up-edited":
        this.toaster.success("Follow Up has Edited Successfully");
        await this.getIncommingCalls();
        break;
    }
  }

  setfilters = async (load?: any) => {
    const form = this.filterForm.value;
    this.formateDate();
    let payload: any = {
      startDate: this.filters.startDate,
      endDate: this.filters.endDate,
    };

    if (load.offset) {
      payload = {
        ...payload,
        offset: load.offset,
      };
    }
    if (load.limit) {
      payload = {
        ...payload,
        limit: load.limit,
      };
    }

    switch (this.route) {
      case "incomming-calls":
        payload = {
          ...payload,
          callStatus: "pending",
        };
        break;
      case "incomming-calls-closed":
        payload = {
          ...payload,
          callStatus: "closed",
        };
        break;
    }

    if (form.name) {
      payload = {
        ...payload,
        name: form.name,
      };
    }

    return payload;
  };

  async excelExport() {
    const data = await this.generateExcelData(this.exportCount);
    let sheet = { "Helpline Calls": data };
    this.excel.exportExcel(sheet);
  }

  async generateExcelData(limitValue: number) {
    const response: any = await this.getExcelExportData(limitValue);
    const exportData: {}[] = response.data.map((item: any) => {
      return {
        "Date and Time": `${formateDateandTime(
          "date",
          item.date
        )} ${formateDateandTime("time", item.date)}`,
        Name: item.callerName || "-",
        Phone: item.callerPhoneNumber || "-",
        Category: item.category || "-",
        "Company name": item.companyName || "-",
        "Agent name": item.agent.firstName + " " + item.agent.lastName || "-",
        Remarks: item.remarks || "-",
        "Case catering department": item.caseCateringDepartment || "-",
        "Remarks / Details of caller": item.callerRemarksORdetail || "-",
        "Case close time": item.caseCloseTime || "-",
        "Registered Via": item.registeredVia || "-",
        Status: item.callStatusValue?.value || "-",
        "Follow Up Date": formateDateandTime("date", item.followUps?.date),
        "Follow Up Time": formateDateandTime("time", item.followUps?.date),
        "Follow Up Agent Name":
          (item.followUps &&
            item.followUps.agent.firstName +
              " " +
              item.followUps.agent.lastName) ||
          "-",
        "Follow Up Call Status":
          (item.followUps && item.followUps.callStatus) || "-",
        "Follow Up Remarks": (item.followUps && item.followUps.remarks) || "-",
      };
    });

    return exportData;
  }
  setExportLimit(event: any) {
    switch (event.title) {
      case "Export Current Data":
        this.exportCount = this.totalCount;
        break;
      case "Export 1000 Rows":
        this.exportCount = 1000;
        break;
    }
  }
  async getExcelExportData(limit: number) {
    let load = { limit };
    let payload = await this.setfilters(load);
    const result = await this.http.getExcelIncommingCalls(payload);
    return result;
  }

  requestPaginate(offset: any) {
    this.tableData = [];
    this.getIncommingCalls(offset);
  }
}
