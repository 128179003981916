import { environment } from "src/environments/environment";

export const apiBase = environment.apiUrl;
export const silentApiList = [
  apiBase + "chat/",
  apiBase + "doctors/status",
  apiBase + "notifications",
];
export const  CallStatus = {
  pendingCall: 6,
  closedCall: 7,
}
// export const ConsultationStatus = {
//   missed:,
//   cancelled
// }

export const validator = "^92[0-9]{8}$";
