import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgProgress } from "@ngx-progressbar/core";
import { ngProgressBarService } from "./admin/core/services/ngProgressBar.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
// {}
export class AppComponent {
  isCollapsed = false;
  currentUrl = "";

  constructor(
    private router: Router,
    private progress: NgProgress,
    public progressService: ngProgressBarService
  ) {
    router.events.subscribe((e) => {
      // this.onTitleHeaderChange(e);
    });
  }

  ngOnInit() {
    this.progressService.progressRef = this.progress.ref("loadingBar");
  }

  onTitleHeaderChange(e) {
    // this.currentUrl= this.router.url;
    switch (this.router.url) {
      case "/admin/dashboard":
        this.currentUrl = "Dashboard";
        return;
      case "/admin/doctors-panel":
        this.currentUrl = "Doctors Panel";
        return;
      // break;
      case "/userManagement/ViewAllUser":
        this.currentUrl = "View All User";
        return;
      case "/userManagement/userRights":
        this.currentUrl = "User Rights";
        return;
      case "/userManagement/userTypes":
        this.currentUrl = "User Type";
        return;
      case "/userManagement/userLocation":
        this.currentUrl = "User Location";
        return;
      case "/reports/consultations":
        this.currentUrl = "Consultations";
        return;
      case "/reports/aftercallworks":
        this.currentUrl = "After Call Work";
        return;
      case "/reports/incoming-calls":
        this.currentUrl = "Helpline Calls";
        return;
      case "/reports/incoming-calls-closed":
        this.currentUrl = "Helpline Calls";
        return;
      case "/reports/holdtime":
        this.currentUrl = "Holdtime";
        return;
      case "/patient/patient":
        this.currentUrl = " Patient ";
        return;
      case "/setting/editprofile":
        this.currentUrl = " Edit Profile";
        return;
      case "/setting/agentextension":
        this.currentUrl = " Agent Extension ";
        return;
      case "/setting/changepassword":
        this.currentUrl = " Change Password";
        return;
      case "/patient":
        this.currentUrl = " Patient";
        return;
      default:
        break;
    }
  }
}
