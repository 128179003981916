import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class DoctorsPanelServices {
  constructor(private http: HttpService) {}
  async getDoctorsRatingAndBehaviour(params: {
    startDate: number;
    endDate: number;
  }) {
    const payload = this.http.createHttpParams(params);
    return await this.http.get("doctors-panel/rating-feedback", payload);
  }
  async getTopDoctors(params: { startDate: number; endDate: number }) {
    const payload = this.http.createHttpParams(params);
    return await this.http.get("doctors-panel/top-doctors", payload);
  }
}
