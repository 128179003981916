import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { adminAppValidations } from "src/app/shared/interface";
import { AuthService } from "../../core/services/auth.service";
import { roleAndLocationService } from "../../core/services/role-and-location-all.service";
import { EmptyStringValidator } from "../../validators/validators";

@Component({
  selector: "app-editprofile",
  templateUrl: "./editprofile.component.html",
  styleUrls: ["./editprofile.component.scss"],
})
export class EditprofileComponent implements OnInit {
  res: any;
  resLocation: any;
  resRoles: any;
  user: any;
  validator = adminAppValidations;
  constructor(
    private formbuilder: FormBuilder,
    private http: roleAndLocationService,
    private httpUser: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getRoleandLocation();
    this.getCurrentUser();
  }
  getCurrentUser() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.initForm();
  }

  form: FormGroup;

  initForm() {
    this.form = this.formbuilder.group({
      firstName: [
        this.user.firstName || "",
        [
          Validators.required,
          EmptyStringValidator,
          Validators.maxLength(this.validator.name.length),
        ],
      ],
      lastName: [
        this.user.lastName || "",
        [
          Validators.required,
          EmptyStringValidator,
          Validators.maxLength(this.validator.name.length),
        ],
      ],
      phone: [this.user.phone || "", [Validators.required]],
      // userRole: [this.user.userRole.roleId || "", [Validators.required]],
      gender: [this.user.gender || "", [Validators.required]],
      location: [this.user.address || "", [Validators.required]],
    });
  }

  async getUserRoleandLocation() {
    await this.http.getAllRoleandLocation();
  }

  async getRoleandLocation() {
    this.res = (await this.http.getAllRoleandLocation()).data;
    this.resLocation = await this.res.locations;
    this.resRoles = await this.res.roles;
    this.getCurrentUser();
  }
  submit() {
    let body = {
      id: this.user.id,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      phone: this.form.value.phone,
      roleId: this.user.userRole.roleId,
      gender: this.form.value.gender,
      location: this.form.value.location,
    };
    this.httpUser.updateUser(this.res.id, body);
  }
}
