import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  consultationStatus,
  consultationType,
  consultationformateExportData,
  feedbackType,
  platform,
} from "src/app/shared/enums";
import { iNgSelect, getAllConsutations } from "../../../../shared/interface";
import { exportLimit } from "../../../../shared/enums";
import { ConsultationService } from "../../../core/services/consultation.service";
import { ToasterService } from "../../../core/services/toaster.service";
import Swal from "sweetalert2";
import {
  convertDignosisToString,
  formatePayload,
  formateToTimeStamp,
} from "src/app/shared/helper";
import { AllUserService } from "src/app/admin/core/services/allUsers.service";
import { ExcelService } from "../../../core/services/xlsx.service";
import { ModalService } from "src/app/admin/core/services/modal.service";
import { AssignAgentComponent } from "../../../modals/assign-agent/assign-agent.component";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
  response = undefined;
  agents: any;
  constructor(
    private formBuider: FormBuilder,
    private consultations: ConsultationService,
    private toaster: ToasterService,
    private users: AllUserService,
    private excel: ExcelService,
    private modalService: ModalService
  ) {}

  totalCount: number = 0;
  date = new Date();
  form: any;
  consultationType: iNgSelect[] = consultationType;
  consultationStatus: iNgSelect[] = consultationStatus;
  platform: iNgSelect[] = platform;
  feedbackType: iNgSelect[] = feedbackType;
  agentName: iNgSelect[] = [];
  exportLimit: iNgSelect[] = exportLimit;
  convertDignosisToString = convertDignosisToString;
  exportCount: number;

  async ngOnInit(): Promise<void> {
    this.getUsers();
    this.initForm();
    this.applyFilter();
  }

  async getUsers() {
    let response = await this.users.getAllUsers();

    this.agentName = [{ value: "null", title: "Not Assigned" }];
    response.data.map((d) => {
      this.agentName.push({
        value: d.id,
        title: `${d.firstName} ${d.lastName}`,
      });
    });
    this.agents = response.data.map((d) => {
      return {
        value: d.id,
        title: `${d.firstName} ${d.lastName}`,
      };
    });
  }

  initForm() {
    this.form = this.formBuider.group({
      startDate: [this.date],
      endDate: [this.date],
      patientNumber: [],
      type: [],
      partner: [],
      consultationStatus: [],
      doctor: [],
      agentId: [],
      callStatus: [],
      exportLimit: [],
    });
  }

  async applyFilter(payload?: { offset?: number }) {
    let formValue = formatePayload(this.form.value);
    if (payload?.offset) {
      formValue = { ...formValue, offset: payload.offset };
    }

    const result = await this.getAllConsutations(formValue);
    this.response = result.data;
    this.totalCount = result.meta.totalCount;
    this.exportCount = this.totalCount;
  }

  async getAllConsutations(payload) {
    payload.startDate = formateToTimeStamp(payload.startDate);
    payload.endDate = formateToTimeStamp(payload.endDate);

    const result = await this.consultations.allConsultation(payload);
    return result;
  }

  async sortConsultation() {
    if (this.totalCount == 0) {
      this.toaster.error(`Please select consultations`);
      return;
    }

    if (this.totalCount > 1000) {
      return this.toaster.error(
        `1000 consultations can be assign on single click`
      );
    }

    let formValue: any = {
      ...formatePayload(this.form.value),
      limit: this.totalCount,
    };
    const result = (await this.getAllConsutations(formValue)).data;

    const consulIds: any = result
      .filter((d) => !d.user && d.callStatus != "Closed") // Filter to include only objects where user is falsy
      .map((d) => d.consultationId);

    const modal = this.modalService.open(
      AssignAgentComponent,
      {
        centered: true,
        backdrop: "static",
        keyboard: false,
      },
      [
        {
          key: "agentIds",
          value: this.agents,
        },
        {
          key: "consultationsCount",
          value: consulIds.length,
        },
      ]
    );

    const agentIds = await this.modalService.confirmationModalResult(modal);

    let payload: any = {
      agentIds: agentIds,
      consultationsIds: consulIds,
    };

    if (agentIds) {
      const res = await this.consultations.sortConultation(payload);

      if (res) {
        this.toaster.success(`Consultations have been sorted successfully`);
      }
    }
  }

  setExportLimit(event: any) {
    switch (event.title) {
      case "Export Current Data":
        this.exportCount = this.totalCount;
        break;
      case "Export 1000 Rows":
        this.exportCount = 1000;
        break;
    }
  }

  async excelExport() {
    let formValue = { ...this.form.value, limit: this.exportCount };
    formValue.startDate = formateToTimeStamp(formValue.startDate);
    formValue.endDate = formateToTimeStamp(formValue.endDate);

    const reponse = await this.consultations.allConsultationExcel(formValue);
    const data = await consultationformateExportData(reponse);
    this.excel.exportExcel(data);
  }
}
