import { Component, OnInit } from "@angular/core";
import { ModalService } from "src/app/admin/core/services/modal.service";
import { ToasterService } from "src/app/admin/core/services/toaster.service";
import { userLocationService } from "src/app/admin/core/services/user-location.service";
import { AddUserLocationComponent } from "src/app/admin/modals/AddUserLocation/AddUserLocation.component";
import { tableHeaderUserLocation, tableBodyUserLocation } from "../../data";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
interface ModalServiceAttributes {
  key: string;
  value: any;
}
@Component({
  selector: "app-user-location",
  templateUrl: "./user-location.component.html",
  styleUrls: ["./user-location.component.scss"],
})
export class UserLocationComponent implements OnInit {
  editMark = faPenToSquare;
  tableHeader = tableHeaderUserLocation;
  tableBody = tableBodyUserLocation;
  res: any;

  constructor(
    private http: userLocationService,
    private modalService: ModalService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.getUserLocation();
  }
  async open(id?: number) {
    const attributes: ModalServiceAttributes[] = [];
    try {
      attributes.push({
        key: "id",
        value: this.res[id],
      });
      const result = this.modalService.open(
        AddUserLocationComponent,
        {
          centered: true,
          backdrop: "static",
        },
        attributes
      );
      const success: any = await this.modalService.confirmationModalResult(
        result
      );
      if (success) {
        this.getUserLocation();
      }
    } catch (err) {
      this.toaster.error(err);
    }
  }
  // async navigatetoView(index: any) {
  //   const attributes: ModalServiceAttributes[] = [];
  //   attributes.push({
  //     key: "ViewData",
  //     value: this.res[index],
  //   });
  //   const result = this.modalService.open(
  //     AddUserLocationComponent,
  //     {
  //       centered: true,
  //       backdrop: "static",
  //     },
  //     attributes
  //   );
  //   const success: any = await this.modalService.confirmationModalResult(
  //     result
  //   );
  //   if (success) {
  //     // this.getUserTypes();
  //   }
  // }
  // async navigatetoEdit(index: any) {
  //   const attributes: ModalServiceAttributes[] = [];
  //   attributes.push({
  //     key: "EditData",
  //     value: this.res[index],
  //   });
  //   const result = this.modalService.open(
  //     AddUserLocationComponent,
  //     {
  //       centered: true,
  //       backdrop: "static",
  //     },
  //     attributes
  //   );
  //   const success: any = await this.modalService.confirmationModalResult(
  //     result
  //   );
  //   if (success) {
  //     await this.http.getAllLocation();
  //   }
  // }
  async getUserLocation() {
    try {
      this.res = (await this.http.getAllLocation()).data;
    } catch (err) {
      this.toaster.error(err.error.meta.message);
    }
  }
}
