import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "../../core/services/toaster.service";
import { userLocationService } from "../../core/services/user-location.service";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: "app-AddUserLocation",
  templateUrl: "./AddUserLocation.component.html",
  styleUrls: ["./AddUserLocation.component.scss"],
})
export class AddUserLocationComponent implements OnInit {
  typeDisplay: any;
  xmark = faXmark;
  constructor(
    public activeModal: NgbActiveModal,
    private http: userLocationService,
    private toaster: ToasterService
  ) {}
  value: any;
  location;
  @Input() id: any;
  ngOnInit() {
    this.value = this.id;
    this.location = new FormControl((this.id && this.id.location) || "");
  }

  close() {
    this.activeModal.close(false);
  }

  async submit() {
    // try {
    if (this.id) {
      await this.http.editLocation(this.id.id, this.location.value);
      this.toaster.success("Location Updated Successfully");
      this.activeModal.close(true);
    }
    if (!this.id) {
      await this.http.addLocation(this.location.value);
      this.toaster.success("Location Added Successfully");
      this.activeModal.close(true);
    }
    // } catch (err) {
    //   this.toaster.error(err.error.meta.message);
    // }
  }
}
