import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { permissions } from "../../enums/permission";
import { ToasterService } from "../../services/toaster.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Input() isCollapsed = false;
  constructor(private router: Router, private toaster: ToasterService) {}

  async ngOnInit(): Promise<void> {
    await this.user();
    await this.gerPermissions();
    await this.sideBar();
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(["auth"]);
    this.toaster.success("Logout succesfully");
  }

  userName: any;
  userType: any;
  userPermissions: { [view: string]: number }[] | [] = [];
  user() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    this.userName = `${user.firstName} ${user.lastName}`;
    this.userType = user.userRole.userRole.name;
  }
  // val: any;

  gerPermissions() {
    this.userPermissions = JSON.parse(sessionStorage.getItem("permission"));
  }
  DV: any;
  getValue(v: any) {
    this.DV = v.toUpperCase();

    let view: any;
    this.userPermissions.map((p) => {
      if (p[this.DV]) {
        view = p[this.DV];
      }
    });

    return view.view || 0;
  }

  sidebarDetails: any = [];
  sideBar() {
    this.sidebarDetails = [
      {
        id: 0,
        title: "Dashboard",
        routerLink: "/admin/dashboard",
        icon: "dashboard",
        view: this.getValue(permissions.DASHBOARD),
      },
      {
        id: 1,
        title: "Consultations",
        icon: "reconciliation",

        nested: [
          {
            id: 1,
            title: "Active",
            routerLink: "/admin/active-calls",
            icon: "dashboard",
            view: this.getValue(permissions.ACTIVE_CALLS),
          },
          {
            id: 2,
            title: "Pending Feedback",
            routerLink: "/admin/consultations",
            icon: "dashboard",
            view: this.getValue(permissions.CONSULTATIONS),
          },
          {
            id: 3,
            title: "Successfull Feedback",
            routerLink: "/admin/closed-calls",
            icon: "dashboard",
            view: this.getValue(permissions.CLOSED_CALLS),
          },
        ],
      },

      {
        id: 4,
        title: "Doctors Panel",
        icon: "phone",
        view: this.getValue(permissions.HELPLINE_CALLS),
        routerLink: "/admin/doctors-panel",
      },
      {
        id: 5,
        title: "Helpline Calls",
        icon: "phone",
        view: this.getValue(permissions.HELPLINE_CALLS),
        nested: [
          {
            id: 1,
            title: "Pending",
            routerLink: "/admin/incomming-calls",
            icon: "dashboard",
            view: this.getValue(permissions.HELPLINE_PENDING_CALLS),
          },
          {
            id: 2,
            title: "Closed",
            routerLink: "/admin/incomming-calls-closed",
            icon: "dashboard",
            view: this.getValue(permissions.HELPLINE_CLOSE_CALLS),
          },
        ],
      },
      {
        id: 6,
        title: "User and Roles",
        icon: "user",
        view: this.getValue(permissions.USER_MANAGEMENT),
        nested: [
          {
            id: 1,
            title: "Users",
            routerLink: "/admin/users",
            view: this.getValue(permissions.VIEW_ALL_USERS),
          },
          // {
          //   id: 2,
          //   title: "Reports",
          //   routerLink: "/admin/user-reports",
          //   view: 1,
          // },
          {
            id: 2,
            title: "Roles",
            routerLink: "/admin/roles",
            view: this.getValue(permissions.USER_TYPES),
          },
          {
            id: 3,
            title: "Role Permissions",
            routerLink: "/admin/role-permissions",
            view: this.getValue(permissions.USER_RIGHTS),
          },
          {
            id: 4,
            title: "User Locations",
            routerLink: "/admin/user-location",
            view: this.getValue(permissions.USER_LOCATIONS),
          },
        ],
      },
      {
        id: 7,
        title: "Settings",
        icon: "setting",
        view: this.getValue(permissions.SETTINGS),
        nested: [
          {
            id: 1,
            title: "Change Password",
            routerLink: "/admin/change-password",
            view: this.getValue(permissions.CHANGE_PASSWORD),
          },
          {
            id: 2,
            title: "Edit Profile",
            routerLink: "/admin/edit-profile",
            view: this.getValue(permissions.EDIT_PROFILE),
          },
        ],
      },
    ];
  }
}
